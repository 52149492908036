import { useFormik } from 'formik';
import React, { useState } from 'react'
import { Container, Row, Col, Form, Button, Toast } from "react-bootstrap";
import { ForgotPasswordForm } from '../../helper/ValidationHelper';
import AuthService from '../../services/AuthService';

 const ForgotPassword = () => {

    const [isLoading, setIsLoading] = useState(false);

    const submitHandler = async (formvalues) => {
        try {
            // setIsLoading(true);
            // let input = {};
            // input.identifier = formvalues.email;
            console.log(">>>>>>",formvalues)
            let response = await AuthService.ForgotPassword({to:formvalues.email});
            
            if (response.status === 200) {
                console.log(">>>>>>",response.status)
                setIsLoading(false);
                Toast.fire({
                    icon: "success",
                    title: "Check you registered email for the reset password link",
                });
            } else {
                setIsLoading(false);
                Toast.fire({
                    icon: "warning",
                    title: "Something went wrong. try again later",
                });
            }
        } catch (err) {
            setIsLoading(false);
            let message = err.message;
            if (err.code === "ERR_BAD_REQUEST") {
                message = "Invalid email or password";
            }
            Toast.fire({ icon: "error", title: message });
        }
    };

    const initialValues = {
        email: ""
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: ForgotPasswordForm,
        onSubmit: (values, action) => {
            submitHandler(values);
            action.resetForm()
        },
    });

  return (
    <>
    <div className="main-wrap">
        <Container>
            <h2 className="main-title">Forget Password</h2>
            <Row className="mt-5">
                <Col lg={{ span: 6, offset: 3 }} md={12}>
                    <div className="sign-form-wrap">
                        <div className="signin-form">
                            <Form
                                noValidate
                                onSubmit={formik.handleSubmit}
                                className="row"
                            >
                                <Form.Group className="col-md-12 mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        autoComplete="off"
                                        placeholder="example@gmail.com"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        isInvalid={formik.touched.email && formik.errors.email}
                                        className="form-control"
                                        required
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <span className="text-danger small">
                                            <strong>{formik.errors.email}</strong>
                                        </span>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-12 mb-3 d-flex align-items-center">
                                    {/* <Button className="btn btn-warning" type="submit">Submit</Button> */}
                                    <Button
                                        type="submit"
                                        className="btn btn-warning"
                                    >
                                        {isLoading ? (
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            "Submit"
                                        )}
                                    </Button>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
</>
  )
}


export default ForgotPassword