import axios from "axios";
import HttpHelper from "../helper/HttpHelper";

class SubscriptionServices {
  create(payload) {
    return axios.post(
      HttpHelper.API_URL + "/subscriptions",
      payload,
      HttpHelper.getHeader()
    );
  }
  getOne(id, payload) {
    return axios.get(
      HttpHelper.API_URL + "/subscriptions/" + id,
      { params: payload },
      HttpHelper.getHeader()
    );
  }
  getAll(payload) {
    return axios.get(
      HttpHelper.API_URL + "/subscriptions",
      { params: payload },
      HttpHelper.getHeader()
    );
  }
}

export default SubscriptionServices = new SubscriptionServices();
