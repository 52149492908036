import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class MessageService {
    
    getAll(payload) {
        return axios.get(HttpHelper.API_URL + '/messages', { params: payload }, HttpHelper.getAuthHeader());
    }

    getOne(id, payload) {
        return axios.get(HttpHelper.API_URL + '/messages/' + id, { params: payload }, HttpHelper.getAuthHeader());
    }

    create(payload) {
        return axios.post(HttpHelper.API_URL + '/messages', payload, HttpHelper.getAuthHeader());
    }

    update(id, payload) {
        return axios.put(HttpHelper.API_URL + "/messages/" + id, payload, HttpHelper.getAuthHeader());
    }

    delete(id) {
        return axios.delete(HttpHelper.API_URL + "/messages/" + id, HttpHelper.getAuthHeader());
    }

}

export default MessageService = new MessageService();