import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserContext';
import axios from 'axios';
import CommentService from '../../services/CommentService';

const CommentsList = () => {

    const { userId } = useContext(UserContext)
    const [isLoaded, setIsLoaded] = useState(false);
    const [commentsList, setCommentsList] = useState([]);
    const [query, setQuery] = useState({
        filters: {
            provider: {
                user: {
                    id: {
                        $eq: userId,
                    },
                },
            },
        },
        populate: {
            provider:"*",
        },
        pagination: { pageSize: 10 },
        sort: ['id:desc']
    })

    async function fetchAllCommentsByUserId() {
        const response = await CommentService.getAll(query)
        if (response.status === 200) {
            console.log(response.data.data);
            setCommentsList(response.data.data);
        }
    }

    useEffect(() => {
        fetchAllCommentsByUserId();
    }, [query]);

    return (
        <div>CommentsList</div>
    )
}


export default CommentsList