import React, { useState } from "react";
import HttpHelper from "../../helper/HttpHelper";
import { Container, Row, Col, Collapse, Form } from "react-bootstrap";


function LeftSidebar(prop) {
    const url = HttpHelper.BASE_URL;

    const [open, setOpen] = useState(true);
    const [data, setData] = useState(prop.category.list);

    return (
        <div className="category-list mb-3">
            <a className="expand" onClick={() => setOpen(!open)} aria-controls="category1" aria-expanded={open}>
                <div className="right-arrow"><i className='fa fa-angle-down'></i></div>
                <div><h3 className="hd3">{prop.category.label}</h3></div>
            </a>
            <Collapse className="category-body" id="category1" in={open}>
                <ul>
                    {data.map((row, i) => {
                        return <li key={i}><a href="#">{row}</a></li>
                    })}
                </ul>
            </Collapse>
        </div>
    );
}

export default LeftSidebar;