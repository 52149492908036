import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";

import { Toast } from "../../layout/Alert";
import HttpHelper from "../../../helper/HttpHelper";
import { ProductEditForm } from "../../../helper/ValidationHelper";

import ProductService from "../../../services/ProductService";
import UploadService from "../../../services/UploadService";

import { UserContext } from "../../../context/UserContext";

import Account from "../../layout/Account/Account";
import Loader from "../../layout/Loader";

export default function ProductEdit() {
    let { id } = useParams();
    if (isNaN(id)) {
        id = null;
    }
    const { userId } = useContext(UserContext);

    // Image Form
    const [selectedImage, setselectedImage] = useState(true);
    const [isImgLoading, setImgIsLoading] = useState(false);
    const onFileChangeImages = (event) => {
        setselectedImage(false);
        let input = event.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                setImgPath(reader.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
    };
    async function handleUpload(e) {
        e.preventDefault();
        try {
            setselectedImage(true);
            setImgIsLoading(true);
            let response = await UploadService.upload(new FormData(e.target));
            if (response.status === 200) {
                // console.log(response.data[0]);
                let oldImageId = imageId;
                let newImageId = response.data[0].id;
                response = await ProductService.update(id, { data: { picture: newImageId } });
                setImgIsLoading(false);
                if (response.status === 200) {
                    setimageId(newImageId);
                    if (oldImageId) {
                        await UploadService.delete(oldImageId);
                    }
                    Toast.fire({
                        icon: "success",
                        title: "Your place are successfully updated",
                    });
                } else {
                    let message = "Please try again later";
                    Toast.fire({ icon: "error", title: message });
                }
            } else {
                setImgIsLoading(false);
                let message = "Please try again later";
                Toast.fire({ icon: "error", title: message });
            }
        } catch (err) {
            setselectedImage(true);
            setImgIsLoading(false);
            Toast.fire({ icon: "error", title: "Network response was not ok" });
        }
    }

    // Form
    const [isLoading, setIsLoading] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(true);
    const submitHandler = async (input) => {
        try {
            setIsLoading(true);
            input.user = userId;
            const response = await ProductService.update(id, { data: input });
            if (response.status !== 200) {
                throw new Error("Network response was not ok");
            } else {
                setIsLoading(false);
                Toast.fire({
                    icon: "success",
                    title: "Your Product are successfully updated",
                });
            }
        } catch (err) {
            setIsLoading(false);
            Toast.fire({ icon: "error", title: "Network response was not ok" });
        }
    };
    const initialValues = {
        name: "",
        description: "",
        price: "",
    };
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: ProductEditForm,
        onSubmit: (values, action) => {
            submitHandler(values);
        },
    });

    // Fetch Data
    const [imageId, setimageId] = useState(0);
    const [imgPath, setImgPath] = useState('/images/no-photo-small.svg');

    async function fetchData() {
        try {
            const response = await ProductService.getOne(id, { populate: "*" });
            if (response.status !== 200) {
                throw new Error("Network response was not ok");
            } else {
                const product = response.data.data.attributes;
                formik.setFieldValue("name", product.name ? product.name : "");
                formik.setFieldValue("price", product.price ? product.price : "");
                formik.setFieldValue("description", product.description ? product.description : "");
                if (product.picture.data) {
                    const url = HttpHelper.BASE_URL;
                    const img = product.picture.data?.attributes;
                    if (img.formats) {
                        setImgPath(url + img.formats?.thumbnail?.url);
                    } else {
                        setImgPath(url + img.url);
                    }
                    setimageId(product.picture.data?.id);
                }
                setLoaderVisible(false);
            }
        } catch (error) {
            setLoaderVisible(false);
            Toast.fire({ icon: "error", title: "Network response was not ok" });
        }
    }
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Account>

            <Loader visible={loaderVisible}>
                <div className="filter-part p-3 mb-3">
                    <h2 className="title1 p-0 mb-3">Edit Product</h2>
                    <Row className="mb-3">
                        <Col lg={4} md={4}>
                            <Form onSubmit={handleUpload}>
                                <Row>
                                    <Form.Group
                                        controlId="image"
                                        className="col-md-12 mb-3"
                                    >
                                        <Form.Label>Image</Form.Label>
                                        <Photo url={imgPath}></Photo>
                                        <Form.Control
                                            type="file"
                                            name="files"
                                            accept="image/*"
                                            onChange={(e) => {
                                                onFileChangeImages(e);
                                            }}
                                            className="form-control"
                                            required
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className="col-md-12 d-flex mb-5">
                                        <Button
                                            disabled={selectedImage}
                                            type="submit"
                                            className="btn btn-warning btn-subscribe"
                                        >
                                            {isImgLoading ? (
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            ) : (
                                                "Upload"
                                            )}
                                        </Button>
                                    </Form.Group>
                                </Row>
                            </Form>
                        </Col>

                        <Col lg={8} md={8}>
                            <Form noValidate onSubmit={formik.handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group
                                        className="col-mb-12 mb-3"
                                        controlId="name"
                                    >
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.name}
                                            isInvalid={
                                                formik.touched.name && formik.errors.name
                                            }
                                            className="form-control"
                                            required
                                        />
                                    </Form.Group>

                                </Row>
                                <Row className="mb-3">
                                    <Form.Group
                                        className="col-mb-12 mb-3"
                                        controlId="name"
                                    >
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.description}
                                            isInvalid={
                                                formik.touched.description && formik.errors.description
                                            }
                                            className="form-control"
                                            required
                                        />
                                    </Form.Group>

                                </Row>
                                <Row className="mb-3">
                                    <Form.Group
                                        className="col-mb-12 mb-3"
                                        controlId="name"
                                    >
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control
                                            name="price"
                                            type="text"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.price}
                                            isInvalid={
                                                formik.touched.price && formik.errors.price
                                            }
                                            className="form-control"
                                            required
                                        />
                                    </Form.Group>

                                </Row>

                                <Row>
                                    <Form.Group className="col-md-12 mb-3 d-flex">
                                        <Button
                                            size="lg"
                                            type="submit"
                                            disabled={isLoading}
                                            className="btn btn-warning"
                                        >
                                            {isLoading ? (
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            ) : (
                                                "Save"
                                            )}
                                        </Button>
                                    </Form.Group>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Loader>

        </Account>
    );
}

function Photo({ url }) {
    return (
        <div className="w-100 text-center">
            <img alt="" src={url} className="image w-100" border="0" />
        </div>
    );
}
