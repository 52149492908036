import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse';
import { Form } from 'react-bootstrap';


const PersonalityFilter = ({ handleChange,personality }) =>{

    const [open17, setOpen17] = useState(true);
    const [isYes, setIsYes] = useState(false);
    const [isNo, setIsNo] = useState(false);
    const [personalityChecked, setPersonalityChecked] = useState([{}]);
    const handleClickCheckbox = (event) => {
        console.log('handleClickCheckbox')
        handleChange(event);
        const { value } = event.target;
        if(value){
            const prevState = { ...personalityChecked, [value]: !personalityChecked[value] };
            setPersonalityChecked(prevState);
        }else{
            if(event.target.checked){
                const prevState = [];
                setPersonalityChecked(prevState);
            }else{
                const prevState = { ...personalityChecked, ['test']: !personalityChecked['test'] };
                setPersonalityChecked(prevState);
            }
           
        }
        console.log('lang check uncheck is call ',personalityChecked)
    };

  return (
    <div className="category-list mb-3">
    <div className="expand" onClick={() => setOpen17(!open17)} aria-controls="category17" aria-expanded={open17}>
        <div className="right-arrow"><i className='fa fa-angle-down'></i></div>
        <div><h3 className="hd3">Personality</h3></div>
    </div>
    <Collapse className="category-body" id="category17" in={open17}>
        <ul>
            <li>
                <Form.Check inline name="personality" id="personality1" label={`Submissive (${personality[0]?.Submissive})`} value="Submissive" type="checkbox" onChange={handleClickCheckbox} checked={personalityChecked['Submissive']} />
            </li>
            <li>
                <Form.Check inline name="personality" id="personality2" label={`Confident (${personality[0]?.Confident})`} value="Confident" type="checkbox" onChange={handleClickCheckbox} checked={personalityChecked['Confident']} />
            </li>
            <li>
                <Form.Check inline name="personality" id="personality3" label={`Dominant (${personality[0]?.Dominant})`} value="Dominant" type="checkbox" onChange={handleClickCheckbox} checked={personalityChecked['Dominant']} />
            </li>
            <li>
                <Form.Check inline name="personality" id="personality4" label={`N/A`} value="" type="checkbox" onChange={handleClickCheckbox} checked={personalityChecked?.length === 0 ? true : false} />
            </li>
        </ul>
    </Collapse>
</div>
  )
}
export default PersonalityFilter;
