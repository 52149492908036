import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class StatesService {
    
    getAll(payload) {
        return axios.get(HttpHelper.API_URL + '/states', { params: payload }, HttpHelper.getHeader());
    }
    getAllWithProviderCount(payload) {
        return axios.get(HttpHelper.API_URL + '/state-by-country', { params: payload }, HttpHelper.getHeader());
    }


    getOne(id, payload) {
        return axios.get(HttpHelper.API_URL + '/states/' + id, { params: payload }, HttpHelper.getHeader());
    }

}

export default StatesService = new StatesService();