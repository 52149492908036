import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse';
import { Form } from 'react-bootstrap';

const RacesFilter = ({races, handleChange}) => {
    const [open4, setOpen4] = useState(true);
    const [raceChecked, setRaceChecked] = useState([{}]);
    const handleClickCheckbox = (event) => {
        console.log('handleClickCheckbox')
        handleChange(event);
        const { value } = event.target;
        if(value){
            const prevState = { ...raceChecked, [value]: !raceChecked[value] };
            setRaceChecked(prevState);
        }else{
            if(event.target.checked){
                const prevState = [];
                setRaceChecked(prevState);
            }else{
                const prevState = { ...raceChecked, ['test']: !raceChecked['test'] };
                setRaceChecked(prevState);
            }
        }
        console.log('race check uncheck is call ',raceChecked)
    };
    return (
        <div className="category-list mb-3">
            <div className="expand" onClick={() => setOpen4(!open4)} aria-controls="category4" aria-expanded={open4}>
                <div className="right-arrow"><i className='fa fa-angle-down'></i></div>
                <div><h3 className="hd3">Race</h3></div>
            </div>
            <Collapse className="category-body" id="category4" in={open4}>
                <ul>
                    {races.map((row) => (
                        <li key={row.id}>
                            {row.race_name === "N/A" ?
                                <Form.Check inline className="cat-checkbox" id={row.race_name + row.id} label={row.race_name} value="" onChange={handleClickCheckbox} name="race" checked={raceChecked?.length === 0 ? true : false} /> :
                                <Form.Check inline className="cat-checkbox" id={row.race_name + row.id} label={row.race_name+' ('+row.provider+')'} value={row.id} onChange={handleClickCheckbox} name="race" checked={raceChecked[row.id]}/>
                            }
                        </li>
                    ))}
                </ul>
            </Collapse>
        </div>
    )
}

export default RacesFilter;
