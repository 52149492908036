import React, { useState, useRef } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Form } from "react-bootstrap";

const CumFilter = ({ handleChange, cum }) => {
  const [open14, setOpen14] = useState(true);
  const [isYes, setIsYes] = useState(false);
  const [isNo, setIsNo] = useState(false);
  const [isNa, setIsNa] = useState(false);
  const checkboxRef = useRef(null);

  const [cimtype, setcimtype] = useState({
    Swallow: false,
    Drool: false,
    Spit: false,
  });
  const handleClickCheckbox = (event) => {
    console.log("handleClickCheckbox", event.target.value);
    handleChange(event);
    const { value } = event.target;
    const prevState = { ...cimtype, [value]: !cimtype[value] };
    setIsYes(() => prevState.Swallow || prevState.Drool || prevState.Spit);
    setIsNo(() => false);
    setIsNa(() => false);
    setcimtype(prevState);
  };

  const [cumtype, setcumtype] = useState({
    CIP: false,
    COF: false,
    COT: false,
    COB: false,
    COA: false,
    CIA: false,
  });
  const handleClickCheckbox2 = (event) => {
    console.log("handleClickCheckbox2");
    handleChange(event);
    const { value } = event.target;
    const prevState = { ...cumtype, [value]: !cumtype[value] };
    setIsNo(() => false);
    setIsNa(() => false);
    setcumtype(prevState);
  };

  const handleClickRadio = (event) => {
    console.log("handleClickRadio ", event.target.id);
    handleChange(event);
    let tid = event.target.id;
    if (tid === "cum1") {
      if (event.target.checked) {
        setIsYes(() => true);
        setcimtype({
          Swallow: true,
          Drool: true,
          Spit: true,
        });
        setIsNa(() => false);
      } else {
        setIsYes(() => false);
        setcimtype({
          Swallow: false,
          Drool: false,
          Spit: false,
        });
      }

      //setIsNo(() => false);
      //setIsNa(() => false);
    }
    if (tid === "cum2") {
      if (checkboxRef.current) {
        // checkboxRef.current.click();
      }
      setIsYes(() => true);
      setIsNo(() => true);
      setIsNa(() => false);
      setcimtype({
        Swallow: true,
        Drool: true,
        Spit: true,
      });
      setcumtype({
        CIP: true,
        COF: true,
        COT: true,
        COB: true,
        COA: true,
        CIA: true,
      });
    }
    if (tid === "cum3") {
      setIsYes(() => false);
      setIsNo(() => false);
      setIsNa(() => true);
      setcimtype({
        Swallow: false,
        Drool: false,
        Spit: false,
      });
      setcumtype({
        CIP: false,
        COF: false,
        COT: false,
        COB: false,
        COA: false,
        CIA: false,
      });
    }
  };

  return (
    <div className="category-list mb-3">
      <div
        className="expand"
        onClick={() => setOpen14(!open14)}
        aria-controls="category14"
        aria-expanded={open14}
      >
        <div className="right-arrow">
          <i className="fa fa-angle-down"></i>
        </div>
        <div>
          <h3 className="hd3">Cum</h3>
        </div>
      </div>
      <Collapse className="category-body" id="category14" in={open14}>
        <ul>
          <li>
            <Form.Check
              inline
              checked={isYes}
              className="cat-checkbox"
              type="checkbox"
              name="cum"
              id="cum1"
              value="CIM"
              label={`CIM (${cum[0]?.CIM})`}
              onChange={handleClickRadio}
              ref={checkboxRef}
            />
            <div className="ps-3">
              <ul>
                {["Swallow", "Drool", "Spit"].map((type) => (
                  <li key={`default-${type}`}>
                    <Form.Check
                      inline
                      className="cat-checkbox"
                      type="checkbox"
                      name="cum_type"
                      id={`cum_type${type}`}
                      value={type}
                      checked={cimtype[type]}
                      label={`${type} (${cum[0]?.[type]})`}
                      onChange={handleClickCheckbox}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </li>

          <hr />
          {Object.entries({
            CIP: "CIP",
            COF: "COF",
            COT: "COT",
            COB: "COB",
            COA: "COA",
            CIA: "CIA",
          }).map(([key, type], i) => (
            <li key={`default-${key}`}>
              <Form.Check
                inline
                className="cat-checkbox"
                type="checkbox"
                name="cum_type"
                id={`cum_type_${key}`}
                value={key}
                checked={cumtype[key]}
                label={`${type} (${cum[0]?.[key]})`}
                onChange={handleClickCheckbox2}
              />
            </li>
          ))}
          <hr />
          <li>
            {/* <Form.Check
              inline
              checked={isNo}
              className="cat-checkbox"
              type="radio"
              name="cum"
              id="cum2"
              label={`Anywhere (${cum[0]?.Anywhere})`}
              value="any"
              onChange={handleClickRadio}
            /> */}
          </li>
          <li>
            <Form.Check
              inline
              checked={isNa}
              className="cat-checkbox"
              type="radio"
              name="cum"
              id="cum3"
              label="N/A"
              value=""
              onChange={handleClickRadio}
            />
          </li>
        </ul>
      </Collapse>
    </div>
  );
};

export default CumFilter;
