import React, { useState, useEffect, useContext, useCallback } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { useParams } from "react-router-dom";

import ProviderService from "../../services/ProviderService";
import { CatagoryContext } from "../../context/CatagoryContext";

import Loader from "../layout/Loader";
import Post from "./Post";
import PaginationComponent from "../layout/PaginationComponent";
import TransactionService from "../../services/TransactionService";
import { UserContext } from "../../context/UserContext";

export default function Posts() {
  const { query, setQuery } = useContext(CatagoryContext);
  const {
    hasUserId,
    userId,
    updateUser,
    isLogin,
    isProvider,
    logout,
    totalDebit,
    setTotalDebit,
    totalCredit,
    setTotalCredit,
    totalCreditPackage,
    setTotalCreditPackage,
    setTransactions,
    totalWalletBalance,
    setTotalWalletBalance,
  } = useContext(UserContext);
  let { page } = useParams();
  if (isNaN(page)) {
    page = 1;
  }
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState([]);
  const [visible, setvisible] = useState(true);

  const fetchTransactions = async () => {
    try {
      let walletRes = await TransactionService.getAll({
        filters: {
          $or: [
            {
              tip: {
                provider: {
                  user: {
                    id: {
                      $eq: userId,
                    },
                  },
                },
              },
            },
            {
              user: {
                id: {
                  $eq: userId,
                },
              },
            },
          ],
        },
        populate: "*",
        sort: ["id:desc"],
      });
      if (walletRes.status === 200) {
        setTransactions(walletRes.data.data);
        setTotalCreditPackage(() => 0);
        setTotalCredit(() => 0);
        setTotalDebit(() => 0);
        // eslint-disable-next-line array-callback-return
        walletRes.data.data.map((row) => {
          if (row.attributes.transaction_type === "credit") {
            setTotalCredit(
              (t) => t + parseFloat(row.attributes.transaction_amount)
            );
            if (row.attributes.order_type === "package") {
              setTotalCreditPackage(
                (t) => t + parseFloat(row.attributes.transaction_amount)
              );
            }
          }
          if (row.attributes.transaction_type === "debit") {
            setTotalDebit(
              (t) => t + parseFloat(row.attributes.transaction_amount)
            );
          }
        });
      }
    } catch (err) {}
  };

  const fetchData = useCallback(async () => {
    try {
      console.log("query update", query);
      setvisible(true);
      const response = await ProviderService.getAll(query);
      if (response.status === 200) {
        if (response.data.data) {
          setData(response.data.data);
          setMeta(response.data.meta);
        }
      }
      setvisible(false);
    } catch (error) {
      setvisible(false);
    }
  }, [query]);

  function filtersByPagination(pageNumber) {
    setQuery((query) => {
      let qury = query;
      qury.pagination.page = pageNumber;
      console.log("query pagination ", query);
      return qury;
    });
    fetchData();
  }

  useEffect(() => {
    fetchTransactions();
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // console.log(query);

  return (
    <Loader visible={visible}>
      {data.map((row, id) => {
        return <Post key={id} row={row}></Post>;
      })}
      {data.length !== 0 ? (
        <div className="text-center mt-4">
          <PaginationComponent
            pagination={meta.pagination}
            filtersByPagination={filtersByPagination}
          />
        </div>
      ) : null}
    </Loader>
  );
}
