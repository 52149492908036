import axios from "axios";
import HttpHelper from "../helper/HttpHelper";

class CartService {
  create(payload) {
    return axios.post(
      HttpHelper.API_URL + "/carts",
      payload,
      HttpHelper.getAuthHeader()
    );
  }
  getOne(id, payload) {
    return axios.get(
      HttpHelper.API_URL + "/carts/" + id,
      payload,
      HttpHelper.getAuthHeader()
    );
  }
  deleteCart(id, payload) {
    return axios.delete(
      HttpHelper.API_URL + "/carts/" + id,
      payload,
      HttpHelper.getAuthHeader()
    );
  }
}

export default CartService = new CartService();
