import axios from "axios";
import HttpHelper from "../helper/HttpHelper";

class CustomerHobbyistService {
  getAll(payload) {
    return axios.get(
      HttpHelper.API_URL + "/customer-hobbyists",
      { params: payload },
      HttpHelper.getHeader()
    );
  }

  getOne(id, payload) {
    return axios.get(
      HttpHelper.API_URL + "/customer-hobbyists/" + id,
      { params: payload },
      HttpHelper.getHeader()
    );
  }

  create(payload) {
    return axios.post(
      HttpHelper.API_URL + "/customer-hobbyists",
      payload,
      HttpHelper.getAuthHeader()
    );
  }

  update(id, payload) {
    return axios.put(
      HttpHelper.API_URL + "/customer-hobbyists/" + id,
      payload,
      HttpHelper.getAuthHeader()
    );
  }

  delete(id) {
    return axios.delete(
      HttpHelper.API_URL + "customer-hobbyists/" + id,
      HttpHelper.getAuthHeader()
    );
  }
}

export default CustomerHobbyistService = new CustomerHobbyistService();
