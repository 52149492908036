import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import ProductService from "../../services/ProductService";
import { CartContext } from "../../context/CartContext";
import { Toast } from "../layout/Alert";
import CartService from "../../services/CartService";
import { UserContext } from "../../context/UserContext";
import HttpHelper from "../../helper/HttpHelper";

function Productdetails() {
  let [count, setCount] = useState(0);
  const [oneProduct, setOneProduct] = useState();
  const [quantity, setQuantity] = useState(0);
  const { isLogin, userId } = useContext(UserContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    addToCart,
    getOneItemCount,
    removeFromCart,
    getQuantiyById,
    updateToCart,
    cartItems,
  } = useContext(CartContext);
  // console.log("CARTITEMS>>>>>>>",cartItems);

  const quantityPlus = (row) => {
    // console.log("Quantity---->", row);
    let num = getQuantiyById(row.id);
    // console.log("NUM", num);
    if (num > 0) {
      row.quantiy = num + 1;
      updateToCart(row);
    } else {
      row.quantiy = 1;
      addToCart(row);
    }
    setQuantity(row.quantiy);
  };

  const quantityMinus = (row) => {
    let num = getQuantiyById(row.id);
    if (num > 1) {
      row.quantiy = num - 1;
      setQuantity(row.quantiy);
      updateToCart(row);
    } else {
      removeFromCart(row.id);
      setQuantity(0);
    }
  };

  const handleAddToCart = async () => {
    if (!isLogin()) {
      Toast.fire({
        icon: "error",
        title: "Need to login",
      });
    } else {
      if (quantity <= 0) {
        Toast.fire({
          icon: "error",
          title: "Quantity can not be zero!!",
        });
      } else {
        let cartResponse = await CartService.create({
          data: {
            user: userId,
            price: oneProduct?.quantiy * oneProduct?.attributes?.price,
            quantity: oneProduct?.quantiy,
            product: oneProduct?.id,
          },
        });
        if (cartResponse.status === 200) {
          Toast.fire({
            icon: "success",
            title: "Product added to cart successfully!!!",
          });
          navigate("/Cart");
        } else {
          Toast.fire({
            icon: "error",
            title: "Something went worng please try after sometime!!",
          });
        }
      }
    }
  };

  // const handleQuantityChange = (value) => {
  //   setQuantity(Math.max(1, quantity + value));
  // };
  // function incrementCount() {
  //   count = count + 1;
  //   setCount(count);
  // }
  // function decrementCount() {
  //   count = count - 1;
  //   setCount(count);
  // }

  // console.log("Product>>>>>>", oneProduct);
  const url = HttpHelper.BASE_URL;

  const getOneProduct = async () => {
    try {
      let qury = {};
      qury.populate = "*";
      let response = await ProductService.getOne(id, qury);
      if (response.status === 200) {
        // console.log("Response", response.data.data);
        setOneProduct(response?.data?.data);
        setQuantity(getQuantiyById(id));
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getOneProduct();
  }, []);

  return (
    <>
      <div className="main-wrap dashboard-prt">
        <Container>
          <h2 className="main-title">Product Details</h2>

          <Row className="my-5">
            <Col md={5}>
              <div className="menu-dtl-img me-4">
                <img
                  className="img-fluid"
                  src={
                    url + oneProduct?.attributes?.picture?.data?.attributes?.url
                      ? url +
                        oneProduct?.attributes?.picture?.data?.attributes?.url
                      : "/images/pro1.png"
                  }
                  alt=""
                />
              </div>
            </Col>
            <Col md={7}>
              <div className="menu-dtl-txt">
                <h3>
                  {oneProduct?.attributes?.name
                    ? oneProduct?.attributes?.name
                    : "TEST PRODUCT"}
                </h3>
                <p>
                  {oneProduct?.attributes?.description
                    ? oneProduct?.attributes?.description
                    : " Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
                </p>
                <div className="rating">
                  <img src="/images/star.png" alt="" />
                </div>
                <span className="price">
                  {oneProduct?.attributes?.price
                    ? oneProduct?.attributes?.price
                    : 150}
                </span>
                <div className="descrip">
                  <p>
                    {oneProduct?.attributes?.description
                    ? oneProduct?.attributes?.description
                    : " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book"}
                  </p>
                </div>

                <div className="count-bx">
                  {/* <button onClick={decrementCount}>-</button> */}
                  <button onClick={() => quantityMinus(oneProduct)}>-</button>
                  <div>{quantity}</div>
                  {/* <div>{count}</div> */}
                  <button onClick={() => quantityPlus(oneProduct)}>+</button>
                </div>

                <Button
                  className="btn btn-warning mt-3"
                  onClick={() => handleAddToCart()}
                >
                  Add To Cart
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Productdetails;
