import React from "react";
import { useParams } from "react-router-dom";

import Vendor from "./Single";
import Vendors from "./List";

export default function Eroticbody() {
    let { id } = useParams();
    if (isNaN(id)) {
        id = null;
    }

    let category = {
        label: "Services",
        list: [
            "Table Shower (9)",
            "Sauna (2)",
            "Jacuzzi (1)"
        ],
    };

    return id ? (
        <Vendor id={id} />
    ) : (
        <Vendors type="eroticbody" category={category} />
    );
}
