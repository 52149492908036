import { useFormik } from "formik";
import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { ContactForm } from "../helper/ValidationHelper";
import { Toast } from "./layout/Alert";
import ContactUsServices from "../services/ContactUsServices";

function Contactus() {

    const [isLoading, setIsLoading] = useState(false);


    const submitHandler = async (formvalues) => {
        try {
            setIsLoading(true);
            let response = await ContactUsServices.create({
                data: formvalues
            });
            if (response.status === 200) {
                setIsLoading(false);
                Toast.fire({
                    icon: "success",
                    title: "Your Message is successfully send",
                });
            } else {
                setIsLoading(false);
                Toast.fire({
                    icon: "warning",
                    title: "Something went wrong. try again later",
                });
            }
        } catch (err) {
            setIsLoading(false);
            let message = err.message;
            if (err.code === "ERR_BAD_REQUEST") {
                message = "Invalid email or password";
            }
            Toast.fire({ icon: "error", title: message });
        }
    };

    const initialValues = {
        name: "",
        email: "",
        note: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: ContactForm,
        onSubmit: (values, action) => {
            submitHandler(values);
            action.resetForm()
        },
    });





    return (
        <>
            <div className="main-wrap">
                <Container>
                    <h2 className="main-title">CONTACT US</h2>
                    <Row className="mt-5">
                        <Col lg={{ span: 6, offset: 3 }} md={12}>
                            <div className="sign-form-wrap">
                                <div className="signin-form">
                                    <Form
                                        noValidate
                                        className="row"
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <Form.Group className="col-md-12 mb-3">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                autoComplete="off"
                                                placeholder="Jone Doe"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                                isInvalid={formik.touched.name && formik.errors.name}
                                                className="form-control"
                                                required
                                            />
                                            {formik.touched.name && formik.errors.name ? (
                                                <span className="text-danger small">
                                                    <strong>{formik.errors.name}</strong>
                                                </span>
                                            ) : null}
                                        </Form.Group>

                                        <Form.Group className="col-md-12 mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                autoComplete="off"
                                                placeholder="example@gmail.com"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                                isInvalid={formik.touched.email && formik.errors.email}
                                                className="form-control"
                                                required
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <span className="text-danger small">
                                                    <strong>{formik.errors.email}</strong>
                                                </span>
                                            ) : null}
                                        </Form.Group>

                                        <Form.Group className="col-md-12 mb-3">
                                            <Form.Label>Note</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                type="text"
                                                name="note"
                                                autoComplete="off"
                                                placeholder="Message for us"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.note}
                                                isInvalid={formik.touched.note && formik.errors.note}
                                                className="form-control"
                                                required
                                            />
                                            {formik.touched.note && formik.errors.note ? (
                                                <span className="text-danger small">
                                                    <strong>{formik.errors.note}</strong>
                                                </span>
                                            ) : null}
                                        </Form.Group>

                                        <Form.Group className="col-md-12 mb-3 d-flex align-items-center">
                                            <Button
                                                size="lg"
                                                type="submit"
                                                disabled={isLoading}
                                                className="btn btn-warning"
                                            >
                                                {isLoading ? (
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                ) : (
                                                    "Submit"
                                                )}
                                            </Button>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Contactus;
