import React from "react";
import { Routes, Route } from "react-router-dom";

// layout
import Header from "../pages/layout/Header";
import Footer from "../pages/layout/Footer";

// public pages
import Home from "../pages/Home/Home";
import Advertising from "../pages/Advertising";
import Termsofservice from "../pages/Termsofservice";
import Privacypolicy from "../pages/Privacypolicy";
import Reporttrafficking from "../pages/Reporttrafficking";
import Contactus from "../pages/Contactus";

import Sextoy from "../pages/Place/Sextoy";
import Stripclub from "../pages/Place/Stripclub";
import Eroticbody from "../pages/Place/Eroticbody";
import Products from "../pages/Vendors/Products";

// import Cart from "../pages/Cart";
import Cart from "../pages/CartUpdated";

// Auth
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import ForgotPassword from "../pages/Auth/ForgotPassword";

// Ads
import ProviderDetails from "../pages/Provider/ProviderDetails";
import MyAdds from "../pages/Profile/MyAdds";

// Place
import Add from "../pages/Provider/Place/Add";
import List from "../pages/Provider/Place/List";
import Edit from "../pages/Provider/Place/Edit";

// Product
import ProductAdd from "../pages/Vendors/Product/Add";
import ProductEdit from "../pages/Vendors/Product/Edit";
import ProductList from "../pages/Vendors/Product/List";
import ProductListUpdated from "../pages/Vendors/ProductList";
import Productdetails from "../pages/Vendors/ProductDetails";

// Profile
import ProfileView from "../pages/Profile/ProfileView";
import ProfileEdit from "../pages/Profile/ProfileEdit";
import ChangePassword from "../pages/Profile/ChangePassword";
// Others
// import MyOrders from "../pages/Profile/MyOrders";
import OrderList from "../pages/Order/List";

import { RequireAuth, RequireAuthLogout } from "../middleware/Authenticate";
import Faq from "../pages/FAQ";
import Package from "../pages/Provider/Adds/Package";
import Checkout from "../pages/Checkout";
import PackageProvider from "../pages/Provider/Adds/PackageProvider";
import { MyWallet } from "../pages/Profile/MyWallet";
import CartOutdate from "../pages/Cart";
import MySubscription from "../pages/Profile/MySubscription";
import RateHobbiyst from "../pages/Provider/Adds/RateHobbiyst";

export default function RouteList() {
  return (
    <>
      <Header />

      <Routes>
        <Route index path="/" element={<Home />} />
        <Route index path="/ad/:page" element={<Home />} />
        {/* <Route exact path="/advertising" element={<Advertising />} /> */}
        <Route exact path="/termsofservice" element={<Termsofservice />} />
        <Route exact path="/privacypolicy" element={<Privacypolicy />} />
        <Route
          exact
          path="/reporttrafficking"
          element={<Reporttrafficking />}
        />
        <Route exact path="/contactus" element={<Contactus />} />

        <Route exact path="/eroticbody/:id" element={<Eroticbody />} />
        <Route exact path="/sextoy/:id" element={<Sextoy />} />
        <Route exact path="/stripclub/:id" element={<Stripclub />} />
        <Route exact path="/eroticbody" element={<Eroticbody />} />
        <Route exact path="/sextoy" element={<Sextoy />} />
        <Route exact path="/stripclub" element={<Stripclub />} />

        <Route
          exact
          path="/login"
          element={
            <RequireAuthLogout>
              <Login />
            </RequireAuthLogout>
          }
        />
        <Route
          exact
          path="/register"
          element={
            <RequireAuthLogout>
              <Register />
            </RequireAuthLogout>
          }
        />
        <Route
          exact
          path="/forgotPassword"
          element={
            <RequireAuthLogout>
              <ForgotPassword />
            </RequireAuthLogout>
          }
        />

        <Route
          exact
          path="/profile"
          element={
            <RequireAuth>
              <ProfileView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/profile/view"
          element={
            <RequireAuth>
              <ProfileView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/profile/edit"
          element={
            <RequireAuth>
              <ProfileEdit />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/profile/changepassword"
          element={
            <RequireAuth>
              <ChangePassword />
            </RequireAuth>
          }
        />

        <Route
          exact
          path="/place/add"
          element={
            <RequireAuth>
              <Add />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/place/view"
          element={
            <RequireAuth>
              <List />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/provider/wallet"
          element={
            <RequireAuth>
              <MyWallet />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/provider/subscription"
          element={
            <RequireAuth>
              <MySubscription />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/place/edit/:id"
          element={
            <RequireAuth>
              <Edit />
            </RequireAuth>
          }
        />

        <Route
          exact
          path="/ads/submit"
          element={
            <RequireAuth>
              <ProviderDetails />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/ads/packages/"
          element={
            <RequireAuth>
              <PackageProvider />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/ads/view"
          element={
            <RequireAuth>
              <MyAdds />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/ads/edit/:id"
          element={
            <RequireAuth>
              <MyAdds />
            </RequireAuth>
          }
        />

        <Route
          exact
          path="/products/add"
          element={
            <RequireAuth>
              <ProductAdd />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/products/view"
          element={
            <RequireAuth>
              <ProductList />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/products/edit/:id"
          element={
            <RequireAuth>
              <ProductEdit />
            </RequireAuth>
          }
        />

        <Route
          exact
          path="/orders/view"
          element={
            <RequireAuth>
              <OrderList />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="provider/ratehobyist"
          element={
            <RequireAuth>
              <RateHobbiyst />
            </RequireAuth>
          }
        />

        <Route exact path="/products" element={<Products />} />
        <Route exact path="/productsList" element={<ProductListUpdated />} />
        <Route exact path="/productdetails/:id" element={<Productdetails />} />
        {/* <Route exact path="/cartO" element={<CartOutdate/>} /> */}
        <Route exact path="/Cart" element={<Cart />} />
        <Route exact path="/Checkout" element={<Checkout />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/package" element={<PackageProvider />} />
      </Routes>

      <Footer />
    </>
  );
}
