import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { UserContext } from "../../../context/UserContext";
import HobbyistAverageService from "../../../services/HobbyistAverageService";
import HobbyistLikeService from "../../../services/HobbyistLikeService";
import HobbyistDislikeService from "../../../services/HobbyistDislikeService";
import HobbyistCommentService from "../../../services/HobbyistCommentService";

const RateOneHobbiyst = ({ row }) => {
  console.log(row);
  const [like, setlike] = useState(0);
  const [dislike, setdislike] = useState(0);
  const [avg, setAvg] = useState(0);
  const [slideDown, setslideDown] = useState(false);
  const [comments, setcomments] = useState([]);
  // const [comment, setcomment] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { userId, isHobbyist, isProvider, user } = useContext(UserContext);

  const [likecounter, setlikecounter] = useState(
    row?.attributes.hobbyist_likes
      ? row?.attributes?.hobbyist_likes?.data?.length
      : 0
  );

  const [dislikecounter, setdislikecounter] = useState(
    row?.attributes?.hobbyist_dislikes
      ? row?.attributes?.hobbyist_dislikes?.data?.length
      : 0
  );

  const [avgCounter, setAvgCounter] = useState(
    row?.attributes?.hobbyist_averages
      ? row?.attributes?.hobbyist_averages?.data?.length
      : 0
  );

  const [commentcounter, setcommentcounter] = useState(
    row.attributes.comments ? row.attributes.comments.data.length : 0
  );

  async function fetchData() {
    try {
      let qury = {};
      qury.populate = {};
      qury.populate.customer_hobbyist = true;
      qury.filters = {};
      qury.filters.customer_hobbyist = {};
      qury.filters.customer_hobbyist.id = {};
      qury.filters.customer_hobbyist.id.$eq = row.id;
      qury.filters.user_provider = {};
      qury.filters.user_provider.id = {};
      qury.filters.user_provider.id.$eq = userId;

      // console.log("Query", qury);
      let response = "";
      if (likecounter) {
        response = await HobbyistLikeService.getAll(qury);
        if (response.status === 200) {
          if (response.data.data.length) {
            setlike(parseInt(response.data.data[0].id));
          }
        }
      }
      if (dislikecounter) {
        response = await HobbyistDislikeService.getAll(qury);
        if (response.status === 200) {
          if (response.data.data.length) {
            setdislike(parseInt(response.data.data[0].id));
          }
        }
      }
      if (avgCounter) {
        response = await HobbyistAverageService.getAll(qury);
        if (response.status === 200) {
          if (response.data.data.length) {
            setAvg(parseInt(response.data.data[0].id));
          }
        }
      }
      fetchComments();
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchComments() {
    try {
      let qury = {};
      qury.populate = {};
      qury.populate.fields = ["hobbyist_comment"];
      qury.populate.user_provider = {};
      qury.populate.user_provider.fields = ["first_name", "last_name"];
      qury.filters = {};
      qury.filters.customer_hobbyist = {};
      qury.filters.customer_hobbyist.id = {};
      qury.filters.customer_hobbyist.id.$eq = row.id;
      console.log("Query", qury);
      let response = await HobbyistCommentService.getAll(qury);
      if (response.status === 200) {
        console.log("Comment REs>>", response.data);
        setcomments(response?.data?.data);
        setcommentcounter(response?.data?.data?.length);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleLike() {
    if (isProvider()) {
      if (userId > 0 && !like) {
        const response = await HobbyistLikeService.create({
          data: {
            customer_hobbyist: row.id,
            user_provider: userId,
          },
        });
        if (response.status === 200) {
          // const id = dislike;
          setlike(response.data.data.id);
          setlikecounter(likecounter + 1);
          if (dislike) {
            setdislike(0);
            setdislikecounter(dislikecounter - 1);
            await HobbyistDislikeService.delete(dislike);
          }
          if (avg) {
            setAvg(0);
            setAvgCounter(avgCounter - 1);
            await HobbyistAverageService.delete(avg);
          }
        }
      }
    }
  }

  async function handleDislike() {
    if (isProvider()) {
      if (userId > 0 && !dislike) {
        const response = await HobbyistDislikeService.create({
          data: {
            customer_hobbyist: row.id,
            user_provider: userId,
          },
        });
        if (response.status === 200) {
          setdislike(response.data.data.id);
          setdislikecounter(dislikecounter + 1);
          if (like) {
            setlike(0);
            setlikecounter(likecounter - 1);
            await HobbyistLikeService.delete(like);
          }
          if (avg) {
            setAvg(0);
            setAvgCounter(avgCounter - 1);
            await HobbyistAverageService.delete(avg);
          }
        }
      }
    }
  }

  async function handleAvg() {
    console.log("Clicked");
    if (isProvider()) {
      console.log("first if");
      if (userId > 0 && !avg) {
        // console.log("DATA PAYLOAD>>>", userId, row.id);
        const response = await HobbyistAverageService.create({
          data: {
            customer_hobbyist: row.id,
            user_provider: userId,
          },
        });
        if (response.status === 200) {
          console.log("RES AVG>>>", response.data);
          // const id = avg;
          setAvg(response.data.data.id);
          setAvgCounter(avgCounter + 1);
          if (like) {
            console.log("LikeAvg", like);
            setlike(0);
            setlikecounter(likecounter - 1);
            await HobbyistLikeService.delete(like);
          }
          if (dislike) {
            console.log("DislikeLikeAvg", dislike);
            setdislike(0);
            setdislikecounter(dislikecounter - 1);
            await HobbyistDislikeService.delete(dislike);
          }
        }
      }
    }
  }

  const handleClick = (event) => {
    event.preventDefault();
    console.log("CLickedd");
    fetchComments();
    handleShow();
    // setslideDown2((c) => false);
    // setslideDown3((c) => false);
    // setslideDown4((c) => false);
  };

  function LikeBtn({ like, likecounter, handleLike }) {
    return (
      <li id="like" onClick={handleLike}>
        {like ? (
          <i className="fa fa-thumbs-up like"></i>
        ) : (
          <i className="fa fa-thumbs-up"></i>
        )}{" "}
        {likecounter}
        {/* <Tooltip anchorSelect="#like" content="Like" /> */}
      </li>
    );
  }

  function AvgBtn({ avg, avgCounter, handleAvg }) {
    console.log(avg, avgCounter);
    return (
      <li id="avg" onClick={handleAvg}>
        {avg > 0 ? (
          <span style={{ backgroundImage: "url(/images/ico1.png)" }}>
            <img className="hvr-img" src="/images/ico1.png" alt="" />
          </span>
        ) : (
          <span style={{ backgroundImage: "url(/images/ico1.png)" }}>
            <img className="hvr-img" src="/images/ico1-x.png" alt="" />
          </span>
        )}
        {avgCounter}
        {/* <Tooltip anchorSelect="#avg" content="Avg." /> */}
      </li>
    );
  }

  function DislikeBtn({ dislike, dislikecounter, handleDislike }) {
    return (
      <li id="down" onClick={handleDislike}>
        {dislike ? (
          <i className="fa fa-thumbs-down dislike"></i>
        ) : (
          <i className="fa fa-thumbs-down"></i>
        )}{" "}
        {dislikecounter}
        {/* <Tooltip anchorSelect="#down" content="Dislike" /> */}
      </li>
    );
  }

  function CommentBtn({ slideDown, handleClick, commentcounter }) {
    return (
      <li
        id="comment"
        onClick={handleClick}
        className={slideDown ? "active" : ""}
      >
        <i className="fa fa-commenting"></i> {commentcounter}
        {/* <Tooltip anchorSelect="#comment" content="Comment" /> */}
      </li>
    );
  }

  function CommentForm({ row, fetchComments }) {
    const { isProvider, userId } = useContext(UserContext);
    const [comment, setcomment] = useState("");

    // if (!isProvider()) {
    //   return null;
    // }

    async function handleComment(e) {
      e.preventDefault();
      setcomment("");
      const response = await HobbyistCommentService.create({
        data: {
          user_provider: userId,
          customer_hobbyist: row.id,
          hobbyist_comment: comment.trim(),
        },
      });
      if (response.status === 200) {
        // console.log("comments", response.data);
        await fetchComments();
      }
    }

    return (
      <Row>
        <Col sm={9} lg={9} className="mb-3">
          <Form.Group className="col-12">
            <Form.Control
              type="text"
              value={comment}
              className="col-12 rounded-0"
              placeholder="leave your thoughts here"
              onChange={(e) => setcomment(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col sm={3} lg={3}>
          <Form.Group className="">
            <Button
              type="button"
              onClick={(e) => handleComment(e)}
              disabled={!comment.length}
              className="btn btn-warning w-100"
            >
              Post
            </Button>
          </Form.Group>
        </Col>
      </Row>
    );
  }

  function CommentList({ comments }) {
    // console.log("Comments>>>>>", comments);

    return (
      <Row>
        <div>
          <ul className="comment-wrap p-0">
            {comments.length &&
              comments.map((row) => {
                const user = row?.attributes?.user_provider?.data?.attributes;
                // console.log("user", user);
                return (
                  <>
                    <li key={row.id}>
                      <div className="comment-inner">
                        <h4>
                          {user?.first_name} {user?.last_name}
                          {/* <span>
                          {calculateTimeDifference(row.attributes.createdAt)}
                        </span> */}
                        </h4>
                        <p>
                          {row?.attributes?.hobbyist_comment
                            ? row?.attributes?.hobbyist_comment
                            : null}
                        </p>
                      </div>
                    </li>
                  </>
                );
              })}
          </ul>
        </div>
      </Row>
    );
  }

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (!userId && (like || dislike)) {
      setAvg(0);
      setlike(0);
      setdislike(0);
    }
  }, [userId, like, dislike]);

  return (
    <>
      <Col md={6}>
        <div className="rate-bx">
          <ul className="feedback-lst p-3">
            <li id="like">
              <LikeBtn
                like={like}
                likecounter={likecounter}
                handleLike={handleLike}
              />
            </li>
            <li id="avg">
              <AvgBtn avg={avg} avgCounter={avgCounter} handleAvg={handleAvg} />
            </li>
            <li id="down">
              <DislikeBtn
                dislike={dislike}
                dislikecounter={dislikecounter}
                handleDislike={handleDislike}
              />
            </li>
            <li id="down">
              <i className="fa fa-phone"></i> {row?.attributes?.phone_number}
            </li>
            <li id="comment">
              {/* <i className="fa fa-commenting"></i> 10 */}
              <CommentBtn
                handleClick={handleClick}
                commentcounter={commentcounter}
              />
            </li>
          </ul>
          <ul className="comment-lst">
            {comments.length
              ? comments.map((comment) => {
                  const _user =
                    comment?.attributes?.user_provider?.data?.attributes;
                  //  console.log("user", _user);
                  return (
                    <li>
                      <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {_user?.first_name} {_user?.last_name}
                      </span>
                      <div>
                        {comment?.attributes?.hobbyist_comment
                          ? comment?.attributes?.hobbyist_comment
                          : null}
                        .
                      </div>
                    </li>
                  );
                })
              : null}

            {/* <li>
              <img className="hvr-img" src="/images/ico1-x.png" alt="" /> Ok,
              Nothing special.
            </li>
            <li>
              <i className="fa fa-thumbs-up"></i> Very Nice.
            </li> */}
          </ul>
        </div>
      </Col>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {/* <CommentList comments={comments} /> */}
          <CommentForm row={row} fetchComments={fetchComments} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RateOneHobbiyst;
