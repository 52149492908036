import React, { useState, useEffect } from "react";
import MultiRangeSlider from "multi-range-slider-react";
import { Collapse } from "react-bootstrap";

export const DonationFilter = ({
  setMaxValueDonation,
  setMinValueDonation,
}) => {
  const [open5, setOpen5] = useState(true);
  const [minValue, setMinValue] = useState(1);
  const [maxValue, setMaxValue] = useState(1000);

  useEffect(() => {
    let v = setTimeout(() => {
      if (open5) {
        setMinValueDonation(() => minValue);
        setMaxValueDonation(() => maxValue);
      } else {
        setMinValueDonation(() => 0);
        setMaxValueDonation(() => 0);
      }
    }, 100);

    return () => {
      clearTimeout(v);
    };
  }, [minValue, maxValue, open5, setMinValueDonation, setMaxValueDonation]);

  return (
    <div className="category-list mb-3">
      <a
        className="expand"
        onClick={() => setOpen5(!open5)}
        aria-controls="category5"
        aria-expanded={open5}
      >
        <div className="right-arrow">
          <i className="fa fa-angle-down"></i>
        </div>
        <div>
          <h3 className="hd3">Donation</h3>
          {/* <span>{minValue}</span>
          <span className="range-max-value">{maxValue}</span> */}
        </div>
      </a>
      <Collapse className="category-body" id="category5" in={open5}>
        <MultiRangeSlider
          min={1}
          max={1000}
          minValue={minValue}
          maxValue={maxValue}
          onChange={(e) => {
            setMinValue(() => e.minValue);
            setMaxValue(() => e.maxValue);
          }}
        ></MultiRangeSlider>
      </Collapse>
      <label className="form-check-label">
        <input
          className="form-check-input me-2"
          type="checkbox"
          checked={!open5}
          onChange={() => {
            setOpen5((c) => {
              return !c;
            });
          }}
        />
        N/A
      </label>
    </div>
  );
};
