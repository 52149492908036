import axios from "axios";
import HttpHelper from "../helper/HttpHelper";

class TipService {
  create(payload) {
    return axios.post(
      HttpHelper.API_URL + "/tips",
      payload,
      HttpHelper.getAuthHeader()
    );
  }
}

export default TipService = new TipService();
