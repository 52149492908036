import { createContext, useState, useEffect } from "react";
import React from "react";

export const CatagoryContext = createContext(null);

export const CatagoryContextProvider = (props) => {
  const [catagory, setCatagory] = useState({});
  // const [pageSizeValue, setPageSizeValue] = useState(10);
  const [query, setQuery] = useState({
    pagination: { page: 1, pageSize: 10 },
    sort: ["id:desc"],
    filters: {
      isShowed: {
        $eq: true,
      },
    },
    populate: [
      "user",
      "video",
      "messages",
      "comments",
      "dislikes",
      "images",
      "language",
      "likes",
      "race",
      "averages",
    ],
  });

  const updateCatagory = (value) => {
    setCatagory((old) => {
      return { ...old, ...value };
    });
  };

  const contextValue = {
    catagory,
    updateCatagory,
    query,
    setQuery,
  };

  useEffect(() => {
    console.log("catagory update=======================================>", catagory);
    if (Object.keys(catagory).length) {
      setQuery((old) => {
        let qury = { ...old };
        qury.filters = {
          isShowed: {
            $eq: true,
          }
        };
        for (let x in catagory) {
          console.log(x, catagory[x], catagory[x].length);
          if (catagory[x].length) {
            qury.filters[x] = {};
            if (catagory[x] && catagory[x] !== "N/A") {
              if (x === "language") {
                qury.filters[x].id = {};
                qury.filters[x].id.$in = catagory[x];
              } else if (x === "race") {
                qury.filters[x].id = {};
                qury.filters[x].id.$in = catagory[x];
              } else if (x === "age" || x === "donation_hour") {
                qury.filters[x].$between = catagory[x];
              } else if (x === "country" || x === "state" || x === "city") {
                qury.filters[x].$in = catagory[x];
              }else {
                qury.filters[x].$contains = catagory[x];
              }
            }
          }
        }
        return qury;
      });
    }
  }, [catagory]);

  return (
    <CatagoryContext.Provider value={contextValue}>
      {props.children}
    </CatagoryContext.Provider>
  );
};
