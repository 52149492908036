import React, { useEffect } from "react";
import { useState } from "react";
import { Collapse } from "react-bootstrap";
import MultiRangeSlider from "multi-range-slider-react";

export const AgeFilter = ({ setMinValueAge, setMaxValueAge }) => {
  const [open5, setOpen5] = useState(true);
  const [minValue, setMinValue] = useState(18);
  const [maxValue, setMaxValue] = useState(100);

  useEffect(() => {
    let v = setTimeout(() => {
      if (open5) {
        setMinValueAge(() => minValue);
        setMaxValueAge(() => maxValue);
      } else {
        setMinValueAge(() => 0);
        setMaxValueAge(() => 0);
      }
    }, 500);

    return () => {
      clearTimeout(v);
    };
  }, [minValue, maxValue, setMinValueAge, setMaxValueAge, open5]);

  return (
    <div className="category-list mb-3">
      <a
        className="expand"
        onClick={() => setOpen5(!open5)}
        aria-controls="category5"
        aria-expanded={open5}
      >
        <div className="right-arrow">
          <i className="fa fa-angle-down"></i>
        </div>
        <div>
          <h3 className="hd3">Age</h3>
          {/* <span>{minValue}</span>
          <span className="range-max-value">{maxValue}</span> */}
        </div>
      </a>
      <Collapse className="category-body" id="category5" in={open5}>
        <MultiRangeSlider
          min={18}
          max={100}
          minValue={minValue}
          maxValue={maxValue}
          onChange={(e) => {
            setMinValue(() => e.minValue);
            setMaxValue(() => e.maxValue);
          }}
        ></MultiRangeSlider>
      </Collapse>
      {/* <input
        class="form-check-input"
        type="checkbox"
        onChange={() => {
          setOpen5((c) => !c);
          setMaxValue(() => 0);
          setMinValue(() => 0);
        }}
      /> */}
      <label className="form-check-label">
        <input
          className="form-check-input me-2"
          type="checkbox"
          checked={!open5}
          onChange={() => {
            setOpen5((c) => {
              return !c;
            });
          }}
        />
        N/A
      </label>

      {/* <input  type="checkbox" />
      <label >N/A</label> */}
    </div>
  );
};
