import React from "react";
import { Link } from "react-router-dom";

import card from '../../card.png';

function Footer() {
    return (
        <>

            <div className="footer">
                <div className="container">
                    <ul className="footer-menu">
                        <li><Link to="/termsofservice">TERMS OF SERVICE</Link></li>
                        <li><Link to="/privacypolicy">PRIVACY POLICY</Link></li>
                        <li><Link to="/reporttrafficking">REPORT TRAFFICKING</Link></li>
                        <li><Link to="/contactus">CONTACT US</Link></li>
                    </ul>

                    <ul className="social-lnk">
                        <li><a href="https://www.facebook.com/CEO.HotSpicyGirls" target="_blank"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="https://twitter.com/CEO_HSG" target="_blank"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/ceo.hotspicygirls" target="_blank"><i className="fa fa-instagram"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCZZkR97xZPTu9lih8fgNfaA" target="_blank"><i className="fa fa-youtube-play"></i></a></li>
                    </ul>

                    <ul className="social-lnk">
                        <li>James Divine - Hot Spicy Girls CEO</li>
                        <li>Google: ceo.hotspicygirls@gmail.com</li>
                    </ul>

                    <img src={card} style={{ width: "120px", marginTop: "20px" }} alt="fireSpot" />

                    <p className="copyright">© Copyright 2023. All rights reserved</p>
                </div>
            </div>

        </>
    );
}

export default Footer;