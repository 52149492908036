import React, { useState } from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationComponent = ({ pagination, filtersByPagination }) => {
  console.log('PaginationComponent')
  const [activePage, setActivePage] = useState(pagination.page);

  const pageNumbers = [];
  for (let i = 1; i <= pagination.pageCount; i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    setActivePage(pageNumber);
    filtersByPagination(pageNumber);
  };

  return (
    <Pagination>
      <Pagination.Prev />
      {pageNumbers.map((pageNumber) => (
        <Pagination.Item
          key={pageNumber}
          active={pageNumber === activePage}
          // href={`/ad/${pageNumber}`}
          onClick={() => handlePageClick(pageNumber)}
        >
          {pageNumber}
        </Pagination.Item>
      ))}
      <Pagination.Next />
    </Pagination>
  );
}

export default PaginationComponent;
