import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class CommentService {

    getAll(payload) {
        return axios.get(HttpHelper.API_URL + '/comments', { params: payload }, HttpHelper.getHeader());
    }

    getOne(id, payload) {
        return axios.get(HttpHelper.API_URL + '/comments/' + id, { params: payload }, HttpHelper.getHeader());
    }

    create(payload) {
        return axios.post(HttpHelper.API_URL + '/comments', payload, HttpHelper.getAuthHeader());
    }

    update(id, payload) {
        return axios.put(HttpHelper.API_URL + "/comments/" + id, payload, HttpHelper.getAuthHeader());
    }

    delete(id) {
        return axios.delete(HttpHelper.API_URL + "/comments/" + id, HttpHelper.getAuthHeader());
    }

}

export default CommentService = new CommentService();