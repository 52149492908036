import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";


function Reporttrafficking() {
    return(
        <>
            <div className="main-wrap">
                <Container>
                    <h2 className="main-title">REPORT TRAFFICKING</h2>

                    <Row className="mt-5">
                        <Col md={{ span: 10, offset: 1 }}>
                        How Do I Report a Suspected Incidence of Human Trafficking?

                            - Call the toll-free (24/7) National Human Trafficking Resource Center at 1-888-373-7888.
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Reporttrafficking;