import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class ProviderService {

    getAll(payload) {
        return axios.get(HttpHelper.API_URL + '/providers', { params: payload }, HttpHelper.getHeader());
    }

    getOneProvider(id, payload) {
        return axios.get(HttpHelper.API_URL + '/providers/' + id, { params: payload }, HttpHelper.getHeader());
    }

    create(payload) {
        return axios.post(HttpHelper.API_URL + '/providers', payload, HttpHelper.getAuthHeader());
    }

    update(id, payload) {
        return axios.put(HttpHelper.API_URL + "/providers/" + id, payload, HttpHelper.getAuthHeader());
    }

    delete(id) {
        return axios.delete(HttpHelper.API_URL + "/providers/" + id, HttpHelper.getAuthHeader());
    }

}

export default new ProviderService();