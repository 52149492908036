import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class CountryService {
    getProviderByCountry(payload) {
         return axios.get(HttpHelper.API_URL + '/provider-count-by-country', { params: payload }, HttpHelper.getHeader());
     }

}

export default CountryService = new CountryService();