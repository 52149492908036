import React from "react";
import { useParams } from "react-router-dom";

import Vendor from "./Single";
import Vendors from "./List";

export default function Eroticbody() {
    let { id } = useParams();
    if (isNaN(id)) {
        id = null;
    }

    let category = {
        label: "Sex Shops Forums",
        list: [
            "210-300-2215 (2 replies)",
            "Suck my cock at peep show"
        ],
    };

    return id ? <Vendor id={id} /> : <Vendors type="sextoy" category={category}/>;
}
