import axios from "axios";
import HttpHelper from "../helper/HttpHelper";

class TransactionService {
  create(payload) {
    return axios.post(
      HttpHelper.API_URL + "/transactions",
      payload,
      HttpHelper.getHeader()
    );
  }

  getAll(payload) {
    return axios.get(
      HttpHelper.API_URL + "/transactions",
      { params: payload },
      HttpHelper.getHeader()
    );
  }
}

export default TransactionService = new TransactionService();
