import axios from "axios";
import HttpHelper from "../helper/HttpHelper";

class UserService {
  getAll(payload) {
    let config = HttpHelper.getAuthHeader();
    config.params = payload;
    return axios.get(HttpHelper.API_URL + "/users", config);
  }

  getOne(id, payload) {
    let config = HttpHelper.getAuthHeader();
    config.params = payload;
    return axios.get(HttpHelper.API_URL + "/users/" + id, config);
  }

  getMe() {
    return axios.get(
      HttpHelper.API_URL + "/users/me",
      HttpHelper.getAuthHeader()
    );
  }

  update(id, payload) {
    return axios.put(
      HttpHelper.API_URL + "/users/" + id,
      payload,
      HttpHelper.getAuthHeader()
    );
  }

  changePassword(payload) {
    return axios.post(
      HttpHelper.API_URL + "/auth/change-password",
      payload,
      HttpHelper.getAuthHeader()
    );
  }
}

export default new UserService();
