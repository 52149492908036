import { createContext, useState } from "react";
import StorageHelper from "../helper/StorageHelper";
import { useNavigate } from "react-router-dom";

export const UserContext = createContext(null);

export const UserContextProvider = (props) => {
  const userDetails = StorageHelper.getUser();

  var init = {
    user: {
      id: 0,
      username: "",
      email: "",
      provider: null,
      phone_number: "",
      dob: "",
      city: "",
      user_type: "",
      is_active: true,
      first_name: "",
      last_name: "",
      avatar: {
        id: 0,
        url: "../images/placeholder1.jpg",
        name: "",
        formats: null,
      },
    },
    user_id: 0,
    token: null,
    subscribed: false,
    processingCosts: {
      tax: "",
      processing_fee: "",
      shipping_cost: "",
    },
  };

  if (userDetails) {
    init.user.id = userDetails?.id ? userDetails?.id : init?.user?.id;
    init.user.username = userDetails?.username;
    init.user.email = userDetails?.email;
    init.user.provider = userDetails?.provider
      ? userDetails?.provider
      : init?.user?.provider;
    init.user.phone_number = userDetails?.phone_number;
    init.user.dob = userDetails?.dob;
    init.user.city = userDetails?.city;
    init.user.user_type = userDetails?.user_type;
    init.user.is_active = userDetails?.is_active;
    init.user.first_name = userDetails?.first_name;
    init.user.last_name = userDetails?.last_name;
    init.user.avatar.id = userDetails?.avatar?.id
      ? userDetails?.avatar?.id
      : init?.user?.avatar?.id;
    init.user.avatar.url = userDetails?.avatar?.url
      ? userDetails?.avatar?.url
      : init?.user?.avatar?.url;
    init.user.avatar.name = userDetails?.avatar?.name;
    init.user.avatar.formats = userDetails?.avatar?.formats
      ? userDetails?.avatar?.formats
      : init?.user?.avatar?.formats;
  }

  const [totalDebit, setTotalDebit] = useState(0);
  const [totalWalletBalance, setTotalWalletBalance] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalCreditPackage, setTotalCreditPackage] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [user, setUser] = useState(init.user);
  const [token, setToken] = useState(StorageHelper.getToken() || init.token);
  const [userId, setUserId] = useState(
    StorageHelper.getUserId() || init.user_id
  );
  const [isSubscribed, setIsSubscribed] = useState(
    StorageHelper.getIsSubscribed() || init.subscribed
  );
  const [procssingCost, setProcssingCost] = useState(
    StorageHelper.getProcessingCosts() || init.processingCosts
  );

  const updateUser = (data) => {
    setUser(data);
    StorageHelper.setUser(data);
  };

  const updateUserId = (data) => {
    setUserId(data);
    StorageHelper.setUserId(data);
  };

  const updateToken = (data) => {
    setToken(data);
    StorageHelper.setToken(data);
  };

  const updateIsSubscribed = (data) => {
    setIsSubscribed(data);
    StorageHelper.setIsSubscribed(data);
  };

  const updateProcessingCosts = (data) => {
    setProcssingCost(data);
    StorageHelper.setProcssingCosts(data);
  };

  const reset = () => {
    setUser(init.user);
    setToken(init.token);
    setUserId(init.user_id);
  };

  const logout = () => {
    setUser(init.user);
    setToken(init.token);
    setUserId(init.user_id);
    setIsSubscribed(init.subscribed);
    setProcssingCost(init.processingCosts);
    StorageHelper.removeAll();
  };

  const isLogin = () => {
    return token ? true : false;
  };

  const hasUserId = () => {
    return userId > 0 ? true : false;
  };

  const isHobbyist = () => {
    return user.user_type === "Hobbyist" ? true : false;
  };

  const isProvider = () => {
    return user.user_type === "Provider" ? true : false;
  };

  const isVender = () => {
    return user.user_type === "Vender" ? true : false;
  };

  const contextValue = {
    init,
    user,
    token,
    userId,
    reset,
    logout,
    hasUserId,
    isLogin,
    isProvider,
    isHobbyist,
    isVender,
    updateUser,
    updateToken,
    updateUserId,
    setTotalDebit,
    setTotalCredit,
    totalDebit,
    totalCredit,
    totalCreditPackage,
    setTotalCreditPackage,
    transactions,
    setTransactions,
    totalWalletBalance,
    setTotalWalletBalance,
    isSubscribed,
    setIsSubscribed,
    updateIsSubscribed,
    updateProcessingCosts,
    procssingCost,
    setProcssingCost,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
};
