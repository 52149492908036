import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse';
import { Form } from 'react-bootstrap';


const GroomingFilter = ({handleChange,grooming}) => {

    const [open18, setOpen18] = useState(true);
    const [groomingChecked, setGroomingChecked] = useState([{}]);
    const handleClickCheckbox = (event) => {
        console.log('handleClickCheckbox')
        handleChange(event);
        const { value } = event.target;
        if(value){
            const prevState = { ...groomingChecked, [value]: !groomingChecked[value] };
            setGroomingChecked(prevState);
        }else{
            //const prevState = [];
            //setGroomingChecked(prevState);

            if(event.target.checked){
                const prevState = [];
                setGroomingChecked(prevState);
            }else{
                const prevState = { ...groomingChecked, ['test']: !groomingChecked['test'] };
                setGroomingChecked(prevState);
            }
        }
        console.log('lang check uncheck is call ',groomingChecked)
    };


    return (
        <div className="category-list mb-3">
            <div className="expand" onClick={() => setOpen18(!open18)} aria-controls="category18" aria-expanded={open18}>
                <div className="right-arrow"><i className='fa fa-angle-down'></i></div>
                <div><h3 className="hd3">Grooming</h3></div>
            </div>
            <Collapse className="category-body" id="category18" in={open18}>
                <ul>
                    <li>
                        <Form.Check inline name="grooming" id="grooming1" className="mt-2" label={`Natural (${grooming[0]?._Natural})`} value="Natural" type="checkbox" onChange={handleClickCheckbox} checked={groomingChecked['Natural']} />
                    </li>
                    <li>
                        <Form.Check inline name="grooming" id="grooming2" className="mt-2" label={`Trimmed (${grooming[0]?.Trimmed})`} value="Trimmed" type="checkbox" onChange={handleClickCheckbox} checked={groomingChecked['Trimmed']} />
                    </li>
                    <li>
                        <Form.Check inline name="grooming" id="grooming3" className="mt-2" label={`Bare (${grooming[0]?.Bare})`} value="Bare" type="checkbox" onChange={handleClickCheckbox} checked={groomingChecked['Bare']} />
                    </li>
                    <li>
                        <Form.Check inline name="grooming" id="grooming4" className="mt-2" label={`N/A`} value="" type="checkbox" onChange={handleClickCheckbox} checked={groomingChecked?.length === 0 ? true : false} />
                    </li>
                </ul>
            </Collapse>
        </div>
    )
}

export default GroomingFilter;
