import * as Yup from "yup";

export const LoginForm = Yup.object().shape({
  email: Yup.string().required("Email is required").email(),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum")
    .max(64, "Password is too long - should be 30 chars maximun"),
});

export const ForgotPasswordForm = Yup.object().shape({
  email: Yup.string()
    .email("Email is not valid")
    .max(160)
    .required("Email is required"),
});

export const ResetPasswordForm = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum")
    .max(64, "Password is too long - should be 30 chars maximun"),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export const ChangePasswordForm = Yup.object().shape({
  currentPassword: Yup.string()
    .required("Current Password is required")
    .min(6, "Current Password is too short - should be 6 chars minimum")
    .max(64, "Current Password is too long - should be 30 chars maximun"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum")
    .max(64, "Password is too long - should be 30 chars maximun"),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export const ContactForm = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Name is required"),

  email: Yup.string().email().max(160).required("Email is required"),
  // phone_numder: Yup.string()
  //     .required("Phone number is required")
  //     .matches(new RegExp("[0-9]{10}"), "Invalid phone number")
  //     .min(4, "Phone number should not be lass than 4 character")
  //     .max(15, "Phone number should not be more than 15 character"),
  note: Yup.string()
    .min(5, "Too Short!")
    .max(200, "Too Long!")
    .required("Note is required"),
});

export const PlaceAddForm = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Name is required"),
  vendor_type: Yup.string().required("Type is required"),
  location: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  postal_code: Yup.string().required("Postal Code is required"),
  email: Yup.string().email().max(160),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(new RegExp("^[0-9]*$"), "Invalid phone number")
    .min(4, "Phone number should not be lass than 4 character")
    .max(15, "Phone number should not be more than 15 character"),
  image: Yup.mixed().required("Required"),
});
export const CheckoutForm = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First name is required"),
  last_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last name is required"),
  email_address: Yup.string()
    .required("Email address is required")
    .email()
    .max(160),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(new RegExp("^[0-9]*$"), "Invalid phone number")
    .min(10, "Phone number should not be less than 10 character"),
  country: Yup.string().required("Country is required"),
  // address: Yup.string().required("Address is required"),
  town: Yup.string().required("Town is required"),
  postcode: Yup.string().required("Postal Code is required"),
  transaction_id: Yup.string().required("transaction_id is required"),
  // image: Yup.mixed().required("Required"),
});

export const PlaceEditForm = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Name is required"),
  vendor_type: Yup.string().required("Type is required"),
  location: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  postal_code: Yup.string().required("Postal Code is required"),
  email: Yup.string().email().max(160),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(new RegExp("^[0-9]*$"), "Invalid phone number")
    .min(4, "Phone number should not be lass than 4 character")
    .max(15, "Phone number should not be more than 15 character"),
});

export const ProductEditForm = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Name is required"),
  description: Yup.string().required("description is required"),
  price: Yup.string()
    .required("price is required")
    .matches(new RegExp("^[0-9]*$"), "Invalid price")
    .min(1, "Price should not be lass than 1 digit")
    .max(10, "Price should not be more than 10 digit"),
});

export const ProductAddForm = Yup.object().shape({
  picture: Yup.mixed().required("Required"),
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Name is required"),
  description: Yup.string()
    .required("description is required")
    .min(2, "Too Short!")
    .max(200, "Too Long!"),
  price: Yup.string()
    .required("price is required")
    .matches(new RegExp("^[0-9]*$"), "Invalid price")
    .min(1, "Price should not be lass than 1 digit")
    .max(10, "Price should not be more than 10 digit"),
});

export const RegisterForm = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  email: Yup.string().email().max(160).required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum")
    .max(64, "Password is too long - should be 30 chars maximun"),
  cpassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  first_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First name is required"),
  last_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last name is required"),
  phone_number: Yup.string()
    .required("Phone number is required")
    .matches(new RegExp("[0-9]{10}"), "Invalid phone number")
    .min(4, "Phone number should not be lass than 4 character")
    .max(15, "Phone number should not be more than 15 character")
    .required("Phone number is required"),
  city: Yup.string().required("City is required"),
  dob: Yup.date()
    .nullable()
    .max(
      new Date(Date.now() - 18 * 365 * 24 * 60 * 60 * 1000),
      "You must be at least 18 years old"
    )
    .required("Date of birth is required")
    .typeError("Invalid date of birth"),
  user_type: Yup.string()
    .oneOf(["Hobbyist", "Provider", "Vender"], "Invalid option")
    .required("User Type is required"),
});
