import { createContext, useState, useEffect } from "react";

import StorageHelper from "../helper/StorageHelper";

export const CartContext = createContext(null);

export const CartContextProvider = (props) => {
  const [cartItems, setCartItems] = useState(StorageHelper.getCart() || []);

  const getCartTotalAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      console.log("Price", item);
      totalAmount += parseFloat(
        cartItems[item]?.attributes?.price * cartItems[item]?.quantiy
      );
    }
    // console.log("Total In Context", totalAmount);
    return totalAmount;
  };

  const getTotalItemCount = () => {
    let count = 0;
    for (const item in cartItems) {
      if (cartItems[item]) count += 1;
    }
    return count;
  };

  const getOneItemCount = (itemId) => {
    let arr = 0;
    for (const i in cartItems) {
      if (cartItems[i].id === itemId) {
        arr = 1;
      }
    }
    return arr;
  };

  const addToCart = (item) => {
    console.log(item);
    console.log(cartItems);
    // setCartItems((prev) => ({ ...prev, [item.id]: item }));
    setCartItems([...cartItems, item]);
    // let arr = [];
    // let ext = cartItems.length;
    // if (cartItems.length) {
    //     for (const i in cartItems) {
    //         arr[i] = cartItems[i];
    //         if (cartItems[i].id === item.id) {
    //             ext = i;
    //         }
    //     }
    // }
    // arr[ext] = item;
    // setCartItems(arr);
  };

  const updateToCart = (item) => {
    console.log(item);
    console.log(cartItems);
    let arr = [];
    if (cartItems.length) {
      for (const i in cartItems) {
        arr[i] = cartItems[i];
        if (cartItems[i].id === item.id) {
          arr[i] = item;
        }
      }
    }
    setCartItems(arr);
  };

  const removeFromCart = (itemId) => {
    console.log("I am removed", itemId);
    console.log(itemId);
    console.log(cartItems);
    // setCartItems((prev) => ({ ...prev, [itemId]: '' }));
    setCartItems(cartItems.filter((item) => item.id !== itemId));
    // if (cartItems.length) {
    //     // let arr = [];
    //     // for (const i in cartItems) {
    //     //     if (cartItems[i].id !== itemId) {
    //     //         arr[i] = cartItems[i];
    //     //     }
    //     // }

    //     let arr = cartItems.filter((item) => {
    //         return item.id !== itemId;
    //     });

    //     setCartItems(arr);
    // } else {
    //     setCartItems([]);
    // }
  };

  const getItemById = (itemId) => {
    console.log(itemId);
    console.log(cartItems);
    if (cartItems.length) {
      let arr = cartItems.filter((item) => {
        return parseInt(item.id) === parseInt(itemId);
      });
      console.log(arr);
      return arr[0];
    } else {
      return false;
    }
  };

  const getQuantiyById = (itemId) => {
    console.log(itemId);
    console.log(cartItems);
    if (cartItems.length) {
      let arr = cartItems.filter((item) => {
        console.log(item);
        return parseInt(item.id) === parseInt(itemId);
      });
      console.log(arr);
      return arr[0]?.quantiy;
    } else {
      return 0;
    }
  };

  const clearCartItem = () => {
    StorageHelper.setCart([]);
    setCartItems([]);
  };

  const contextValue = {
    cartItems,
    addToCart,
    updateToCart,
    removeFromCart,
    getCartTotalAmount,
    getTotalItemCount,
    getOneItemCount,
    clearCartItem,
    getQuantiyById,
    getItemById,
  };

  useEffect(() => {
    StorageHelper.setCart(cartItems);
  }, [cartItems]);

  return (
    <CartContext.Provider value={contextValue}>
      {props.children}
    </CartContext.Provider>
  );
};
