import axios from "axios";
import HttpHelper from "../helper/HttpHelper";

class PackageServices {
  getAll(payload) {
    return axios.get(
      HttpHelper.API_URL + "/packages",
      {
        params: payload,
      },
      HttpHelper.getHeader()
    );
  }
  getOne(id, payload) {
    return axios.get(
      HttpHelper.API_URL + "/packages/" + id,
      { params: payload },
      HttpHelper.getHeader()
    );
  }
}

export default PackageServices = new PackageServices();
