import React, { useCallback } from "react";
import { useState, useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import MultiRangeSlider from "multi-range-slider-react";

import RaceService from "../../services/RaceService";
import LangService from "../../services/LangService";
import FilterService from "../../services/FilterService";

import { CatagoryContext } from "../../context/CatagoryContext";
import LanguageFilter from "./SidebarComponents/LanguageFilter";
import RacesFilter from "./SidebarComponents/RacesFilter";
import CallFilter from "./SidebarComponents/CallFilter";
import KissingFilter from "./SidebarComponents/KissingFilter";
import FellatioFilter from "./SidebarComponents/FellatioFilter";
import CunnilingusFilter from "./SidebarComponents/CunnilingusFilter";
import AnilingusFilter from "./SidebarComponents/AnilingusFilter";
import VaginalFilter from "./SidebarComponents/VaginalFilter";
import AnalFilter from "./SidebarComponents/AnalFilter";
import CumFilter from "./SidebarComponents/CumFilter";
import ThreesomeFIlter from "./SidebarComponents/ThreesomeFIlter";
import MassageFilter from "./SidebarComponents/MassageFilter";
import PersonalityFilter from "./SidebarComponents/PersonalityFilter";
import GroomingFilter from "./SidebarComponents/GroomingFilter";
import OtherServicesFilter from "./SidebarComponents/OtherServicesFilter";
import { AgeFilter } from "./SidebarComponents/AgeFilter";
import { DonationFilter } from "./SidebarComponents/DonationFilter.js";

function Sidebar() {
  const { updateCatagory, catagory } = useContext(CatagoryContext);

  const stval = true;
  const [open20, setOpen20] = useState(stval);
  const [showPanel, setshowPanel] = useState(null);

  const handleClick = (event) => {
    setshowPanel((current) => (current === "hide" ? null : "hide"));
  };

  const [inputs, setInputs] = useState({});

  const [races, setRaces] = useState([]);
  const [lang, setLang] = useState([]);
  const [call, setCall] = useState([]);
  const [kissing, setKissing] = useState([]);
  const [fellatio, setFellatio] = useState([]);
  const [anilingus, setAnilingus] = useState([]);
  const [cunnilingus, setCunnilingus] = useState([]);
  const [vaginal, setVaginal] = useState([]);
  const [anal, setAnal] = useState([]);
  const [cum, setCum] = useState([]);
  const [threesome, setThreesome] = useState([]);
  const [massage, setMassage] = useState([]);
  const [personality, setPersonality] = useState([]);
  const [grooming, setGrooming] = useState([]);
  const [minValue, setMinValueAge] = useState(0);
  const [maxValue, setMaxValueAge] = useState(0);
  const [maxValueDonation, setMaxValueDonation] = useState(0);
  const [minValueDonation, setMinValueDonation] = useState(0);
  const [country, setCountry] = useState("US");
  const [state, setState] = useState();
  const [city, setCity] = useState([]);

  const handleChange = useCallback(
    (event) => {
      console.log("handleChange");
      const target = event.target;
      const name = target.name;
      var copy = { ...inputs };
      console.log("category is calling===============> ", catagory);
      for (let x in catagory) {
        if (catagory[x].length) {
          console.log("cate is calling=====> ", catagory[x]);
            copy[x] = catagory[x];
          
        }
      }
      console.log("copy category state ======> ", copy);
      if (target.type === "checkbox") {
        if (target.value === "" || target.value === "N/A") {
          copy[name] = [];
        } else {
          // console.log(name)
          if (
            [
              "kissing_types",
              "fellatio_type",
              "anilingus_act",
              "vaginal_type",
              "anal_activity",
              "cum",
              "language",
              "race"
            ].includes(name)
          ) {
            
            if (!(name in copy)) {
              copy[name] = [];
            }
            if (target.checked) {
              copy[name].push(target.value);
            } else {
              copy[name] = copy[name].filter((el) => el !== target.value);
            }
            console.log('this is the name  ========>',copy);
            if (name === "kissing_types") {
              if (copy[name].length) {
                copy["kissing"] = "yes";
              } else {
                copy["kissing"] = "";
              }
            } else if (name === "fellatio_type") {
              if (copy[name].length) {
                copy["fellatio"] = "yes";
              } else {
                copy["fellatio"] = "";
              }
            } else if (name === "anilingus_act") {
              if (copy[name].length) {
                copy["anilingus"] = "yes";
              } else {
                copy["anilingus"] = "";
              }
            } else if (name === "vaginal_type") {
              if (copy[name].length) {
                copy["vaginal"] = "yes";
              } else {
                copy["vaginal"] = "";
              }
            } else if (name === "anal_activity") {
              if (copy[name].length) {
                copy["anal"] = "yes";
              } else {
                copy["anal"] = "";
              }
            } else if (name === "cum") {
              if (!("cum_type" in copy)) {
                copy["cum_type"] = [];
              }
              if (target.checked) {
                copy["cum_type"].push("Swallow");
                copy["cum_type"].push("Drool");
                copy["cum_type"].push("Spit");
                //  copy[name].push('CIM');
              } else {
                copy["cum_type"] = copy["cum_type"].filter(
                  (el) => el !== "Swallow"
                );
                copy["cum_type"] = copy["cum_type"].filter(
                  (el) => el !== "Drool"
                );
                copy["cum_type"] = copy["cum_type"].filter(
                  (el) => el !== "Spit"
                );
                // copy[name] = copy[name].filter((el) => el !== target.value);
              }
            }
          } else if (target.name === "call") {
            if (target.checked) {
              if (target.id === "Both") {
                copy[name] = ["Incall", "Outcall", "Outcall/Incall"];
              } else {
                copy[name] = [target.value];
              }
            } else {
              if (target.id === "Both") {
                copy[name] = [];
              } else {
                copy[name] = copy[name].filter((el) => el !== "Outcall/Incall");
                copy[name] = copy[name].filter((el) => el !== target.value);
              }
            }
          } else {
            if (!(name in copy)) {
              copy[name] = [];
            }
            if (target.checked) {
              copy[name].push(target.value);
            } else {
              copy[name] = copy[name].filter((el) => el !== target.value);
            }
          }
        }
      } else {
        if (!(name in copy)) {
          copy[name] = target.value;
        }
        copy[name] = target.value;
        if (target.name === "kissing") {
          delete copy["kissing_types"];
        }
        if (target.name === "fellatio") {
          delete copy["fellatio_type"];
        }
        if (target.name === "cum" && target.value === "any") {
          copy["cum_type"] = [
            "CIP",
            "COF",
            "COT",
            "COB",
            "COA",
            "CIA",
            "Swallow",
            "Drool",
            "Spit",
          ];
          copy["cum"] = ["any", "CIM"];
        } else if (target.name === "cum" && target.value !== "any") {
          copy["cum_type"] = [];
          copy["cum"] = [target.value];
        }
      }
      setInputs(copy);
       updateCatagory(copy);
    },
    [inputs]
  );

  const getFilter = useCallback(async () => {
    var copy = { ...inputs };
    if (minValue && maxValue) {
      copy["age"] = [minValue, maxValue];
    }
    if (minValueDonation && maxValueDonation) {
      copy["donation_hour"] = [minValueDonation, maxValueDonation];
    }
    for (let x in catagory) {
      if (catagory[x].length) {
        if (x == "age" || x == "donation_hour") {
        } else {
          copy[x] = catagory[x];
        }
      }
    }
    updateCatagory(copy);
    const response = await FilterService.get({
      populate: inputs,
      country,
      state,
      city,
      minValue,
      maxValue,
      maxValueDonation,
      minValueDonation,
    });

    if (response.status === 200) {
      setLang(response.data["data"]?.language);
      setRaces(response.data["data"]?.races);
      setCall(response.data["data"]?.call);
      setKissing(response.data["data"]?.kissing);
      setFellatio(response.data["data"]?.fellatio_type);
      setAnilingus(response.data["data"]?.anilingus_act);
      setCunnilingus(response.data["data"]?.cunnilingus);
      setVaginal(response.data["data"]?.vaginal);
      setAnal(response.data["data"]?.anal);
      setCum(response.data["data"]?.cum);
      setThreesome(response.data["data"]?.threesome);
      setMassage(response.data["data"]?.massage);
      setPersonality(response.data["data"]?.personality);
      setGrooming(response.data["data"]?.Grooming);
      // setAge(response.data["data"]?.age);
      // setDonation(response.data["data"]?.donation);
    }
  }, [inputs, maxValue, maxValueDonation, minValue, minValueDonation, country,state,city]);

  useEffect(() => {
    getFilter();
  }, [getFilter]);

  // async function getLang() {
  //   // const response = await LangService.get({ populate: "*" });
  //   const response = await FilterService.get({ populate: "*" });

  //   if (response.status === 200) {
  //     setLang(response.data["data"]?.language);
  //     setRaces(response.data["data"]?.races);
  //     setCall(response.data["data"]?.call);
  //     setKissing(response.data["data"]?.kissing);
  //     setFellatio(response.data["data"]?.fellatio_type);
  //     setAnilingus(response.data["data"]?.anilingus_act);
  //     setCunnilingus(response.data["data"]?.cunnilingus);
  //     setVaginal(response.data["data"]?.vaginal);
  //     setAnal(response.data["data"]?.anal);
  //     setCum(response.data["data"]?.cum);
  //     setThreesome(response.data["data"]?.threesome);
  //     setMassage(response.data["data"]?.massage);
  //     setPersonality(response.data["data"]?.personality);
  //     setGrooming(response.data["data"]?.Grooming);
  //     // setAge(response.data["data"]?.age);
  //     // setDonation(response.data["data"]?.donation);
  //   }
  // }

  // async function getRaces() {
  //   const response = await RaceService.get({ populate: "*" });
  //   if (response.status === 200) {
  //     setraces(response.data["data"]);
  //   }
  // }

  useEffect(() => {
    try {
      // getRaces();
      // getLang();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <div className="btn btn-warning w-100 mb-3" onClick={handleClick}>
        Categories
      </div>

      <div className={showPanel}>
        <LanguageFilter lang={lang} handleChange={handleChange} />
        <RacesFilter races={races} handleChange={handleChange} />
        <CallFilter handleChange={handleChange} call={call} />
        <AgeFilter
          setMinValueAge={setMinValueAge}
          setMaxValueAge={setMaxValueAge}
        />
        <DonationFilter
          setMaxValueDonation={setMaxValueDonation}
          setMinValueDonation={setMinValueDonation}
        />
        <KissingFilter handleChange={handleChange} kissing={kissing} />
        <FellatioFilter handleChange={handleChange} fellatio={fellatio} />
        <CunnilingusFilter
          handleChange={handleChange}
          cunnilingus={cunnilingus}
        />
        <AnilingusFilter handleChange={handleChange} anilingus={anilingus} />
        <VaginalFilter handleChange={handleChange} vaginal={vaginal} />
        <AnalFilter handleChange={handleChange} anal={anal} />
        <CumFilter handleChange={handleChange} cum={cum} />
        <ThreesomeFIlter handleChange={handleChange} threesome={threesome} />
        <MassageFilter handleChange={handleChange} massage={massage} />
        <PersonalityFilter
          handleChange={handleChange}
          personality={personality}
        />
        <GroomingFilter handleChange={handleChange} grooming={grooming} />
        {/* <OtherServicesFilter handleChange={handleChange} /> */}

        {/* <div className="category-list mb-3">
          <div
            className="expand"
            onClick={() => setOpen20(!open20)}
            aria-controls="category20"
            aria-expanded={open20}
          >
            <div className="right-arrow">
              <i className="fa fa-angle-down"></i>
            </div>
            <div>
              <h3 className="hd3">Search</h3>
            </div>
          </div>
          <Collapse className="category-body" id="category20" in={open20}>
            <Form>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Key word"
                  name="title"
                  onChange={handleChange}
                />
  </Form.Group>*/}
        {/* <a href="#" className="btn btn-outline-warning">Search</a> */}
        {/* </Form>
          </Collapse>
        </div> */}
      </div>
    </>
  );
}

export default Sidebar;
