import React from "react";
import { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import Slider from "react-slick";

import { UserContext } from "../../../context/UserContext";
import HttpHelper from "../../../helper/HttpHelper";
import ProviderService from "../../../services/ProviderService";
import UploadService from "../../../services/UploadService";
import RaceService from "../../../services/RaceService";
import LangService from "../../../services/LangService";
import StatesService from "../../../services/StatesService";
import CityService from "../../../services/CityService";

import { DobToAge, showLang, showRace } from "../../../helper/UtilityHelper";

import { Toast } from "../../layout/Alert";
import Loader from "../../layout/Loader";

const slider1 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export default function AdsEdit() {
  let { id } = useParams();
  if (isNaN(id)) {
    id = null;
  }
  const url = HttpHelper.BASE_URL;
  const { userId } = useContext(UserContext);

  // Image Form
  const [selectedImage, setselectedImage] = useState(true);
  const [isImgLoading, setImgIsLoading] = useState(false);
  const onFileChangeImages = (event) => {
    setselectedImage(false);
    let input = event.target;
    if (input.files && input.files[0]) {
      Array.from(input.files).map((data) => {
        var reader = new FileReader();
        reader.onload = function (e) {
          //setImgPath([reader.result]);
          setImgPath((d) => [...d, reader.result]);
        };
        reader.readAsDataURL(data);
      });
    }
  };

  async function handleUpload(e) {
    e.preventDefault();
    try {
      setselectedImage(true);
      setImgIsLoading(true);
      let response = await UploadService.upload(new FormData(e.target));
      if (response.status === 200) {
        let newImageId = [];
        let oldImageId = imageId;
        if (response.data.length > 0) {
          response.data?.map(async (res) => {
            console.log(res);
            newImageId = [...newImageId, res.id];
          });
          response = await ProviderService.update(id, {
            data: { images: newImageId },
          });
          setImgIsLoading(false);
          if (response.status === 200) {
            setImageId([newImageId]);
          }

          if (oldImageId.length > 0) {
            // console.log("sudhir bhai console===>", oldImageId);
            oldImageId.map(async (old) => {
              await UploadService.delete(old);
            });
          }
        }
      } else {
        setImgIsLoading(false);
        Toast.fire({ icon: "error", title: "Please try again later" });
      }
    } catch (err) {
      setselectedImage(true);
      setImgIsLoading(false);
      let message = err.message;
      // if (err.code === "ERR_BAD_REQUEST") {
      //     message = err.response.data.error;
      // }
      Toast.fire({ icon: "error", title: message });
    }
  }

  const [isVLoading, setVIsLoading] = useState(false);
  const [videoId, setVideoId] = useState(0);
  const [videoSrc, seVideoSrc] = useState("");
  const [selectedVideo, setselectedVideo] = useState(false);
  const onFileChangeVideo = (event) => {
    setselectedVideo(event.target.files[0]);
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    seVideoSrc(url);
  };
  async function handleUploadVide(e) {
    e.preventDefault();
    try {
      setVIsLoading(true);
      let response = await UploadService.upload(new FormData(e.target));
      if (response.status === 200) {
        let oldImageId = videoId;
        let newVObj = response.data[0];
        console.log(newVObj);
        response = await ProviderService.update(id, {
          data: { video: newVObj.id },
        });
        setVIsLoading(false);
        if (response.status === 200 && oldImageId) {
          setVideoId((v) => newVObj.id);
          await UploadService.delete(oldImageId);
        }
      } else {
        setVIsLoading(false);
        Toast.fire({ icon: "error", title: "Please try again later" });
      }
    } catch (err) {
      setVIsLoading(false);
      let message = err.message;
      Toast.fire({ icon: "error", title: message });
    }
  }

  // handle Form Submit
  const [inputs, setInputs] = useState({
    name: "",
    language: 1,
    country: "",
    race: 1,
    state: "",
    age: "",
    city: "",
    call: "Incall",
    phone: "",
    title: "",
    massage: "no",
    threesome: "no",
    donation_hour: 0,
    donation_half_hour: 0,
    kissing: "no",
    kissing_types: [],
    fellatio: "no",
    fellatio_type: [],
    cunnilingus: "no",
    anilingus: "no",
    anilingus_act: [],
    vaginal: "no",
    vaginal_type: [],
    anal: "no",
    anal_activity: [],
    cum: "no",
    cum_type: [],
    personality: [],
    grooming: [],
    services_provided_approval: false,
    message: "",
    duration_ad: 0,
    duration_ad_unit: "Days",
    ad_bonus_side_panel_ad: false,
    discount_code: "",
  });
  function handleChange(event) {
    // console.log(event);
    const target = event.target;
    const name = target.name;

    if (target.type === "checkbox") {
      let copy = { ...inputs };
      if (
        name === "ad_bonus_side_panel_ad" ||
        name === "services_provided_approval"
      ) {
        copy[name] = target.checked ? true : false;
      } else {
        if (target.checked) {
          copy[name].push(target.value);
        } else {
          copy[name] = copy[name].filter((el) => el !== target.value);
        }
      }
      setInputs(copy);
    } else if (target.type === "radio") {
      let value = target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    } else {
      setInputs((values) => ({ ...values, [name]: target.value }));
    }
  }
  const [isLoading, setIsLoading] = useState(false);
  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setIsLoading(true);
      let data = inputs;
      data["anal_activity"] = data["anal_activity"].toString();
      data["anilingus_act"] = data["anilingus_act"].toString();
      data["cum_type"] = data["cum_type"].toString();
      data["fellatio_type"] = data["fellatio_type"].toString();
      data["grooming"] = data["grooming"].toString();
      data["kissing_types"] = data["kissing_types"].toString();
      data["personality"] = data["personality"].toString();
      data["vaginal_type"] = data["vaginal_type"].toString();
      data["user"] = userId;
      await ProviderService.update(id, { data: data })
        .then(function (response) {
          Toast.fire({
            icon: "success",
            title: "Your Ad are successfully updated",
          });
        })
        .catch(function (error) {
          Toast.fire({
            icon: "warning",
            title: "Something went wrong. try again later",
          });
        })
        .finally(function () {
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      let message = error.message;
      if (error.code === "ERR_BAD_REQUEST") {
        message = error.response.data.error.message;
      }
      Toast.fire({ icon: "error", title: message });
    }
  }

  // Data Populate
  const [races, setraces] = useState([]);
  const [lang, setlang] = useState([]);
  const [imageId, setImageId] = useState([]);
  const [imgPath, setImgPath] = useState([
    "/images/no-photo-small.svg",
    "/images/no-photo-small.svg",
    "/images/no-photo-small.svg",
  ]);
  const [loaderVisible, setLoaderVisible] = useState(true);
  async function getData() {
    try {
      let response = await RaceService.get({ populate: "*" });
      if (response.status === 200) {
        setraces(response.data.data);
      }
      response = await LangService.get({ populate: "*" });
      if (response.status === 200) {
        setlang(response.data.data);
      }
      response = await ProviderService.getOneProvider(id, { populate: "*" });
      if (response.status === 200) {
        response = response.data.data.attributes;
        console.log("edit datas call hocche===>", response);
        setInputs({
          name: response.name,
          language: response.language.data.id,
          country: response.country,
          race: response.race.data.id,
          state: response.state,
          age: response.age,
          city: response.city,
          call: response.call,
          phone: response.phone,
          title: response.title,
          massage: response.massage,
          threesome: response.threesome,
          donation_hour: response.donation_hour,
          donation_half_hour: response.donation_half_hour,
          kissing: response.kissing,
          kissing_types: response.kissing_types.split(","),
          fellatio: response.fellatio,
          fellatio_type: response.fellatio_type.split(","),
          cunnilingus: response.cunnilingus,
          anilingus: response.anilingus,
          anilingus_act: response.anilingus_act.split(","),
          vaginal: response.vaginal,
          vaginal_type: response.vaginal_type.split(","),
          anal: response.anal,
          anal_activity: response.anal_activity.split(","),
          cum: response.cum,
          cum_type: response.cum_type.split(","),
          personality: response.personality.split(","),
          grooming: response.grooming.split(","),
          services_provided_approval: response.services_provided_approval,
          message: response.message,
          duration_ad: response.duration_ad,
          duration_ad_unit: response.duration_ad_unit,
          ad_bonus_side_panel_ad: response.ad_bonus_side_panel_ad,
          discount_code: response.discount_code,
        });
        if (response.images && response.images.data) {
          let arr = await response.images.data.map((row, i) => {
            setImageId((data) => [...data, row.id]);
            if (row.attributes.formats) {
              if (row.attributes.formats.medium) {
                return url + row.attributes.formats?.medium?.url;
              }
              if (row.attributes.formats.small) {
                return url + row.attributes.formats?.small?.url;
              }
            }
            return url + row.attributes.url;
          });
          setImgPath(arr);
          console.log(imageId);
        }
        if (response.video && response.video.data) {
          setVideoId((data) => response.video.data.id);
          seVideoSrc((data) => url + response.video.data.attributes.url);
        }
      }
      // getState();
      setLoaderVisible(false);
    } catch (error) {
      setLoaderVisible(false);
      let message = error.message;
      if (error.code === "ERR_BAD_REQUEST") {
        message = error.response.data.error;
      }
      Toast.fire({ icon: "error", title: message });
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [country] = useState("US");
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const getState = useCallback(async () => {
    try {
      const response = await StatesService.getAll({
        filters: {
          country_code: {
            $eq: country,
          },
        },
        fields: ["state_name", "state_code"],
        pagination: { pageSize: 100 },
        sort: ["state_name:asc"],
      });
      if (response.status === 200) {
        setStateList(response.data.data);
      } else {
        setStateList([]);
      }
    } catch (err) {
      console.log(err);
    }
  }, [country]);

  const getCity = useCallback(async () => {
    try {
      const response = await CityService.getAll({
        filters: {
          state_code: {
            $eq: inputs.state,
          },
        },
        fields: ["city_name", "state_code", "state_name"],
        pagination: { pageSize: 1500 },
        sort: ["city_name:asc"],
      });
      if (response.status === 200) {
        setCityList(response.data.data);
      } else {
        setCityList([]);
      }
    } catch (err) {
      console.log(err);
    }
  }, [inputs.state]);

  useEffect(() => {
    if (inputs.country) {
      getState();
    }
  }, [getState, inputs.country]);

  useEffect(() => {
    if (inputs.state) {
      getCity();
    }
  }, [getCity, inputs.state]);

  return (
    <Loader visible={loaderVisible}>
      <Row>
        <Col md={12}>
          <div className="mid-bx mb-4">
            <div className="d-flex justify-content-between">
              <div className="lft-img-prt">
                <Slider {...slider1}>
                  {imgPath.map((row, i) => {
                    return (
                      <div key={i}>
                        <img
                          className="img-fluid"
                          src={row}
                          alt={row}
                          key={i}
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div>

              <div className="rt-content-prt">
                <div className="hdr-prt">
                  <h3>{inputs.title}</h3>
                </div>

                <div className="p-2">
                  <Row>
                    <Col md={6}>
                      <ul className="info-lst">
                        <li>
                          <span className="name">Name:</span>
                          <span className="info">{inputs.name}</span>
                        </li>
                        <li>
                          <span className="name">Location:</span>
                          <span className="info">
                            {[inputs.country, inputs.state, inputs.city].join(
                              ","
                            )}
                          </span>
                        </li>
                        <li>
                          <span className="name">Language:</span>
                          <span className="info">
                            {showLang(inputs.language, lang)}
                          </span>
                        </li>

                        <li>
                          <span className="name">Race:</span>
                          <span className="info">
                            {showRace(inputs.race, races)}
                          </span>
                        </li>
                        <li>
                          <span className="name">Massage:</span>
                          <span className="info">{inputs.massage}</span>
                        </li>

                        <li>
                          <span className="name">Threesome:</span>
                          <span className="info">{inputs.threesome}</span>
                        </li>
                        <li>
                          <span className="name">Kissing:</span>
                          <span className="info">{inputs.kissing}</span>
                        </li>

                        <li>
                          <span className="name">Fellatio:</span>
                          <span className="info">{inputs.fellatio}</span>
                        </li>
                        <li>
                          <span className="name">Cunnilingus:</span>
                          <span className="info">{inputs.cunnilingus}</span>
                        </li>
                      </ul>
                    </Col>
                    <Col md={6}>
                      <ul className="info-lst">
                        <li>
                          <span className="name">Anilingus:</span>
                          <span className="info">{inputs.anilingus}</span>
                        </li>
                        <li>
                          <span className="name">Vaginal:</span>
                          <span className="info">{inputs.vaginal}</span>
                        </li>
                        <li>
                          <span className="name">Anal:</span>
                          <span className="info">{inputs.anal}</span>
                        </li>
                        <li>
                          <span className="name">Cum:</span>
                          <span className="info">{inputs.cum}</span>
                        </li>

                        <li>
                          <span className="name">Personality:</span>
                          <span className="info">{inputs.personality}</span>
                        </li>
                        <li>
                          <span className="name">Grooming:</span>
                          <span className="info">{inputs.grooming}</span>
                        </li>

                        <li>
                          <span className="name">Other:</span>
                          <span className="info">Yes</span>
                        </li>
                        <li>
                          <span className="name">Phone</span>
                          <span className="info">{inputs.phone}</span>
                        </li>
                      </ul>
                      <h4 className="pricing">
                        <em>$</em> {inputs.donation_half_hour}/h,{" "}
                        {inputs.donation_hour}/H <span>{inputs.call}</span>
                      </h4>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={12}>
          <div className="provider-form mb-4">
            <Form onSubmit={handleUpload}>
              <Form.Group controlId="formFile" className="col-md-12">
                <Form.Label>Change Image</Form.Label>
                <Form.Control
                  type="file"
                  name="files"
                  accept="image/*"
                  onChange={onFileChangeImages}
                  multiple
                />
              </Form.Group>

              <Form.Group className="col-md-12">
                <Button
                  className="btn btn-subscribe btn-warning"
                  disabled={selectedImage}
                  type="submit"
                >
                  {isImgLoading ? (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Upload"
                  )}
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Col>

        <Col md={12}>
          <div className="provider-form mb-4">
            <Row>
              <Col md={6}>
                {videoSrc ? (
                  <video width="100%" controls playsInline src={videoSrc} />
                ) : null}
              </Col>
              <Col md={6}>
                <Form onSubmit={handleUploadVide}>
                  <Form.Group controlId="formFile" className="col-md-12">
                    <Form.Label>Upload Video (30 Sec.)</Form.Label>
                    <Form.Control
                      type="file"
                      name="files"
                      accept="video/*"
                      onChange={onFileChangeVideo}
                    />
                  </Form.Group>
                  <Form.Group className="col-md-12">
                    <Button
                      className="btn btn-subscribe btn-warning"
                      disabled={!!!selectedVideo}
                      type="submit"
                    >
                      {isVLoading ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Upload"
                      )}
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
        </Col>

        <Col md={12}>
          <div className="provider-form">
            <Form className="row" onSubmit={handleSubmit}>
              <Form.Group className="col-md-6 mb-3">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleChange}
                  value={inputs.name}
                />
              </Form.Group>

              <Form.Group className="col-md-6 mb-3">
                <Form.Label>Language</Form.Label>
                {lang.length && (
                  <Form.Select
                    aria-label="Default select example"
                    name="language"
                    onChange={handleChange}
                    value={inputs.language}
                  >
                    {lang.map((row) => (
                      <option key={row.id} value={row.id}>
                        {row.attributes.lang_name}
                      </option>
                    ))}
                  </Form.Select>
                )}
                {!lang.length && (
                  <Form.Select aria-label="Default select example">
                    <option value=""></option>
                  </Form.Select>
                )}
              </Form.Group>

              <Form.Group className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  {/* <Form.Control
                                        type="text"
                                        name="country"
                                        onChange={handleChange}
                                        value={inputs.country}
                                    /> */}
                  <Form.Select
                    aria-label="Default select example"
                    name="country"
                    onChange={handleChange}
                    value={inputs.country}
                  >
                    <option value=""></option>
                    <option value={country}>United States</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Region/State</Form.Label>
                  {/* <Form.Control
                                        type="text"
                                        name="state"
                                        onChange={handleChange}
                                        value={inputs.state}
                                    /> */}
                  {stateList.length ? (
                    <Form.Select
                      aria-label="Default select example"
                      name="state"
                      onChange={handleChange}
                      value={inputs.state}
                    >
                      {stateList.map((row, i) => {
                        return (
                          <option key={i} value={row.attributes.state_code}>
                            {row.attributes.state_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  ) : (
                    <Form.Select aria-label="Default select example" disabled>
                      <option value=""></option>
                    </Form.Select>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  {/* <Form.Control
                                        type="text"
                                        name="city"
                                        onChange={handleChange}
                                        value={inputs.city}
                                    /> */}
                  {cityList.length ? (
                    <Form.Select
                      aria-label="Default select example"
                      name="city"
                      onChange={handleChange}
                      value={inputs.city}
                    >
                      {cityList.map((row, i) => {
                        return (
                          <option key={i} value={row.attributes.city_name}>
                            {row.attributes.city_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  ) : (
                    <Form.Select aria-label="Default select example" disabled>
                      <option value=""></option>
                    </Form.Select>
                  )}
                </Form.Group>
              </Form.Group>

              <Form.Group className="col-md-6">
                {races.length && (
                  <Form.Group className="mb-3">
                    <Form.Label>Race</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="race"
                      onChange={handleChange}
                    >
                      {races.map((row) => (
                        <option key={row.id} value={row.id}>
                          {row.attributes.race_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}
                {!races.length && (
                  <Form.Group className="mb-3">
                    <Form.Label>Race</Form.Label>

                    <Form.Select aria-label="Default select example">
                      <option value=""></option>
                    </Form.Select>
                  </Form.Group>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>Age:</Form.Label>
                  <Form.Control
                    type="number"
                    name="age"
                    onChange={handleChange}
                    value={inputs.age}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Call:</Form.Label>
                  <div className="mb-3">
                    <Form.Select
                      name="call"
                      aria-label="Default select example"
                      onChange={handleChange}
                      value={inputs.call}
                    >
                      <option value="Incall">Incall</option>
                      <option value="Outcall">Outcall</option>
                      <option value="Outcall/Incall">Both</option>
                    </Form.Select>
                  </div>
                </Form.Group>
              </Form.Group>

              <Form.Group className="col-md-6 mb-3">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  onChange={handleChange}
                  value={inputs.phone}
                />
              </Form.Group>
              <hr />

              <Form.Group className="col-md-12 mb-3">
                <Form.Label>Title:</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  onChange={handleChange}
                  value={inputs.title}
                />
              </Form.Group>

              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Donation:</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="$"
                    type="number"
                    value={inputs.donation_half_hour}
                    aria-describedby="basic-addon2"
                    name="donation_half_hour"
                    onChange={handleChange}
                  />
                  <InputGroup.Text id="basic-addon2">
                    / Half Hour
                  </InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="$"
                    type="number"
                    value={inputs.donation_hour}
                    aria-describedby="basic-addon3"
                    name="donation_hour"
                    onChange={handleChange}
                  />
                  <InputGroup.Text id="basic-addon3">/ Hour</InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Massage:</Form.Label>
                <div className="mb-3">
                  <Form.Check
                    type="radio"
                    label="Yes"
                    value="yes"
                    name="massage"
                    id="massage1"
                    onChange={handleChange}
                    checked={inputs.massage === "yes"}
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    value="no"
                    name="massage"
                    id="massage2"
                    onChange={handleChange}
                    checked={inputs.massage === "no"}
                  />
                </div>
              </Form.Group>

              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Threesome:</Form.Label>
                <div className="mb-3">
                  <Form.Check
                    type="radio"
                    label="Yes"
                    value="yes"
                    onChange={handleChange}
                    name="threesome"
                    id="threesome1"
                    checked={inputs.threesome === "yes"}
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    value="no"
                    onChange={handleChange}
                    name="threesome"
                    id="threesome2"
                    checked={inputs.threesome === "no"}
                  />
                </div>
              </Form.Group>
              <hr />

              <Form.Group className="col-md-6 mb-3 d-flex">
                <Form.Label className="me-4">Kissing:</Form.Label>
                <div className="category-body">
                  <ul>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          className="mt-3"
                          type="radio"
                          label="Yes"
                          name="kissing"
                          id="kissing1"
                          value="yes"
                          onChange={handleChange}
                          checked={inputs.kissing === "yes"}
                        />
                      </Form.Check>

                      <div className="ps-3">
                        <ul>
                          {["Light", "Moderate", "Heavy"].map((type) => (
                            <li key={`kissing_types-${type}`}>
                              <Form.Check className="cat-checkbox">
                                <Form.Check
                                  type="checkbox"
                                  name="kissing_types"
                                  id={`kissing_types${type}`}
                                  value={type}
                                  label={`${type}`}
                                  onChange={handleChange}
                                  checked={
                                    inputs.kissing_types.indexOf(type) >= 0
                                  }
                                />
                              </Form.Check>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          className="mt-3"
                          type="radio"
                          label="No"
                          name="kissing"
                          id="kissing2"
                          value="no"
                          onChange={handleChange}
                          checked={inputs.kissing === "no"}
                        />
                      </Form.Check>
                    </li>
                  </ul>
                </div>
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 d-flex">
                <Form.Label className="me-4">Fellatio:</Form.Label>
                <div className="category-body">
                  <ul>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          className="mt-3"
                          type="radio"
                          label="Yes"
                          name="fellatio"
                          id="fellatio1"
                          value="yes"
                          onChange={handleChange}
                          checked={inputs.fellatio === "yes"}
                        />
                      </Form.Check>

                      <div className="ps-3">
                        <ul>
                          {Object.entries({
                            CBJ: "CBJ: Covered Blow Job",
                            BBJ: "BBJ: Bare Blow Job",
                          }).map(([key, type], i) => (
                            <li key={`default-${key}`}>
                              <Form.Check className="cat-checkbox">
                                <Form.Check
                                  type="checkbox"
                                  name="fellatio_type"
                                  id={`fellatio_type${key}`}
                                  value={key}
                                  label={`${type}`}
                                  onChange={handleChange}
                                  checked={
                                    inputs.fellatio_type.indexOf(key) >= 0
                                  }
                                />
                              </Form.Check>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          className="mt-3"
                          type="radio"
                          label="No"
                          name="fellatio"
                          id="fellatio2"
                          value="no"
                          onChange={handleChange}
                          checked={inputs.fellatio === "no"}
                        />
                      </Form.Check>
                    </li>
                  </ul>
                </div>
              </Form.Group>
              <hr />

              <Form.Group className="col-md-6 mb-3 d-flex">
                <Form.Label>Cunnilingus:</Form.Label>
                <div className="mb-3">
                  <Form.Check
                    label="Yes"
                    onChange={handleChange}
                    checked={inputs.fellatio === "yes"}
                    name="cunnilingus"
                    value="yes"
                    id="cunnilingus1"
                    type="radio"
                  />
                  <Form.Check
                    label="No"
                    onChange={handleChange}
                    checked={inputs.fellatio === "no"}
                    name="cunnilingus"
                    value="no"
                    id="cunnilingus2"
                    type="radio"
                  />
                </div>
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 d-flex">
                <Form.Label className="me-4">Anilingus:</Form.Label>
                <div className="category-body">
                  <ul>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          type="radio"
                          label="Yes"
                          name="anilingus"
                          id="anilingus1"
                          value="yes"
                          onChange={handleChange}
                          checked={inputs.anilingus === "yes"}
                        />
                      </Form.Check>

                      <div className="ps-3">
                        <ul>
                          {["Give", "Receive", "Both"].map((type) => (
                            <li key={`default-${type}`}>
                              <Form.Check className="cat-checkbox">
                                <Form.Check
                                  type="checkbox"
                                  name="anilingus_act"
                                  id={`anilingus_act_${type}`}
                                  value={type}
                                  label={`${type}`}
                                  onChange={handleChange}
                                  checked={
                                    inputs.anilingus_act.indexOf(type) >= 0
                                  }
                                />
                              </Form.Check>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          type="radio"
                          label="No"
                          name="anilingus"
                          id="anilingus2"
                          value="no"
                          onChange={handleChange}
                          checked={inputs.anilingus === "no"}
                        />
                      </Form.Check>
                    </li>
                  </ul>
                </div>
              </Form.Group>
              <hr />

              <Form.Group className="col-md-6 mb-3 d-flex">
                <Form.Label className="me-4">Vaginal:</Form.Label>
                <div className="category-body">
                  <ul>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          type="radio"
                          label="Yes"
                          id="vaginal1"
                          name="vaginal"
                          value="yes"
                          onChange={handleChange}
                          checked={inputs.vaginal === "yes"}
                        />
                      </Form.Check>

                      <div className="ps-3">
                        <ul>
                          {Object.entries({
                            CFS: "CFS: Covered Full Sex",
                            BFS: "BFS: Bare Full Sex",
                          }).map(([key, type], i) => (
                            <li key={`default-${key}`}>
                              <Form.Check className="cat-checkbox">
                                <Form.Check
                                  type="checkbox"
                                  name="vaginal_type"
                                  id={`vaginal_type${key}`}
                                  value={key}
                                  label={`${type}`}
                                  onChange={handleChange}
                                  checked={
                                    inputs.vaginal_type.indexOf(key) >= 0
                                  }
                                />
                              </Form.Check>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          type="radio"
                          label="No"
                          id="vaginal2"
                          name="vaginal"
                          value="no"
                          onChange={handleChange}
                          checked={inputs.vaginal === "no"}
                        />
                      </Form.Check>
                    </li>
                  </ul>
                </div>
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 d-flex">
                <Form.Label className="me-4">Anal:</Form.Label>
                <div className="category-body">
                  <ul>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          type="radio"
                          label="Yes"
                          name="anal"
                          id="anal1"
                          value="yes"
                          onChange={handleChange}
                          checked={inputs.anal === "yes"}
                        />
                      </Form.Check>

                      <div className="ps-3">
                        <ul>
                          {Object.entries({
                            "CAS:": "CAS: Covered Anal Sex",
                            BAS: "BAS: Bare Anal Sex",
                          }).map(([key, type], i) => (
                            <li key={`default-${key}`}>
                              <Form.Check className="cat-checkbox">
                                <Form.Check
                                  type="checkbox"
                                  name="anal_activity"
                                  id={`anal_activity${key}`}
                                  value={key}
                                  label={`${type}`}
                                  onChange={handleChange}
                                  checked={
                                    inputs.anal_activity.indexOf(key) >= 0
                                  }
                                />
                              </Form.Check>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          type="radio"
                          label="No"
                          name="anal"
                          id="anal2"
                          value="no"
                          onChange={handleChange}
                          checked={inputs.anal === "no"}
                        />
                      </Form.Check>
                    </li>
                  </ul>
                </div>
              </Form.Group>
              <hr />

              <Form.Group className="col-md-12 mb-3 d-flex">
                <Form.Label className="me-4">Cum:</Form.Label>
                <div className="category-body">
                  <ul className="row">
                    <div className="col-md-6">
                      <li>
                        <Form.Check className="cat-checkbox">
                          <Form.Check
                            type="radio"
                            label="CIM: Cum In Mouth"
                            id="cum0"
                            name="cum"
                            value="CIM"
                            onChange={handleChange}
                          />
                        </Form.Check>
                        <div className="ps-3">
                          <ul>
                            {["Swallow", "Drool", "Spit"].map((type) => (
                              <li key={`default-${type}`}>
                                <Form.Check className="cat-checkbox">
                                  <Form.Check
                                    type="checkbox"
                                    name="cum_type"
                                    id={`cum_type_${type}`}
                                    value={type}
                                    label={`${type} (CIM)`}
                                    onChange={handleChange}
                                    checked={inputs.cum_type.indexOf(type) >= 0}
                                  />
                                </Form.Check>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    </div>

                    <div className="col-md-6">
                      {Object.entries({
                        CIP: "CIP: Cum In Pussy",
                        COF: "COF: Cum On Face",
                        COT: "COT: Cum On Tits",
                        COB: "COB: Cum On Body",
                        COA: "COA: Cum On Ass",
                        CIA: "CIA: Cum In Ass",
                      }).map(([key, type], i) => (
                        <li key={`default-${key}`}>
                          <Form.Check className="cat-checkbox">
                            <Form.Check
                              type="checkbox"
                              name="cum_type"
                              id={`cum_type_${key}`}
                              value={key}
                              label={type}
                              onChange={handleChange}
                              checked={inputs.cum_type.indexOf(key) >= 0}
                            />
                          </Form.Check>
                        </li>
                      ))}
                    </div>
                    <hr />

                    <li className="col-md-6 ps-3">
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          inline
                          name="cum"
                          id="cum1"
                          type="radio"
                          aria-label="cum"
                          value="Anywhere"
                          label="Anywhere"
                          onChange={handleChange}
                          checked={inputs.cum === "Anywhere"}
                        />
                      </Form.Check>
                    </li>
                    <li className="col-md-6 ps-x">
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          inline
                          name="cum"
                          id="cum2"
                          type="radio"
                          aria-label="na"
                          value="N/A"
                          label="N/A"
                          onChange={handleChange}
                          checked={inputs.cum === "N/A"}
                        />
                      </Form.Check>
                    </li>
                  </ul>
                </div>
              </Form.Group>
              <hr />

              <Form.Group className="col-md-12 mb-3 d-flex">
                <Form.Label>Personality:</Form.Label>
                <div className="col-sm-8">
                  <Form.Check
                    inline
                    name="personality"
                    id="personality1"
                    className="mt-2"
                    label="Submissive"
                    value="Submissive"
                    type="checkbox"
                    onChange={handleChange}
                    checked={inputs.personality.indexOf("Submissive") >= 0}
                  />
                  <Form.Check
                    inline
                    name="personality"
                    id="personality2"
                    className="mt-2"
                    label="Confident"
                    value="Confident"
                    type="checkbox"
                    onChange={handleChange}
                    checked={inputs.personality.indexOf("Confident") >= 0}
                  />
                  <Form.Check
                    inline
                    name="personality"
                    id="personality3"
                    className="mt-2"
                    label="Dominant"
                    value="Dominant"
                    type="checkbox"
                    onChange={handleChange}
                    checked={inputs.personality.indexOf("Dominant") >= 0}
                  />
                </div>
              </Form.Group>

              <Form.Group className="col-md-12 mb-3 d-flex">
                <Form.Label>Grooming:</Form.Label>
                <div className="col-sm-8">
                  <Form.Check
                    inline
                    name="grooming"
                    id="grooming1"
                    className="mt-2"
                    label="Natural"
                    value="Natural"
                    type="checkbox"
                    onChange={handleChange}
                    checked={inputs.grooming.indexOf("Natural") >= 0}
                  />
                  <Form.Check
                    inline
                    name="grooming"
                    id="grooming2"
                    className="mt-2"
                    label="Trimmed"
                    value="Trimmed"
                    type="checkbox"
                    onChange={handleChange}
                    checked={inputs.grooming.indexOf("Trimmed") >= 0}
                  />
                  <Form.Check
                    inline
                    name="grooming"
                    id="grooming3"
                    className="mt-2"
                    label="Bare"
                    value="Bare"
                    type="checkbox"
                    onChange={handleChange}
                    checked={inputs.grooming.indexOf("Bare") >= 0}
                  />
                </div>
              </Form.Group>
              <hr />

              <Form.Group className="col-md-12 mb-3">
                <Form.Check
                  inline
                  name="services_provided_approval"
                  label="Other Services Provided Upon Request/Approval"
                  type="checkbox"
                  onChange={handleChange}
                  checked={inputs.services_provided_approval}
                />
                {inputs.services_provided_approval && (
                  <Form.Group className="col-md-12 mt-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="message"
                      type="text"
                      value={inputs.message}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
              </Form.Group>
              {/* <hr />

              <Form.Group className="col-md-12 mb-3 d-flex align-items-center justify-content-between">
                <Form.Label className="col-4 col-sm-3">
                  Duration of Ad:
                </Form.Label>
                <Form.Control
                  name="duration_ad"
                  type="number"
                  className="mx-4"
                  onChange={handleChange}
                  value={inputs.duration_ad}
                />
                <Form.Select
                  name="duration_ad_unit"
                  aria-label="Default select example"
                  onChange={handleChange}
                  value={inputs.duration_ad_unit}
                >
                  <option value="Days">Days</option>
                  <option value="Weeks">Weeks</option>
                  <option value="Months">Months</option>
                  <option value="Years">Years</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 d-flex">
                <Form.Check
                  inline
                  name="ad_bonus_side_panel_ad"
                  id="ad_bonus_side_panel_ad"
                  label="Ad bonus side panel mini-ad"
                  type="checkbox"
                  onChange={handleChange}
                  checked={inputs.ad_bonus_side_panel_ad}
                />
              </Form.Group> */}
              <hr />

              <Form.Group className="col-md-12 mb-3 d-flex">
                <Button
                  size="lg"
                  type="submit"
                  disabled={isLoading}
                  className="btn btn-warning"
                >
                  {isLoading ? (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Col>
      </Row>
    </Loader>
  );
}
