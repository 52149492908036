import React, { useContext } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { UserContext } from "../../../context/UserContext";

function DropdownComponent() {
  const { user, logout, isProvider, isHobbyist, isVender } =
    useContext(UserContext);
  const navigate = useNavigate();

  const handleLogoutClick = async () => {
    navigate("/");
    await logout();
    window.location.reload()
  };

  return (
    <DropdownButton
      align="end"
      title={user?.first_name + " " + user?.last_name}
      id="dropdown-menu-align-end"
      variant="success"
    >
      <Dropdown.Item as="button" eventKey="5">
        <Link to="/profile/view">
          <i className="fas fa-user"></i> My Profile
        </Link>
      </Dropdown.Item>
      {isProvider() ? (
        <>
          <Dropdown.Item as="button" eventKey="1">
            <Link to="/ads/view">
              <i className="fas fa-picture-o"></i> My Ads
            </Link>
          </Dropdown.Item>
          <Dropdown.Item as="button" eventKey="2">
            <Link to="/place/view">
              <i className="fas fa-map-marker"></i> My Places
            </Link>
          </Dropdown.Item>
          <Dropdown.Item as="button" eventKey="2">
            <Link to="/provider/wallet">
              <i className="fas fa-wallet"></i> My Wallet
            </Link>
          </Dropdown.Item>
          <Dropdown.Item as="button" eventKey="2">
            <Link to="/provider/subscription">
              <i class="fas fa-credit-card"></i> My Subscription
            </Link>
          </Dropdown.Item>
          <Dropdown.Item as="button" eventKey="2">
            <Link to="/provider/ratehobyist">
              <i class="fas fa-comment"></i> Screen Hobbyists (Rate/Comment on
              Hobbyists)
            </Link>
          </Dropdown.Item>
          <Dropdown.Item as="button" eventKey="2">
            <a href="mailto:ceo.hotspicygirls@gmail.com">
              <span className="icon_menu">
                <i class="fas fa-at"></i>
              </span>
              Review Mail
            </a>
          </Dropdown.Item>
        </>
      ) : null}
      {isHobbyist() || isVender() ? (
        <>
          <Dropdown.Item as="button" eventKey="1">
            <Link to="/orders/view">
              <i className="fa fa-list"></i> My Orders
            </Link>
          </Dropdown.Item>
        </>
      ) : null}
      {isVender() ? (
        <>
          <Dropdown.Item as="button" eventKey="2">
            <Link to="/products/view">
              <i className="fas fa-box"></i> My Products
            </Link>
          </Dropdown.Item>
        </>
      ) : null}
      <Dropdown.Item as="button" eventKey="3">
        <Link to="/profile/changepassword">
          <i className="fas fa-key"></i> Change Password
        </Link>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        as="button"
        eventKey="4"
        onClick={() => handleLogoutClick()}
      >
        <i className="fas fa-power-off"></i> Logout
      </Dropdown.Item>
    </DropdownButton>
  );
}

export default DropdownComponent;
