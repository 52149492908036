import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./privacy.css";


function Privacypolicy() {
    return (
        <>
            <div className="main-wrap">
                <Container>
                    <h2 className="main-title">Hot Spicy Girls Privacy Policy</h2>

                    <Row className="mt-5">
                        <Col md={{ span: 10, offset: 1 }}>
                            <div className="c5 doc-content">
                                {/* <p className="c10">
                                    <span className="c12">Hot Spicy Girls Privacy Policy</span>
                                </p> */}
                                <ol className="c9 lst-kix_list_1-0 start" start="1">
                                    <li className="c4 c7 li-bullet-0">
                                        <span className="c2">General.</span>
                                    </li>
                                </ol>
                                <ol className="c9 lst-kix_list_1-1 start" start="1">
                                    <li className="c3 li-bullet-0">
                                        <span className="c1">
                                            The operative parties referred to in this policy are
                                            Company and you, the user of this site.
                                        </span>
                                    </li>
                                </ol>
                                <ol className="c9 lst-kix_list_1-2 start" start="1">
                                    <li className="c10 c7 c14 li-bullet-0">
                                        <span className="c13">HSG (</span>
                                        <span className="c11">
                                            <a
                                                className="c16"
                                                href="https://www.google.com/url?q=http://www.hotspicygirls.com&amp;sa=D&amp;source=editors&amp;ust=1681911060104654&amp;usg=AOvVaw3UqiS3EyqpthvjbPt4MQXo"
                                            >
                                                www.hotspicygirls.com
                                            </a>
                                        </span>
                                        <span className="c1">
                                            ) is the publisher(s) and/or operator of and are
                                            hereinafter referred to collectively as "Company." When
                                            first-person pronouns are used in this Policy, (us, we,
                                            our, ours, etc.) these provisions are referring
                                            respectively to&nbsp;HSG where applicable. Additionally,
                                            when the terms "The Site" or "Site" are used, these terms
                                            refer respectively to www.hotspicygirls.com when
                                            applicable.
                                        </span>
                                    </li>
                                    <li className="c10 c7 c14 li-bullet-0">
                                        <span className="c1">
                                            You, the User: As the user of this Site, this policy will
                                            refer to the user as "you" or through any second-person
                                            pronouns, such as "yours," etc. Hereinafter, the user of
                                            the Site shall be referred to in applicable second-person
                                            pronouns.
                                        </span>
                                    </li>
                                    <li className="c10 c7 c14 li-bullet-0">
                                        <span className="c1">
                                            Potential Advertisers: Some individuals access the Site
                                            for the purpose of applying to become an Advertiser on the
                                            Site. These individuals are not considered "Users" as
                                            defined hereunder.
                                        </span>
                                    </li>
                                </ol>
                                <ol className="c9 lst-kix_list_1-1" start="2">
                                    <li className="c3 li-bullet-0">
                                        <span className="c1">
                                            This Privacy Policy describes how we use information
                                            received about you when you visit our Site or when you
                                            subscribe to, or otherwise use our online services. This
                                            Policy does not cover any information that we may receive
                                            from or about you through channels other than through the
                                            use of the Site.
                                        </span>
                                    </li>
                                </ol>
                                <ol className="c9 lst-kix_list_1-0" start="2">
                                    <li className="c4 c7 li-bullet-0">
                                        <span className="c2">Changes in this Privacy Statement.</span>
                                    </li>
                                </ol>
                                <p className="c4">
                                    <span className="c1">
                                        If we decide to change our privacy policy, we will post
                                        those changes to this privacy statement, the home page, and
                                        other places we deem appropriate so that you are aware of
                                        what information we collect, how we use it, and under what
                                        circumstances, if any, we disclose it.
                                    </span>
                                </p>
                                <p className="c4">
                                    <span className="c1">
                                        We reserve the right to modify this privacy statement at any
                                        time, so please review it frequently. If we make material
                                        changes to this policy, we will notify you here, by email,
                                        or by means of a notice on our home page.
                                    </span>
                                </p>
                                <ol className="c9 lst-kix_list_1-0" start="3">
                                    <li className="c4 c7 li-bullet-0">
                                        <span className="c2">
                                            Personal Information That We Collect.
                                        </span>
                                    </li>
                                </ol>
                                <ol className="c9 lst-kix_list_1-1 start" start="1">
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">Personal information:</span>
                                        <span className="c1">
                                            &nbsp;When you sign up on our site you provide us with
                                            your username, email. Once you register you can visit your
                                            profile at any time to change personal information about
                                            yourself.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">Content:</span>
                                        <span className="c1">
                                            &nbsp;One of the primary reasons people use our site is to
                                            share content with others. Examples include when you leave
                                            a review, comment someone else's review, upload a picture,
                                            talk on the forums.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">Transactional Information:</span>
                                        <span className="c1">
                                            &nbsp;We may retain the details of transactions or
                                            payments such as credit card number, and billing address
                                            you make on us. However, we will only keep your payment
                                            source account number with your consent. We may provide
                                            your personal information to companies that provide
                                            services to help us with our business activities such as
                                            shipping your order or offering customer service. These
                                            companies are authorized to use your personal information
                                            only as necessary to provide these services to us.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">Site activity information:</span>
                                        <span className="c1">
                                            &nbsp;We keep track of the actions you take on the site,
                                            such as sending a message to someone, sending a file to
                                            someone. In some cases, you are also taking an action when
                                            you provide information or content to us. For example, if
                                            you send a message to some other members, in addition to
                                            storing the actual content you send, we might log the fact
                                            you process it.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">
                                            Access Device and Browser Information:
                                        </span>
                                        <span className="c1">
                                            &nbsp;When you access the site from a computer, mobile
                                            phone, or other device, we may collect information from
                                            that device about your browser type, location, and IP
                                            address, as well as the pages you visit.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">Cookie Information:</span>
                                        <span className="c1">
                                            &nbsp;We use "cookies" (small pieces of data we store for
                                            an extended period of time on your computer, mobile phone,
                                            or other device) to make the site easier to use, to make
                                            our advertising better, and to protect both you and the
                                            site. For example, we use them to store your login ID (but
                                            never your password) to make it easier for you to login
                                            whenever you come back to the site. We also use them to
                                            confirm that you are logged into the site, and to know
                                            when you are interacting with the site. You can remove or
                                            block cookies using the settings in your browser, but in
                                            some cases that may impact your ability to use the site.
                                            We do link the information we store in cookies to any
                                            personally identifiable information you submit while on
                                            our site.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">
                                            Customer Testimonials/Comments/Reviews:
                                        </span>
                                        <span className="c1">
                                            &nbsp;We post customer testimonials/comments/reviews on
                                            our web site which may contain personally identifiable
                                            information. We do obtain the customer's consent via email
                                            prior to posting the testimonial to post their name along
                                            with their testimonial.
                                        </span>
                                    </li>
                                </ol>
                                <ol className="c9 lst-kix_list_1-0" start="4">
                                    <li className="c4 c7 li-bullet-0">
                                        <span className="c2">How We Use Your Information.</span>
                                    </li>
                                </ol>
                                <ol className="c9 lst-kix_list_1-1 start" start="1">
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">To manage the service:</span>
                                        <span className="c1">
                                            &nbsp;We use the information we collect to provide our
                                            services and features to you, to measure and improve those
                                            services and features, and to provide you with customer
                                            support. We use the information to prevent potentially
                                            illegal activities. We also use a variety of technological
                                            systems to detect and address anomalous activity and
                                            screen content to prevent abuse such as spam. These
                                            efforts may on occasion result in a temporary or permanent
                                            suspension or termination of some functions for some
                                            users.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">To contact you:</span>
                                        <span className="c1">
                                            &nbsp;We may contact you with service-related
                                            announcements from time to time. You may opt out of all
                                            communications except essential updates on your account
                                            notifications page. We may include content you see on the
                                            site in the emails we send to you.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">
                                            To serve personalized advertising to you:
                                        </span>
                                        <span className="c1">
                                            &nbsp;We don't share your information with advertisers
                                            without your consent. (An example of consent would be if
                                            you asked us to provide your shipping address to an
                                            advertiser to receive a free sample.) We allow advertisers
                                            to choose the characteristics of users who will see their
                                            advertisements and we may use any of the non-personally
                                            identifiable attributes we have collected (including
                                            information you may have decided not to show to other
                                            users, such as your ethnicity preference or other
                                            sensitive personal information or preferences) to select
                                            the appropriate audience for those advertisements. For
                                            example, we might use your interest in sativa plant to
                                            show you ads posted by sativa stores, but we do not tell
                                            the provider who you are. Even though we do not share your
                                            information with advertisers without your consent, when
                                            you click on or otherwise interact with an advertisement
                                            there is a possibility that the advertiser may place a
                                            cookie in your browser and note that it meets the criteria
                                            they selected.
                                        </span>
                                    </li>
                                </ol>
                                <ol className="c9 lst-kix_list_1-0" start="5">
                                    <li className="c4 c7 li-bullet-0">
                                        <span className="c2">Special Cases.</span>
                                    </li>
                                </ol>
                                <p className="c4">
                                    <span className="c1">
                                        It is our policy not to use or share the passive information
                                        about Users in ways unrelated to those described in this
                                        Policy without also providing the User with an opportunity
                                        to opt out or otherwise prohibit such unrelated uses.
                                        However, we may disclose passively-collected information
                                        about Users, or information regarding your use of the
                                        Services or Web sites accessible through our Services, for
                                        any reason without notice if, in our sole discretion, we
                                        believe that it is reasonable to do so, including, but not
                                        limited to:
                                    </span>
                                </p>
                                <ol className="c9 lst-kix_list_1-1" start="4">
                                    <li className="c7 c17 li-bullet-0">
                                        <span className="c1">
                                            To satisfy any laws, such as the Electronic Communications
                                            Privacy Act, regulations, or governmental, or legal
                                            requests for such information;
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c1">
                                            To disclose information that is necessary to identify,
                                            contact, or bring legal action against someone who may be
                                            violating our Terms of Use and other policies and
                                            procedures;
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c1">To operate our Services properly;</span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c1">
                                            To protect ourselves, our Users, and the general public.
                                            We specifically reserve the right to disclose any and all
                                            information to law enforcement in the event that a crime
                                            including, but not limited to, fraud-related offenses is
                                            committed, is suspected, or if we are compelled to do so
                                            by lawful criminal, civil, or administrative process,
                                            discovery requests, subpoenas, court orders, writs, or
                                            reasonable request of authorities or persons with the
                                            reasonable power to obtain such process;
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c1">
                                            To cooperate with law enforcement authorities, and other
                                            governmental, administrative or regulatory agencies about
                                            our end users to the extent required by applicable law.
                                            Examples of such cooperation include lawful criminal,
                                            civil, or administrative process, discovery requests,
                                            subpoenas, court orders, or writs. We do not intend to
                                            cooperate with private-party litigants and others seeking
                                            information unless compelled to do so through lawful court
                                            or administrative process, such as subpoenas, court
                                            orders, or writs.
                                        </span>
                                    </li>
                                </ol>
                                <ol className="c9 lst-kix_list_1-0" start="6">
                                    <li className="c4 c7 li-bullet-0">
                                        <span className="c2">Transmittals from Us.</span>
                                    </li>
                                </ol>
                                <p className="c4">
                                    <span className="c1">
                                        The Company does not presently intend to send promotional
                                        e-mails to Users, as there is no active collection of a
                                        User's email address.
                                    </span>
                                </p>
                                <p className="c4">
                                    <span className="c1">
                                        If you provide your information to us or use the Site, you
                                        will have created a commercial relationship with us. Even
                                        though the Site presently opts not to provide Users, as
                                        described above, with e-mail advertising materials, your use
                                        of the Site and its services constitutes your understanding
                                        and consent that even unsolicited commercial email sent from
                                        us or our affiliates is not SPAM as that term is defined
                                        under the law.
                                    </span>
                                </p>
                                <ol className="c9 lst-kix_list_1-0" start="7">
                                    <li className="c4 c7 li-bullet-0">
                                        <span className="c2">Public Forums.</span>
                                    </li>
                                </ol>
                                <p className="c4">
                                    <span className="c1">
                                        At the time of the institution of this Policy, the Site
                                        offers a public forum for Users. The Company reserves the
                                        right to offer other services, such as blogging in the
                                        future.
                                    </span>
                                </p>
                                <p className="c4">
                                    <span className="c1">
                                        If you use a bulletin board, blog, or chat room on this Web
                                        site, you should be aware that any personally identifiable
                                        information you submit there can be read, collected, or used
                                        by other users of these forums, and could be used to send
                                        you unsolicited messages. We are not responsible for the
                                        personally identifiable information you choose to submit in
                                        these forums.
                                    </span>
                                </p>
                                <ol className="c9 lst-kix_list_1-0" start="8">
                                    <li className="c4 c7 li-bullet-0">
                                        <span className="c2">Our Commitment to Data Security.</span>
                                    </li>
                                </ol>
                                <p className="c4">
                                    <span className="c1">
                                        We take measures, including data encryption, to protect the
                                        transmission of all sensitive end-user information. We make
                                        every effort to ensure the integrity and security of our
                                        network and systems. Nevertheless, we cannot guarantee that
                                        our security measures will prevent third-party 'hackers'
                                        from illegally obtaining this information. We take all
                                        reasonable measures to prevent such breaches of security,
                                        but given the resourcefulness of cyber-criminals we are
                                        unable to guarantee that our security is 100% breach-proof.
                                        You assume the risk of such breaches to the extent that they
                                        occur despite our reasonable security measures.
                                    </span>
                                </p>
                                <p className="c4">
                                    <span className="c1">
                                        We do not provide User information to third parties. We will
                                        not sell, rent or provide any of your information to other
                                        entities such as advertisers, or other companies selling
                                        services similar to this Company's services.
                                    </span>
                                </p>
                                <ol className="c9 lst-kix_list_1-0" start="9">
                                    <li className="c4 c7 li-bullet-0">
                                        <span className="c2">
                                            Where to Direct Questions About Our Privacy Policy
                                        </span>
                                    </li>
                                </ol>
                                <p className="c4">
                                    <span className="c13">
                                        If you have any questions about this Privacy Policy or the
                                        practices described hereunder, you may contact us from
                                        our&nbsp;
                                    </span>
                                    <span className="c6">contact form</span>
                                    <span className="c1">.</span>
                                </p>
                                <ol className="c9 lst-kix_list_1-0" start="10">
                                    <li className="c4 c7 li-bullet-0">
                                        <span className="c2">Disclaimer</span>
                                    </li>
                                </ol>
                                <p className="c4">
                                    <span className="c1">
                                        If we deem your account as spam not a legitimate Escort or
                                        Escort agency your account will be banned &amp; any monies
                                        will not be returned.
                                    </span>
                                </p>
                                <p className="c8">
                                    <span className="c15"></span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Privacypolicy;
