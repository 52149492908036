import React, { useContext, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { CartContext } from "../context/CartContext";
import { numberWithCommas } from "../helper/UtilityHelper";
import OrderService from "../services/OrderService";
import { Toast } from "./layout/Alert";
import { useFormik } from "formik";
import { CheckoutForm } from "../helper/ValidationHelper";
import { Country } from "../helper/countries";
import { UserContext } from "../context/UserContext";
import { FormatMoney } from "format-money-js";
import CartService from "../services/CartService";

function Checkout() {
  const { getCartTotalAmount, clearCartItem } = useContext(CartContext);
  const { procssingCost } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const cart = localStorage.getItem("cart");
  const userDetails = localStorage.getItem("user");
  const parsedCart = JSON.parse(cart);
  const parsedUserDetails = JSON.parse(userDetails);
  const navigate = useNavigate();
  //   console.log("Cart", parsedCart);
  const total = getCartTotalAmount();
  // console.log("Total Price", parsedUserDetails);
  // console.log("Country",Country());
  const parsedProcesscingCost = JSON.parse(procssingCost);
  // console.log("shdj", parsedProcesscingCost);
  const submitHandler = async (formvalues) => {
    try {
      setIsLoading(true);
      let response = await OrderService.createDetails({
        data: formvalues,
      });
      if (response.status === 200) {
        await CartService.deleteCart(response?.data?.data?.id);
        // return false
        setIsLoading(false);
        Toast.fire({
          icon: "success",
          title: "Your Order is placed successfully",
        });
        clearCartItem();
        navigate("/");
      } else {
        setIsLoading(false);
        Toast.fire({
          icon: "warning",
          title: "Something went wrong. try again later",
        });
      }
    } catch (err) {
      setIsLoading(false);
      let message = err.message;
      if (err.code === "ERR_BAD_REQUEST") {
        message = "Something Went Worng!!!";
      }
      Toast.fire({ icon: "error", title: message });
    }
  };

  const fm = new FormatMoney({
    decimals: 2,
  });

  let totalFinalPrice = 0;
  if (total) {
    let precentage =
      parseFloat(total * parseInt(parsedProcesscingCost?.tax)) / 100;
    if (precentage) {
      let finalTotalPrice = parseFloat(
        total + parseInt(parsedProcesscingCost?.processing_fee) + precentage
      );
      totalFinalPrice = finalTotalPrice;
    }
  }
  // console.log("final total price", totalFinalPrice);

  const initialValues = {
    first_name: "",
    last_name: "",
    email_address: "",
    phone: "",
    country: "",
    address: "",
    town: "",
    postcode: "",
    transaction_id: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: CheckoutForm,
    onSubmit: (values, action) => {
      console.log("Value", values);
      submitHandler(values);
      action.resetForm();
      // resetInputFile();
    },
  });
  return (
    <>
      <div className="main-wrap dashboard-prt">
        <Container>
          <h2 className="main-title">Checkout</h2>

          <Row className="my-4">
            <Col md={6}>
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Row className="checkout">
                  <Col md={12}>
                    <h2>Billing Details</h2>
                  </Col>
                  <Col md={6}>
                    <h3>First Name *</h3>
                    <input
                      type="text"
                      name="first_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.first_name}
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <span className="text-danger small">
                        <strong>{formik.errors.first_name}</strong>
                      </span>
                    ) : null}
                  </Col>
                  <Col md={6}>
                    <h3>Last Name *</h3>
                    <input
                      type="text"
                      name="last_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.last_name}
                    />
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <span className="text-danger small">
                        <strong>{formik.errors.last_name}</strong>
                      </span>
                    ) : null}
                  </Col>
                  <Col md={6}>
                    <h3>Email Address *</h3>
                    <input
                      type="email"
                      name="email_address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email_address}
                    />
                    {formik.touched.email_address &&
                    formik.errors.email_address ? (
                      <span className="text-danger small">
                        <strong>{formik.errors.email_address}</strong>
                      </span>
                    ) : null}
                  </Col>
                  <Col md={6}>
                    <h3>Phone *</h3>
                    <input
                      type="tel"
                      name="phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <span className="text-danger small">
                        <strong>{formik.errors.phone}</strong>
                      </span>
                    ) : null}
                  </Col>
                  <Col md={12}>
                    <h3>Country *</h3>
                    <select
                      className="form-control"
                      name="country"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.country}
                    >
                      <option>Select country</option>
                      {Country().map((data, index) => {
                        return <option key={index}>{data.name}</option>;
                      })}
                    </select>
                    {formik.touched.country && formik.errors.country ? (
                      <span className="text-danger small">
                        <strong>{formik.errors.country}</strong>
                      </span>
                    ) : null}
                  </Col>
                  <Col md={12}>
                    <h3>Address *</h3>
                    <input
                      type="text"
                      placeholder="Address"
                      name="address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <span className="text-danger small">
                        <strong>{formik.errors.address}</strong>
                      </span>
                    ) : null}
                  </Col>
                  <Col md={12}>
                    <h3>Town / City *</h3>
                    <input
                      type="text"
                      placeholder="Town / City"
                      name="town"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.town}
                    />
                    {formik.touched.town && formik.errors.town ? (
                      <span className="text-danger small">
                        <strong>{formik.errors.town}</strong>
                      </span>
                    ) : null}
                  </Col>
                  <Col md={12}>
                    <h3>Postcode *</h3>
                    <input
                      type="tel"
                      name="postcode"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.postcode}
                    />
                    {formik.touched.postcode && formik.errors.postcode ? (
                      <span className="text-danger small">
                        <strong>{formik.errors.postcode}</strong>
                      </span>
                    ) : null}
                  </Col>
                  <Col md={12}>
                    <h3>Transaction ID*</h3>
                    <input
                      type="text"
                      placeholder="Transaction ID"
                      name="transaction_id"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.transaction_id}
                    />
                    {formik.touched.transaction_id &&
                    formik.errors.transaction_id ? (
                      <span className="text-danger small">
                        <strong>{formik.errors.transaction_id}</strong>
                      </span>
                    ) : null}
                  </Col>
                  <Col md={12}>
                    <Button className="btn btn-warning mt-3" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>

            <Col md={6}>
              <div className="checkout">
                <h2>Your order</h2>
              </div>

              <div className="element-table">
                <table>
                  <tr>
                    <th>Product</th>
                    <th className="text-right">Total</th>
                  </tr>
                  {parsedCart?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.attributes?.name}</td>
                        <td className="text-right">
                          $
                          {numberWithCommas(
                            numberWithCommas(
                              item?.attributes?.price * item?.quantiy
                            )
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td className="text-uppercase">
                      <b>Subtotal</b>
                    </td>
                    <td className="text-right">${total}</td>
                  </tr>
                  <tr>
                    <td className="text-uppercase">
                      <b> Processing Fee</b>
                    </td>
                    <td className="text-right">
                      ${parsedProcesscingCost?.processing_fee}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-uppercase">
                      <b>Tax</b>
                    </td>
                    <td className="text-right">
                      {parsedProcesscingCost?.tax}%
                    </td>
                  </tr>

                  <tr>
                    <td className="text-uppercase">
                      <b>Shipping</b>
                    </td>
                    <td className="shipping text-right">
                      {parsedProcesscingCost?.shipping_cost === "0"
                        ? "Free shipping"
                        : parsedProcesscingCost?.shipping_cost}
                    </td>
                  </tr>

                  <tr>
                    <td className="text-uppercase">
                      <b>Total</b>
                    </td>
                    <td className="total text-right">
                      ${fm.from(totalFinalPrice ? totalFinalPrice : 0)}
                    </td>
                    {/* <td className="total text-right">${total}</td> */}
                  </tr>
                  {/* <span>
                    ***Processing fees of $1 and 10% tax is added in the total
                    price***
                  </span> */}
                </table>
              </div>
              <div className="clearfix"></div>

              <div className="pay-faq">
                <h2>Payment method</h2>

                <div className="d-flex align-items-center my-3">
                  <figure className="qr-code">
                    <img src="images/qr.png" alt="" />
                  </figure>
                  <ul className="step-lst">
                    <li>Step 1: Scan the QR code.</li>
                    <li>Step 2: Enter the total amount.</li>
                    <li>Step 3: Pay the amount.</li>
                    <li>Step 4: Enter the Transaction Id on sales form.</li>
                    <li>Step 5: Verify your details and submit the form.</li>
                  </ul>
                </div>

                {/* <Form.Group className="my-3 d-flex">
                  <Form.Check type="radio" aria-label="pay1" name="radio1" />
                  <Form.Check.Label className="ms-2">
                    Direct bank transfer
                  </Form.Check.Label>
                </Form.Group>

                <Form.Group className="my-3 d-flex">
                  <Form.Check type="radio" aria-label="pay2" name="radio1" />
                  <Form.Check.Label className="ms-2">
                    Create an account?
                  </Form.Check.Label>
                </Form.Group>

                <Form.Group className="my-3 d-flex align-items-center">
                  <Form.Check type="radio" aria-label="pay3" name="radio1" />
                  <Form.Check.Label className="ms-2">
                    <span>PayPal</span>
                    <img src="images/check-out-payment.png" alt="" />
                    <h4>What is PayPal?</h4>
                  </Form.Check.Label>
                </Form.Group> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Checkout;
