import React, { useState } from "react";
import { Container, Row, Tab, Tabs } from "react-bootstrap";

import Create from "./Adds/Create";
// import Edit from "./Adds/Edit";
import List from "./Adds/List";
import CommentsList from "./CommentsList";
import RateHobbiyst from "./Adds/RateHobbiyst";

function ProviderDetails() {
  const [key, setKey] = useState("create-ad");

  return (
    <>
      <div className="main-wrap">
        <Container>
          <h2 className="main-title">Provider Details</h2>

          <Tabs
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="d-flex align-items-center justify-content-around mt-5"
            defaultActiveKey="create-ad"
          >
            <Tab eventKey="create-ad" title="Create New Ad">
              <Create></Create>
            </Tab>

            <Tab eventKey="edit-ad" title="Edit/View Your Ads (Add Time)">
              <div className="mt-5">
                <List></List>
              </div>
            </Tab>

            <Tab eventKey="review" title="Review Mail (Arrange Time & Place)">
              <Row className="mt-5">
                <h2>Demo text Here...</h2>
              </Row>
            </Tab>

            <Tab
              eventKey="screen-hobbyist"
              title="Screen Hobbyists (Rate/Comment on Hobbyists)"
            >
              {/* <Row className="mt-5">
                                <h2>Demo text Here...</h2>
                            </Row> */}
              {/* <CommentsList/> */}
              <RateHobbiyst />
            </Tab>

            {/* <a className="btn btn-warning">Edit/View Your Ads(Add Time)</a>
                        <a className="btn btn-warning">Review Mail(Arrange Time & Place)</a>
                        <a className="btn btn-warning">Screen Hobbyists (Rate / Comment on Hobbyists)</a> */}
          </Tabs>
        </Container>
      </div>
    </>
  );
}

export default ProviderDetails;
