import React, { useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { useFormik } from "formik";

import { UserContext } from "../../context/UserContext";
import UserService from "../../services/UserService";
import { ChangePasswordForm } from "../../helper/ValidationHelper";

import { Toast } from "../layout/Alert";
import Account from "../layout/Account/Account";

function ChangePassword() {
    const { updateUserId, updateToken } = useContext(UserContext);

    const initialValues = {
        currentPassword: "",
        password: "",
        passwordConfirmation: "",
    };

    const [isLoading, setIsLoading] = useState(false);

    const submitHandler = async (inputs) => {
        try {
            setIsLoading(true);
            const response = await UserService.changePassword(inputs);
            if (response.status === 200) {
                setIsLoading(false);
                updateToken(response.data.jwt);
                updateUserId(response.data.user.id);
                Toast.fire({
                    icon: "success",
                    title: "Your password are successfully updated",
                });
            } else {
                setIsLoading(false);
                Toast.fire({
                    icon: "warning",
                    title: "Something went wrong. try again later",
                });
            }
        } catch (err) {
            setIsLoading(false);
            if (err && err.response.status) {
                let message = err.message;
                if (err.code === "ERR_BAD_REQUEST") {
                    if (err.response.data.error) {
                        message = err.response.data.error.message;
                    }
                }
                Toast.fire({ icon: "error", title: message });
            }
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: ChangePasswordForm,
        onSubmit: (values, action) => {
            submitHandler(values);
            action.resetForm();
        },
    });

    return (
        <Account>
            <div className="filter-part p-3 mb-3">
                <h3 className="title1">Change Password</h3>
                <div className="signin-form mt-4 p-3">
                    <Form
                        className="row"
                        noValidate
                        onSubmit={formik.handleSubmit}
                    >
                        <Form.Group
                            className="col-md-12 mb-3"
                            controlId="currentPassword"
                        >
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="currentPassword"
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.currentPassword}
                                isInvalid={
                                    formik.touched.currentPassword &&
                                    formik.errors.currentPassword
                                }
                                className="form-control"
                                required
                            />
                            {formik.touched.currentPassword &&
                                formik.errors.currentPassword ? (
                                <span className="text-danger small ">
                                    <strong>{formik.errors.currentPassword}</strong>
                                </span>
                            ) : null}
                        </Form.Group>
                        <Form.Group className="col-md-12 mb-3" controlId="password">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                isInvalid={
                                    formik.touched.password && formik.errors.password
                                }
                                className="form-control"
                                required
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <span className="text-danger small ">
                                    <strong>{formik.errors.password}</strong>
                                </span>
                            ) : null}
                            {/* <small>* Min 6 letter, with at least a symbol, upper and lower case letters and a number</small> */}
                        </Form.Group>

                        <Form.Group
                            className="col-md-12 mb-3"
                            controlId="passwordConfirmation"
                        >
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="passwordConfirmation"
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.passwordConfirmation}
                                isInvalid={
                                    formik.touched.passwordConfirmation &&
                                    formik.errors.passwordConfirmation
                                }
                                className="form-control"
                                required
                            />
                            {formik.touched.passwordConfirmation &&
                                formik.errors.passwordConfirmation ? (
                                <span className="text-danger small ">
                                    <strong>{formik.errors.passwordConfirmation}</strong>
                                </span>
                            ) : null}
                        </Form.Group>

                        <Form.Group className="col-md-12 mb-3 d-flex align-items-center">
                            <Button
                                disabled={isLoading}
                                type="submit"
                                className="btn btn-warning"
                            >
                                {isLoading ? (
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    "Update"
                                )}
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </Account>
    );
}

export default ChangePassword;
