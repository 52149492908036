import React, { useState, useEffect } from "react";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import HttpHelper from "../../helper/HttpHelper";
import vendorService from "../../services/VendorService";
import RightSidebar from "../layout/RightSidebar";

function Single(props) {
    const url = HttpHelper.BASE_URL;

    const [data, setdata] = useState([]);
    const [open, setOpen] = useState(true);

    async function getVendor() {
        let query = {
            populate: "*",
        };
        const response = await vendorService.getVendor(props.id, query);
        console.log(response);
        if (response.status === 200) {
            setdata([response.data["data"]]);
            console.log(response.data["data"]);
        }
    }

    useEffect(() => {
        try {
            getVendor();
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <>
            <div className="main-wrap">
                <Container>
                    <Row>
                        <Col md={2}>
                            <div className="category-list mb-3">
                                <a
                                    className="expand"
                                    onClick={() => setOpen(!open)}
                                    aria-controls="category1"
                                    aria-expanded={open}
                                >
                                    <div className="right-arrow">
                                        <i className="fa fa-angle-down"></i>
                                    </div>
                                    <div>
                                        <h3 className="hd3">Services</h3>
                                    </div>
                                </a>
                                <Collapse className="category-body" id="category1" in={open}>
                                    <ul>
                                        <li>
                                            <a href="#">Table Shower (9)</a>
                                        </li>
                                        <li>
                                            <a href="#">Sauna (3)</a>
                                        </li>
                                        <li>
                                            <a href="#">Jacuzzi (1)</a>
                                        </li>
                                        <li>
                                            <a href="#">Table Shower (9)</a>
                                        </li>
                                        <li>
                                            <a href="#">Sauna (3)</a>
                                        </li>
                                        <li>
                                            <a href="#">Jacuzzi (1)</a>
                                        </li>
                                    </ul>
                                </Collapse>
                            </div>
                        </Col>
                        <Col md={7}>
                            <div className="filter-part">
                                {data.map((row) => {
                                    return (
                                        <div className="card-wrapper" key={row.id}>
                                            <div className="card-view d-flex">
                                                <div className="info info-left flex-grow-1">
                                                    <div
                                                        className="image-wrapper"
                                                        style={{ maxWidth: "350px", height: "350px" }}
                                                    >
                                                        <img
                                                            src={
                                                                row.attributes.image.data
                                                                    ? url +
                                                                    row.attributes.image.data.attributes
                                                                        .formats.thumbnail.url
                                                                    : "/images/no-photo-small.svg"
                                                            }
                                                            alt=""
                                                            className="image"
                                                            border="0"
                                                        />
                                                    </div>
                                                    <div className="info">
                                                        <h6 className="title color-link weight-600 m-b-5">
                                                            {row.attributes.name}
                                                        </h6>
                                                        <p>{row.attributes.phone}</p>
                                                        {row.attributes?.vendor_type && <p>{row.attributes?.vendor_type?.data?.attributes?.type}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="fixme">
                                <RightSidebar />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Single;
