import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./termsofservice.css";

function Termsofservice() {
    return (
        <>
            <div className="main-wrap">
                <Container>
                    <h2 className="main-title">Hot Spicy Girls Terms of Service</h2>

                    <Row className="mt-5">
                        <Col md={{ span: 10, offset: 1 }}>
                            <div className="c19 doc-Terms">
                                {/* <p class="c9"><span class="c4">Hot Spicy Girls Terms of Service</span></p> */}
                                <p className="c9">
                                    <span className="c0">
                                        These terms of service outline the rules and regulations for
                                        the use of www.hotspicygirls.com Website.
                                        <br />
                                        By accessing this website we assume you accept these terms
                                        of service in full. Do not continue to use
                                        www.hotspicygirls.com's website if you do not accept all of
                                        the terms of service stated on this page.
                                    </span>
                                </p>
                                <p className="c9">
                                    <span className="c0">
                                        The following terminology applies to these terms of service,
                                        Privacy Statement and Disclaimer Notice and any or all
                                        Agreements: "Client", “You” and “Your” refers to you, the
                                        person accessing this website and accepting the Company’s
                                        terms of service. "The Company", “Ourselves”, “We”, “Our”
                                        and "Us", refers to our Company. “Party”, “Parties”, or
                                        “Us”, refers to both the Client and ourselves, or either the
                                        Client or ourselves. All terms refer to the offer,
                                        acceptance and consideration of payment necessary to
                                        undertake the process of our assistance to the Client in the
                                        most appropriate manner, whether by formal meetings of a
                                        fixed duration, or any other means, for the express purpose
                                        of meeting the Client’s needs in respect of provision of the
                                        Company’s stated services/products, in accordance with and
                                        subject to, prevailing law of United States. Any use of the
                                        above terminology or other words in the singular, plural,
                                        capitalisation and/or he/she or they, are taken as
                                        interchangeable and therefore as referring to same.
                                    </span>
                                </p>
                                <ul className="c6 lst-kix_list_1-0 start">
                                    <li className="c3 li-bullet-0">
                                        <span className="c5">Cookies</span>
                                    </li>
                                </ul>
                                <ul className="c6 lst-kix_list_1-1 start">
                                    <li className="c8 li-bullet-0">
                                        <span className="c10">
                                            We employ the use of cookies. By using&nbsp;
                                        </span>
                                        <span className="c12">www.hotspicygirls.com</span>
                                        <span className="c10">
                                            's website you consent to the use of cookies in accordance
                                            with www.hotspicygirls.com’s&nbsp;
                                        </span>
                                        <span className="c12">
                                            <a
                                                className="c18"
                                                href="https://www.google.com/url?q=https://adultsearch.com/privacy-policy&amp;sa=D&amp;source=editors&amp;ust=1681911055602984&amp;usg=AOvVaw2Q--y7jL_ZBienhBJZa9JP"
                                            >
                                                privacy policy
                                            </a>
                                        </span>
                                        <span className="c0">.</span>
                                    </li>
                                    <li className="c8 li-bullet-0">
                                        <span className="c0">
                                            Most of the modern day interactive web sites use cookies
                                            to enable us to retrieve user details for each visit.
                                            Cookies are used in some areas of our site to enable the
                                            functionality of this area and ease of use for those
                                            people visiting. Some of our affiliate / advertising
                                            partners may also use cookies.
                                        </span>
                                    </li>
                                </ul>
                                <ul className="c6 lst-kix_list_1-0">
                                    <li className="c3 li-bullet-0">
                                        <span className="c5">License</span>
                                    </li>
                                </ul>
                                <ul className="c6 lst-kix_list_1-1 start">
                                    <li className="c8 li-bullet-0">
                                        <span className="c0">
                                            Unless otherwise stated, www.hotspicygirls.com and/or it’s
                                            licensors own the intellectual property rights for all
                                            material on www.hotspicygirls.com All intellectual
                                            property rights are reserved. You may view and/or print
                                            pages from www.hotspicygirls.com for your own personal use
                                            subject to restrictions set in these terms of service.
                                        </span>
                                    </li>
                                    <li className="c8 li-bullet-0">
                                        <span className="c0">You must not:</span>
                                    </li>
                                </ul>
                                <ul className="c6 lst-kix_list_1-2 start">
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">
                                            Republish material from www.hotspicygirls.com
                                        </span>
                                    </li>
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">
                                            Sell, rent or sub-license material from
                                            www.hotspicygirls.com
                                        </span>
                                    </li>
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">
                                            Reproduce, duplicate or copy material from
                                            www.hotspicygirls.com
                                        </span>
                                    </li>
                                </ul>
                                <ul className="c6 lst-kix_list_1-1">
                                    <li className="c8 li-bullet-0">
                                        <span className="c0">
                                            Redistribute content from www.hotspicygirls.com (unless
                                            content is specifically made for redistribution).
                                        </span>
                                    </li>
                                </ul>
                                <ul className="c6 lst-kix_list_1-0">
                                    <li className="c3 li-bullet-0">
                                        <span className="c5">User Comments</span>
                                    </li>
                                </ul>
                                <ol className="c6 lst-kix_list_2-1 start">
                                    <li className="c1 li-bullet-0">
                                        <span className="c0">
                                            This Agreement shall begin on the date hereof.
                                        </span>
                                    </li>
                                    <li className="c8 li-bullet-0">
                                        <span className="c0">
                                            Certain parts of this website offer the opportunity for
                                            users to post and exchange opinions, information, material
                                            and data ('Comments') in areas of the website.
                                            www.hotspicygirls.com does not screen, edit, publish or
                                            review Comments prior to their appearance on the website
                                            and Comments do not reflect the views or opinions of
                                            www.hotspicygirls.com, its agents or affiliates. Comments
                                            reflect the view and opinion of the person who posts such
                                            view or opinion. To the extent permitted by applicable
                                            laws Adultsearch.com shall not be responsible or liable
                                            for the Comments or for any loss cost, liability, damages
                                            or expenses caused and or suffered as a result of any use
                                            of and/or posting of and/or appearance of the Comments on
                                            this website.
                                        </span>
                                    </li>
                                    <li className="c8 li-bullet-0">
                                        <span className="c0">
                                            www.hotspicygirls.com reserves the right to monitor all
                                            Comments and to remove any Comments which it considers in
                                            its absolute discretion to be inappropriate, offensive or
                                            otherwise in breach of these terms of service.
                                        </span>
                                    </li>
                                    <li className="c7 li-bullet-0">
                                        <span className="c0">You warrant and represent that:</span>
                                    </li>
                                </ol>
                                <ol className="c6 lst-kix_list_3-2 start">
                                    <li className="c11 li-bullet-0">
                                        <span className="c0">
                                            You are entitled to post the Comments on our website and
                                            have all necessary licenses and consents to do so;
                                        </span>
                                    </li>
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">
                                            The Comments do not infringe any intellectual property
                                            right, including without limitation copyright, patent or
                                            trademark, or other proprietary right of any third party;
                                        </span>
                                    </li>
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">
                                            The Comments do not contain any defamatory, libelous,
                                            offensive, indecent or otherwise unlawful material or
                                            material which is an invasion of privacy
                                        </span>
                                    </li>
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">
                                            The Comments will not be used to solicit or promote
                                            business or custom or present commercial activities or
                                            unlawful activity.
                                        </span>
                                    </li>
                                </ol>
                                <ol className="c6 lst-kix_list_3-1 start">
                                    <li className="c8 li-bullet-0">
                                        <span className="c10">You hereby grant to&nbsp;</span>
                                        <span className="c15">www.hotspicygirls.com</span>
                                        <span className="c0">
                                            &nbsp;a non-exclusive royalty-free license to use,
                                            reproduce, edit and authorize others to use, reproduce and
                                            edit any of your Comments in any and all forms, formats or
                                            media.
                                        </span>
                                    </li>
                                </ol>
                                <ul className="c6 lst-kix_list_3-0 start">
                                    <li className="c3 li-bullet-0">
                                        <span className="c5">Hyperlinking to our Content</span>
                                    </li>
                                </ul>
                                <ol className="c6 lst-kix_list_3-1 start">
                                    <li className="c7 li-bullet-0">
                                        <span className="c0">
                                            The following organizations may link to our Web site
                                            without prior written approval:
                                        </span>
                                    </li>
                                </ol>
                                <ul className="c6 lst-kix_list_4-2 start">
                                    <li className="c11 li-bullet-0">
                                        <span className="c0">Government agencies;</span>
                                    </li>
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">Search engines;</span>
                                    </li>
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">News organizations;</span>
                                    </li>
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">
                                            Online directory distributors when they list us in the
                                            directory may link to our Web site in the same manner as
                                            they hyperlink to the Web sites of other listed
                                            businesses; and
                                        </span>
                                    </li>
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">
                                            Systemwide Accredited Businesses except soliciting
                                            non-profit organizations, charity shopping malls, and
                                            charity fundraising groups which may not hyperlink to our
                                            Web site.
                                        </span>
                                    </li>
                                </ul>
                                <ol className="c6 lst-kix_list_4-1 start">
                                    <li className="c7 li-bullet-0">
                                        <span className="c0">
                                            The following organizations may link to our Web site
                                            without prior written approval:
                                        </span>
                                    </li>
                                </ol>
                                <p className="c9 c20">
                                    <span className="c0">
                                        These organizations may link to our home page, to
                                        publications or to other Web site information so long as the
                                        link: (a) is not in any way misleading; (b) does not falsely
                                        imply sponsorship, endorsement or approval of the linking
                                        party and its products or services; and (c) fits within the
                                        context of the linking party's site.
                                    </span>
                                </p>
                                <ol className="c6 lst-kix_list_4-1">
                                    <li className="c1 li-bullet-0">
                                        <span className="c0">
                                            We may consider and approve in our sole discretion other
                                            link requests from the following types of organizations:
                                        </span>
                                    </li>
                                </ol>
                                <ul className="c6 lst-kix_list_4-2 start">
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">
                                            commonly-known consumer and/or business information
                                            sources such as Chambers of Commerce, American Automobile
                                            Association, AARP and Consumers Union;
                                        </span>
                                    </li>
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">dot.com community sites;</span>
                                    </li>
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">
                                            associations or other groups representing charities,
                                            including charity giving sites,
                                        </span>
                                    </li>
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">online directory distributors;</span>
                                    </li>
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">internet portals;</span>
                                    </li>
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">
                                            accounting, law and consulting firms whose primary clients
                                            are businesses; and
                                        </span>
                                    </li>
                                    <li className="c2 li-bullet-0">
                                        <span className="c0">
                                            educational institutions and trade associations.
                                        </span>
                                    </li>
                                </ul>
                                <ul className="c6 lst-kix_list_4-0 start">
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">
                                            We will approve link requests from these organizations if
                                            we determine that: (a) the link would not reflect
                                            unfavorably on us or our accredited businesses (for
                                            example, trade associations or other organizations
                                            representing inherently suspect types of business, such as
                                            work-at-home opportunities, shall not be allowed to link);
                                            (b)the organization does not have an unsatisfactory record
                                            with us; (c) the benefit to us from the visibility
                                            associated with the hyperlink outweighs the absence of
                                            www.hotspicygirls.com; and (d) where the link is in the
                                            context of general resource information or is otherwise
                                            consistent with editorial content in a newsletter or
                                            similar product furthering the mission of the
                                            organization.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">
                                            These organizations may link to our home page, to
                                            publications or to other Web site information so long as
                                            the link: (a) is not in any way misleading; (b) does not
                                            falsely imply sponsorship, endorsement or approval of the
                                            linking party and it products or services; and (c) fits
                                            within the context of the linking party's site.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">
                                            If you are among the organizations listed in paragraph 2
                                            above and are interested in linking to our website, you
                                            must notify us. Please include your name, your
                                            organization name, contact information (such as a phone
                                            number and/or e-mail address) as well as the URL of your
                                            site, a list of any URLs from which you intend to link to
                                            our Web site, and a list of the URL(s) on our site to
                                            which you would like to link. Allow 2-3 weeks for a
                                            response.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">
                                            Approved organizations may hyperlink to our Web site as
                                            follows:
                                        </span>
                                    </li>
                                    <li className="c8 c14 li-bullet-0">
                                        <span className="c0"></span>
                                    </li>
                                </ul>
                                <ul className="c6 lst-kix_list_5-1 start">
                                    <li className="c1 li-bullet-0">
                                        <span className="c0">By use of our corporate name; or</span>
                                    </li>
                                    <li className="c8 li-bullet-0">
                                        <span className="c0">
                                            By use of the uniform resource locator (Web address) being
                                            linked to; or
                                        </span>
                                    </li>
                                    <li className="c8 li-bullet-0">
                                        <span className="c0">
                                            By use of any other description of our Web site or
                                            material being linked to that makes sense within the
                                            context and format of content on the linking party's site.
                                        </span>
                                    </li>
                                </ul>
                                <ul className="c6 lst-kix_list_5-0 start">
                                    <li className="c3 li-bullet-0">
                                        <span className="c5">Iframes</span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">
                                            Without prior approval and express written permission, you
                                            may not create frames around our Web pages or use other
                                            techniques that alter in any way the visual presentation
                                            or appearance of our Web site.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c5">Content Liability</span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">
                                            We shall have no responsibility or liability for any
                                            content appearing on your Web site. You agree to indemnify
                                            and defend us against all claims arising out of or based
                                            upon your Website. No link(s) may appear on any page on
                                            your Web site or within any context containing content or
                                            materials that may be interpreted as libelous, obscene or
                                            criminal, or which infringes, otherwise violates, or
                                            advocates the infringement or other violation of, any
                                            third party rights.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c5">Reservation Rights</span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">
                                            We reserve the right at any time and in its sole
                                            discretion to request that you remove all links or any
                                            particular link to our Web site. You agree to immediately
                                            remove all links to our Web site upon such request. We
                                            also reserve the right to amend these terms of service and
                                            its linking policy at any time. By continuing to link to
                                            our Web site, you agree to be bound to and abide by these
                                            linking terms of service.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c5">Removal of links from our website</span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">
                                            If you find any link on our Web site or any linked web
                                            site objectionable for any reason, you may contact us
                                            about this. We will consider requests to remove links but
                                            will have no obligation to do so or to respond directly to
                                            you.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">
                                            Whilst we endeavour to ensure that the information on this
                                            website is correct, we do not warrant its completeness or
                                            accuracy; nor do we commit to ensuring that the website
                                            remains available or that the material on the website is
                                            kept up to date.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c5">Disclaimer</span>
                                    </li>
                                    <li className="c13 li-bullet-0">
                                        <span className="c0">
                                            To the maximum extent permitted by applicable law, we
                                            exclude all representations, warranties and conditions
                                            relating to our website and the use of this website
                                            (including, without limitation, any warranties implied by
                                            law in respect of satisfactory quality, fitness for
                                            purpose and/or the use of reasonable care and skill).
                                            Nothing in this disclaimer will:
                                        </span>
                                    </li>
                                </ul>
                                <ol className="c6 lst-kix_list_6-1 start">
                                    <li className="c1 li-bullet-0">
                                        <span className="c0">
                                            limit or exclude our or your liability for death or
                                            personal injury resulting from negligence;
                                        </span>
                                    </li>
                                    <li className="c8 li-bullet-0">
                                        <span className="c0">
                                            limit or exclude our or your liability for fraud or
                                            fraudulent misrepresentation;
                                        </span>
                                    </li>
                                    <li className="c8 li-bullet-0">
                                        <span className="c0">
                                            limit any of our or your liabilities in any way that is
                                            not permitted under applicable law; or
                                        </span>
                                    </li>
                                    <li className="c8 li-bullet-0">
                                        <span className="c0">
                                            exclude any of our or your liabilities that may not be
                                            excluded under applicable law.
                                        </span>
                                    </li>
                                </ol>
                                <ul className="c6 lst-kix_list_6-0 start">
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">
                                            The limitations and exclusions of liability set out in
                                            this Section and elsewhere in this disclaimer: (a) are
                                            subject to the preceding paragraph; and (b) govern all
                                            liabilities arising under the disclaimer or in relation to
                                            the subject matter of this disclaimer, including
                                            liabilities arising in contract, in tort (including
                                            negligence) and for breach of statutory duty.
                                        </span>
                                    </li>
                                    <li className="c3 li-bullet-0">
                                        <span className="c0">
                                            To the extent that the website and the information and
                                            services on the website are provided free of charge, we
                                            will not be liable for any loss or damage of any nature.
                                        </span>
                                    </li>
                                    <li className="c13 li-bullet-0">
                                        <span className="c0">
                                            If we deem your account as spam not a legitimate Escort or
                                            Escort agency your account will be banned &amp; any monies
                                            will not be returned.
                                        </span>
                                    </li>
                                </ul>
                                <p className="c14 c16">
                                    <span className="c17"></span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Termsofservice;
