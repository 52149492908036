import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import HttpHelper from "../../../helper/HttpHelper";
import ProductService from "../../../services/ProductService";
import UploadService from "../../../services/UploadService";

import Loader from "../../layout/Loader";
import { Toast } from "../../layout/Alert";
import Account from "../../layout/Account/Account";

import { UserContext } from "../../../context/UserContext";

export default function ProductList() {
    const { userId } = useContext(UserContext);

    const [query, setQuery] = useState({
        filters: {
            user: {
                id: {
                    $eq: userId,
                },
            },
        },
        populate: "*",
        pagination: { pageSize: 10 },
        sort: ['id:desc']
    });

    const handlePerPageOnchange = (event) => {
        let tempQuery = query
        tempQuery.pagination.pageSize = event.target.value;
        setQuery(tempQuery)
        fetchData();
        //console.log(event.target.value)
    }
    const [data, setData] = useState([]);
    const [visible, setvisible] = useState(true);

    const fetchData = async () => {
        try {
            const response = await ProductService.getAll(query);
            if (response.status !== 200) {
                throw new Error("Network response was not ok");
            }
            setData(response.data.data);
            setvisible(false);
        } catch (error) {
            setvisible(false);
            Toast.fire({ icon: "error", title: "Network response was not ok" });
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const [itemId, setitemId] = useState(0);
    const [imageId, setImageId] = useState(0);
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleShow = (id, imgId) => {
        setShow(true);
        setitemId(id);
        setImageId(imgId);
    };
    const handleClose = () => {
        setShow(false);
    };
    const handleAction = async () => {
        try {
            setIsLoading(true);
            await ProductService.delete(itemId);
            await UploadService.delete(imageId);
            fetchData();
            handleClose();
            setIsLoading(false);
            Toast.fire({
                icon: "success",
                title: "Your Product are successfully deleted",
            });
        } catch (error) {
            setIsLoading(false);
            Toast.fire({ icon: "error", title: "Network response was not ok" });
        }
    };

    return (
        <Account>
            <Loader visible={visible}>
                <div className="filter-part p-3 mb-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <h3 className="title1 p-0 mb-3">My Products</h3>
                        <div className="rt-filter-prt d-flex align-items-center justify-content-end">
                            <Form.Select onChange={handlePerPageOnchange} size="sm">
                                <option value={10}>10 Per Page</option>
                                <option value={20}>20 Per Page</option>
                                <option value={30}>30 Per Page</option>
                                <option value={40}>40 Per Page</option>
                            </Form.Select>
                            <Link to={"/products/add"} className="btn btn-success">
                                <i className="fa fa-plus"></i>&nbsp;&nbsp;New Product
                            </Link>
                        </div>
                    </div>

                    <Table
                        striped
                        bordered
                        hover
                        responsive
                        size="sm"
                        className="pro-table mt-4"
                    >
                        <thead>
                            <tr>
                                <th className="text-center">#</th>
                                <th className="text-center">Product</th>
                                <th className="text-center">Name</th>
                                <th className="text-center">Description</th>
                                <th className="text-center">Price</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row) => {
                                const attr = row.attributes;
                                return (
                                    <tr key={row.id}>
                                        <td className="text-center">{row.id}</td>
                                        <td className="text-center">
                                            <Photo image={attr.picture}></Photo>
                                        </td>
                                        <td>{attr.name}</td>
                                        <td>{attr.description}</td>
                                        <td className="text-right">${attr.price}</td>
                                        <td className="text-center">
                                            <Link
                                                variant="success"
                                                title="Edit"
                                                to={"/products/edit/" + row.id}
                                                className="btn btn-success text-light"
                                            >
                                                <i className="fas fa-edit"></i> Edit
                                            </Link>
                                            <Button
                                                title="Delete"
                                                variant="danger"
                                                onClick={() => handleShow(row.id, attr.picture.data?.id)}
                                                className="btn btn-danger text-light"
                                            >
                                                <i className="fas fa-trash"></i> Delete
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </Loader>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Delete this Product?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        title="Delete"
                        disabled={isLoading}
                        variant="warning"
                        onClick={handleAction}
                    >
                        {isLoading ? (
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            "Delete"
                        )}
                    </Button>
                    <Button title="Close" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Account>
    );
}

function Photo({ image }) {
    const url = HttpHelper.BASE_URL;
    console.log(image);
    if (image.data) {
        let img = image.data?.attributes;
        if (img && img.formats) {
            return (
                <img
                    alt={img.formats.thumbnail.name}
                    src={url + img.formats.thumbnail.url}
                    className="image"
                    border="0"
                />
            );
        }
        return (
            <img alt={img.name} src={url + img.url} className="image" border="0" />
        );
    }
    return (
        <img
            alt="no"
            src="/images/no-photo-small.svg"
            className="image"
            border="0"
        />
    );
}
