import React, {
    useContext,
    useEffect,
    useState,
    useCallback,
    memo,
} from "react";
import { Button } from "react-bootstrap";

import CityService from "../../../services/CityService";
import StatesService from "../../../services/StatesService";
import CountryService from "../../../services/CountryService";
import { CatagoryContext } from "../../../context/CatagoryContext";

const Country = memo(function Country({ country, setCountry, provider }) {
    return (
        <div className="form-check">
            <input
                value={"US"}
                type="radio"
                name={"country"}
                checked={country === "US"}
                className="form-check-input"
                onChange={(e) => setCountry((c) => e.target.value)}
                id={`countryeUS`}
            />
            <label className="form-check-label" htmlFor={`countryeUS`}>
                United States ({provider > 0 ? provider : 0})
            </label>
        </div>
    );
});
const State = memo(function State({ stateList, state, setState,handleStateCheckBox }) {
    return stateList.map((row, i) => {
        return (
            <div key={i} className="form-check">
                <input
                    type="radio"
                    name={"state"}
                    className="form-check-input"
                    value={row.state_code}
                    checked={state === row.state_code}
                    onChange={(e) => {
                        setState((c) => e.target.value);
                        handleStateCheckBox(e);
                    }}
                    id={`state${i}`}
                />
                <label className="form-check-label" htmlFor={`state${i}`}>
                    {row.state_name+' ('+row.provider+')'}
                </label>
            </div>
        );
    });
});
const City = memo(function City({ cityList, city, handleCityCheckBox }) {
    return cityList?.map((row, i) => {
        return (
            <div key={i} className="form-check">
                <input
                    type="checkbox"
                    name={`city${i}`}
                    className="form-check-input"
                    value={row.city_name}
                    checked={city.indexOf(row.city_name) > -1}
                    onChange={(e) => handleCityCheckBox(e)}
                    id={`city${i}`}
                />
                <label className="form-check-label" htmlFor={`city${i}`}>
                    {row.city_name+' ('+row.provider+')'} 
                </label>
            </div>
        );
    });
});
function LocationListComponent(props) {
    return (
        <div
            className={props.show ? "collapse show" : "collapse"}
            id="collapseExample"
        >
            <div className="card card-body">
                <div className="row">
                    <div className="col-md-4">
                        <h3>Country</h3>
                        <div className="checkWrap">
                            <Country country={props.country} setCountry={props.setCountry} provider={props.provider} />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h3>State</h3>
                        <div className="checkWrap">
                            <State
                                stateList={props.stateList}
                                state={props.state}
                                setState={props.setState}
                                handleStateCheckBox={props.handleStateCheckBox}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h3>City</h3>
                        <div className="checkWrap">
                            <City
                                cityList={props.cityList}
                                city={props.city}
                                handleCityCheckBox={props.handleCityCheckBox}
                            />
                        </div>
                    </div>
                </div>
                <div className="row" style={{ margin: "10px", marginTop: "30px" }}>
                    <div className="col-md-8"></div>
                    <div className="col-md-4">
                        <Button
                            title="Find"
                            variant="secondary"
                            style={{ width: "100%" }}
                            onClick={props.handleFind}
                        >
                            Find
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
function LocationComponent() {
    const { updateCatagory } = useContext(CatagoryContext);

    const [country, setCountry] = useState("US");
    const [state, setState] = useState("CA");
    const [city, setCity] = useState([]);

    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [provider, setProvider] = useState(0);

    const getCountryWiseProvider = useCallback(async () => {
        try {
            const response = await CountryService.getProviderByCountry({
                country_code: country,
            });
            if (response.status === 200) {
                setProvider(response.data.data.length > 0 ? response.data.data[0]?.provider : 0);
            }
        } catch (err) {
            console.log(err);
        }
    }, [country]);

    const getState = useCallback(async () => {
        try {
            // const response = await StatesService.getAllWithProviderCount({
            //     filters: {
            //         country_code: {
            //             $eq: country,
            //         },
            //     },
            //     fields: ["state_name", "state_code"],
            //     pagination: { pageSize: 100 },
            //     sort: ["state_name:asc"],
            // });

            const response = await StatesService.getAllWithProviderCount({
                country_code: country,
            });
            if (response.status === 200) {
                setStateList(response.data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }, [country]);

    const getCity = useCallback(async () => {
        try {
            // const response = await CityService.getAll({
            //     filters: {
            //         state_code: {
            //             $eq: state,
            //         },
            //     },
            //     fields: ["city_name", "state_code", "state_name"],
            //     pagination: { pageSize: 1500 },
            //     sort: ["city_name:asc"],
            // });
            const response = await CityService.getAllByState({
                state_code: state,
            });
            console.log('response===>',response.status)
            if (response.status === 200) {
                // setCity(response.data.data[0].attributes.city_name)
                setCityList(response.data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }, [state]);

    const handleFind = useCallback(() => {
        console.log("updateCatagory");
        let find = {};
        find.city = city;
        find.state = [state];
        find.country = [country];
        updateCatagory(find);
        setshow(false);
    }, [city, state, country, updateCatagory]);
    const handleStateCheckBox = useCallback((e) => {
        setCity('');
    });

    const handleCityCheckBox = useCallback((e) => {
        setCity(o => {
            let copy = [...o];
            if (e.target.checked) {
                copy.push(e.target.value);
            } else {
                copy = copy.filter((el) => el !== e.target.value);
            }
            console.log(city, o, copy);
            return copy;
        });
    }, [city]);

    useEffect(() => {
        getCountryWiseProvider()
        if (state) {
            getCity();
        }
    }, [getCity, state]);

    useEffect(() => {
        if (!stateList.length) {
            getState();
        }
    }, [getState, stateList.length]);

    const [show, setshow] = useState(false);

    return (
        <>
            <ul onClick={() => setshow(!show)}>
                <li>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                </li>
                <li>{country}</li>
                <li>{">"}</li>
                <li>{state}</li>
                <li>{">"}</li>
                <li>{city[city.length - 1]}</li>
            </ul>
            <div
                className={show ? "find-location-bx show" : "find-location-bx"}
                onClick={() => setshow(!show)}
            ></div>
            <LocationListComponent
                show={show}
                country={country}
                provider={provider}
                setCountry={setCountry}
                stateList={stateList}
                state={state}
                setState={setState}
                cityList={cityList}
                city={city}
                handleFind={handleFind}
                handleCityCheckBox={handleCityCheckBox}
                handleStateCheckBox={handleStateCheckBox}
            />
        </>
    );
}
export default memo(LocationComponent);
