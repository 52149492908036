import React, { useContext, useEffect, useState } from "react";
import Account from "../layout/Account/Account";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import PackageServices from "../../services/PackageServices";
import SubscriptionServices from "../../services/SubscriptionServices";
import { UserContext } from "../../context/UserContext";

const MySubscription = () => {
  const { isSubscribed } = useContext(UserContext);
  const [activePackage, setActivePackage] = useState({});
  let userData = localStorage.getItem("user");
  let parsedData = JSON.parse(userData);
  //   console.log("users", parsedData.username);

  const activeSubscriptions = parsedData.subscriptions.filter(
    (subscription) => {
      return subscription.isActive === true;
    }
  );
  const resultObject = {};

  if (activeSubscriptions.length) {
    activeSubscriptions.forEach((obj) => {
      Object.assign(resultObject, obj);
    });
    // setActiveSubs(resultObject)
  }

  // console.log("dsdhg", resultObject);

  async function fetchPackageDetails() {
    let packageRes = await SubscriptionServices.getOne(resultObject?.id, {
      populate: ["package"],
    });
    if (packageRes.status === 200) {
      //   console.log("Res", packageRes?.data?.data?.attributes?.package?.data);
      setActivePackage(packageRes?.data?.data?.attributes?.package?.data);
    }
  }

  useEffect(() => {
    fetchPackageDetails();
  }, []);

  return (
    <Account>
      <div className="filter-part p-3 mb-3">
        <h3 className="title1">My Subscription</h3>
        <Row className="mt-4">
          <Col md={5}>
            <div className="lft-profile-prt">
              <div className="balence-bx">
                <h2>{activePackage?.attributes?.name}</h2>
              </div>
              <Row className="mt-3 justify-content-center">
                <Col md={6}>
                  <div className="b-bx">
                    <h3>
                      <span>Number of Ads</span>
                      {/* {parseFloat(totalCredit) - parseFloat(totalCreditPackage)} */}
                      {activePackage?.attributes?.number_of_ads_per_day}
                    </h3>
                  </div>
                </Col>
                {/* <Col md={6}>
                  <div className="b-bx">
                    <h3 className="clr-red">
                      <span>Total Price</span> $ {resultObject?.amount}
                    </h3>
                  </div>
                </Col> */}
              </Row>
            </div>
          </Col>
          <Col md={7}>
            <h3 className="title2">Package Details</h3>
            <div className="recent-transactions-inner-wrap">
              {/* {transactions.map((row, i) => {
                return ( */}
              <div className="trans-bx">
                <div className="detail package-details-wrap">
                  {/* <div>
                        {row?.attributes?.transaction_type === "debit" ? ( */}
                  <div>
                    {/* <strong>Buy a {row?.attributes?.order_type}</strong> */}
                    {/* <p>{row?.attributes?.createdAt}</p> */}
                    <p>
                      Start date :{" "}
                      {/* {
                                row?.attributes?.subscription?.data?.attributes
                                  ?.start_date
                              }{" "} */}
                      {resultObject.start_date
                        ? moment(resultObject?.start_date).format("MMM Do YY")
                        : "N/A"}
                    </p>
                    <p>
                      End date :{" "}
                      {/* {
                                row?.attributes?.subscription?.data?.attributes
                                  ?.end_date
                              } */}
                      {resultObject.end_date
                        ? moment(resultObject?.end_date).format("MMM Do YY")
                        : "N/A"}
                    </p>
                    <p>
                      Status :{" "}
                      {/* {
                                row.attributes.subscription.data.attributes
                                  .isActive
                              } */}
                      {resultObject?.isActive === true ? "Active" : "Inactive"}
                    </p>
                  </div>
                  {/* // ) : ( */}
                  <div>
                    {/* <strong>
                              {row.attributes.order_type === "package"
                                ? "Amount transaction"
                                : "Get A tip"}
                            </strong> */}
                    {/* <p>{row.attributes.createdAt}</p>
                            <p>{row.attributes.transaction_type}</p> */}
                  </div>
                  {/* )} */}
                </div>
              </div>
              <div className="right">
                {/* {row.attributes.transaction_type === "debit" ? ( */}
                <div className="price text-danger">
                  {/* - {row.attributes.transaction_amount}
                          <p>{row.attributes.transaction_type}</p> */}
                </div>
                {/* ) : ( */}
                <div className="price text-success">
                  {/* + {row.attributes.transaction_amount}
                          <p>{row.attributes.transaction_type}</p> */}
                </div>
                {/* )} */}
              </div>
            </div>
            {/* );
              })} */}
            {/* </div> */}
          </Col>
        </Row>
      </div>
    </Account>
  );
};

export default MySubscription;
