import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class LangService {

    get(payload) {
        return axios.get(HttpHelper.API_URL + '/languages', { params: payload }, HttpHelper.getHeader());
    }

}

export default new LangService();