import React from "react";
import { useParams } from "react-router-dom";

import Vendor from "./Single";
import Vendors from "./List";

export default function Eroticbody() {
    let { id } = useParams();
    if (isNaN(id)) {
        id = null;
    }

    return id ? <Vendor id={id} /> : <Vendors type="stripclub" />;
}
