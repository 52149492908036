import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';
import './responsive.css';

import { CartContextProvider } from "./context/CartContext";
import { CatagoryContextProvider } from "./context/CatagoryContext";
import { UserContextProvider } from "./context/UserContext";

import Route from './routes/Routes';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <CatagoryContextProvider>
      <UserContextProvider>
        <CartContextProvider>
          <BrowserRouter>
            <Route />
          </BrowserRouter>
        </CartContextProvider>
      </UserContextProvider>
    </CatagoryContextProvider>
  // </React.StrictMode>
);

