import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Form } from "react-bootstrap";

const AnilingusFilter = ({ handleChange, anilingus }) => {
  const [open11, setOpen11] = useState(true);
  const [isYes, setIsYes] = useState(false);
  const [isNo, setIsNo] = useState(false);
  const [isNa, setIsNa] = useState(false);
  const [anilingustype, setAnilingustype] = useState({
    Give: false,
    Receive: false,
    Both: false,
  });

  const handleClickCheckbox = (event) => {
    console.log("handleClickCheckbox");
    handleChange(event);
    const { value } = event.target;
    const prevState = { ...anilingustype, [value]: !anilingustype[value] };
    setIsYes(() => prevState.Give || prevState.Receive || prevState.Both);
    setIsNo(() => false);
    setIsNa(() => false);
    setAnilingustype(prevState);
  };

  const handleClickRadio = (event) => {
    console.log("handleClickRadio");
    handleChange(event);
    let tid = event.target.id;
    if (tid === "anilingus1") {
      setIsYes(() => true);
      setIsNo(() => false);
      setIsNa(() => false);
    }
    if (tid === "anilingus2") {
      setIsYes(() => false);
      setIsNo(() => true);
      setIsNa(() => false);
      setAnilingustype({
        Give: false,
        Receive: false,
        Both: false,
      });
    }
    if (tid === "anilingus3") {
      setIsYes(() => false);
      setIsNo(() => false);
      setIsNa(() => true);
      setAnilingustype({
        Give: false,
        Receive: false,
        Both: false,
      });
    }
  };

  return (
    <div className="category-list mb-3">
      <div
        className="expand"
        onClick={() => setOpen11(!open11)}
        aria-controls="category11"
        aria-expanded={open11}
      >
        <div className="right-arrow">
          <i className="fa fa-angle-down"></i>
        </div>
        <div>
          <h3 className="hd3">Anilingus</h3>
        </div>
      </div>
      <Collapse className="category-body" id="category11" in={open11}>
        <ul>
          <li>
            <Form.Check
              checked={isYes}
              inline
              className="cat-checkbox"
              type="radio"
              name="anilingus"
              id="anilingus1"
              label={`Yes (${anilingus[0]?.Yes})`}
              value="yes"
              onChange={handleClickRadio}
            />
            <div className="ps-3">
              <ul>
                {Object.entries({
                  Give: "Give to provider",
                  Receive: "Receive from provider",
                  // Both: "Both",
                }).map(([key, type], i) => (
                  <li key={`anilingus_act-${key}`}>
                    <Form.Check
                      inline
                      className="cat-checkbox pe-4"
                      type="checkbox"
                      name="anilingus_act"
                      id={`anilingus_act${key}`}
                      value={key}
                      label={
                        key != "Both"
                          ? `${type} (${anilingus[0]?.[key]})`
                          : `${type} (${anilingus[0]?._Both})`
                      }
                      checked={anilingustype[key]}
                      onChange={handleClickCheckbox}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </li>
          <li>
            <Form.Check
              inline
              checked={isNo}
              className="cat-checkbox"
              type="radio"
              name="anilingus"
              id="anilingus2"
              label={`No (${anilingus[0]?.No})`}
              value="no"
              onChange={handleClickRadio}
            />
          </li>
          <li>
            <Form.Check
              checked={isNa}
              inline
              className="cat-checkbox"
              type="radio"
              name="anilingus"
              id="anilingus3"
              label="N/A"
              value=""
              onChange={handleClickRadio}
            />
          </li>
        </ul>
      </Collapse>
    </div>
  );
};

export default AnilingusFilter;
