import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class LikeService {

    getAll(payload) {
        return axios.get(HttpHelper.API_URL + '/likes', { params: payload }, HttpHelper.getHeader());
    }

    getOne(id, payload) {
        return axios.get(HttpHelper.API_URL + '/likes/' + id, { params: payload }, HttpHelper.getHeader());
    }

    create(payload) {
        return axios.post(HttpHelper.API_URL + '/likes', payload, HttpHelper.getAuthHeader());
    }

    update(id, payload) {
        return axios.put(HttpHelper.API_URL + "/likes/" + id, payload, HttpHelper.getAuthHeader());
    }

    delete(id) {
        return axios.delete(HttpHelper.API_URL + "/likes/" + id, HttpHelper.getAuthHeader());
    }

}

export default LikeService = new LikeService();