import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  Modal,
  Button,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

import { UserContext } from "../../context/UserContext";
import { CartContext } from "../../context/CartContext";

import UserService from "../../services/UserService";
import TransactionService from "../../services/TransactionService";

import Disclaimer from "./Disclaimer";
import LocationComponent from "./HeaderComponents/LocationComponent";
import DropdownComponent from "./HeaderComponents/DropdownComponent";
import { FormatMoney } from "format-money-js";

export default function Header() {
  const {
    hasUserId,
    userId,
    updateUser,
    isLogin,
    isProvider,
    logout,
    totalDebit,
    setTotalDebit,
    totalCredit,
    setTotalCredit,
    totalCreditPackage,
    setTotalCreditPackage,
    setTransactions,
    totalWalletBalance,
    setTotalWalletBalance,
  } = useContext(UserContext);

  const { getTotalItemCount } = useContext(CartContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fm = new FormatMoney({
    decimals: 2,
  });


  async function getUser() {
    try {
      const response = await UserService.getOne(userId, { populate: "*" });
      if (response.status === 200) {
        // console.log("USERS>>>>S>S>S>S>S>S>S>S>S>S>S>", response.data);
        updateUser(response.data);
      } else {
        logout();
      }
    } catch (err) {
      logout();
    }
  }

  useEffect(() => {
    if (hasUserId() && isLogin()) getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const fetchTransactions = async () => {
    try {
      let walletRes = await TransactionService.getAll({
        filters: {
          $or: [
            {
              tip: {
                provider: {
                  user: {
                    id: {
                      $eq: userId,
                    },
                  },
                },
              },
            },
            {
              user: {
                id: {
                  $eq: userId,
                },
              },
            },
          ],
        },
        populate: "*",
        sort: ["id:desc"],
      });
      if (walletRes.status === 200) {
        setTransactions(walletRes.data.data);
        setTotalCreditPackage(() => 0);
        setTotalCredit(() => 0);
        setTotalDebit(() => 0);
        // eslint-disable-next-line array-callback-return
        walletRes.data.data.map((row) => {
          if (row.attributes.transaction_type === "credit") {
            setTotalCredit(
              (t) => t + parseFloat(row.attributes.transaction_amount)
            );
            if (row.attributes.order_type === "package") {
              setTotalCreditPackage(
                (t) => t + parseFloat(row.attributes.transaction_amount)
              );
            }
          }
          if (row.attributes.transaction_type === "debit") {
            setTotalDebit(
              (t) => t + parseFloat(row.attributes.transaction_amount)
            );
          }
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  useEffect(() => {
    if (show) {
      fetchTransactions();
    }
  }, [show]);

  console.log("TOTOTO>>", totalCredit, totalDebit, totalWalletBalance)

  useEffect(() => {
    setTotalWalletBalance(() =>
      parseFloat(totalCredit) - parseFloat(totalDebit) > 0
        ? parseFloat(totalCredit) - parseFloat(totalDebit)
        : 0
    );
  }, [setTotalWalletBalance, totalCredit, totalDebit]);

  return (
    <>
      <header>
        <div className="top-header">
          <Container>
            <Row className="align-items-center">
              <Col md={5}>
                <div className="left-location-language">
                  <LocationComponent />
                </div>
              </Col>
              <Col md={2}>
                <div className="tophd-text">
                  <h4>Live the Fantasy!</h4>
                </div>
              </Col>
              <Col md={5}>
                <div className="tophd-link">
                  <ul>
                    <li>
                      {isProvider() && (
                        <Link className="btn btn-warning" to="/ads/submit">
                          + Post Ad
                        </Link>
                      )}
                      {!isLogin() && (
                        <Link className="btn btn-warning" to="/ads/submit">
                          + Post Ad
                        </Link>
                      )}
                    </li>
                    <li>
                      <Link to={"/contactus"}>Feedback</Link>
                    </li>
                    <li>
                      <Link to={"/faq"}>FAQ</Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="main-menu-wrapper">
          <Container>
            <Navbar expand="lg">
              <Navbar.Brand className="col-lg-3 col-md-6">
                <a href="/">
                  <img
                    className="img-fluid"
                    src="/images/logo.png"
                    alt="logo"
                  />
                </a>
              </Navbar.Brand>

              <Navbar.Collapse
                id="navbarNav"
                className="justify-content-center col-lg-3 col-md"
              >
                <Nav>
                  <li>
                    <NavLink to="/eroticbody">Erotic Body Rubs</NavLink>
                  </li>
                  <li>
                    <NavLink to="/sextoy">Sex Toy Shops</NavLink>
                  </li>
                  <li>
                    <NavLink to="/stripclub">Strip Club Links</NavLink>
                  </li>
                  {/* <li>
                                        <NavLink to="/products">Online Store</NavLink>
                                    </li> */}
                  <li>
                    <NavLink to="/productsList">Online Store</NavLink>
                  </li>
                </Nav>
              </Navbar.Collapse>

              <div className="logreg-btn-wrap col-lg-3">
                <ul>
                  {isLogin() ? (
                    <li>
                      <DropdownComponent />
                    </li>
                  ) : (
                    <>
                      <li>
                        <Link to="/login">Login</Link>
                      </li>
                      <li>
                        <Link
                          className="btn btn-outline-warning"
                          to="/register"
                        >
                          Register
                        </Link>
                      </li>
                    </>
                  )}
                  {/* {!isVender() && !isProvider() ? ( */}
                  <li>
                    <Link to="/cart">
                      <div className="hed-cart">
                        <i
                          className="fa fa-shopping-cart"
                          aria-hidden="true"
                        ></i>
                        <span className="count">{getTotalItemCount()}</span>
                      </div>
                    </Link>
                  </li>
                  {/* // ) : null} */}
                  {isProvider() ? (
                    <li>
                      <button onClick={handleShow}>
                        <div className="hed-cart">
                          <i className="fas fa-wallet"></i>
                        </div>
                      </button>
                    </li>
                  ) : null}
                  {/* // <li>
                  //       <Link to="/" onClick={handleShow}>
                  //         <div className="hed-cart">
                  //           <i class="fa fa-wallet"></i>
                  //         </div>
                  //       </Link>
                  //     </li> */}
                </ul>
              </div>

              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="col-md-1"
              />
            </Navbar>
          </Container>
        </div>
      </header>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="lft-profile-prt">
            <div className="balence-bx">
              <h2>
                <span>Wallet Balance</span> {fm.from(totalWalletBalance)}
              </h2>
            </div>
            <Row className="mt-3">
              <Col md={6}>
                <div className="b-bx">
                  <h3>
                    <span>Total Income</span> ${" "}
                    {parseFloat(totalCredit) - parseFloat(totalCreditPackage)}
                  </h3>
                </div>
              </Col>
              <Col md={6}>
                <div className="b-bx">
                  <h3 className="clr-red">
                    <span>Total Expences</span> $ {parseFloat(totalDebit)}
                  </h3>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Disclaimer />
    </>
  );
}
