import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Package() {
  let navigate = useNavigate();

  const handleSkip = () => {
    navigate("/login");
  };

  return (
    <>
      <div className="main-wrap dashboard-prt">
        <Container>
          <div className="text-end pe-5">
            <button className="btn btn-danger btn2" onClick={handleSkip}>
              Skip
            </button>
          </div>
          <h2 className="main-title">Package</h2>

          <Row className="my-5">
            <Col md={3}>
              <div className="pricing-bx">
                <div className="pricing-hdr">
                  <h3>
                    <span>Platimun Plan</span> <sup>₹</sup> 6499
                  </h3>
                </div>
                <ul>
                  <li>Lorem ipsum dolor sit amet.</li>
                  <li>Consectetur adipisicing elit do eiu.</li>
                  <li>Smod tempor incididunt ut labore.</li>
                  <li>Dolore magna aliquat enim ad minim.</li>
                  <li>Veniam quis nostrud exercitation.</li>
                  <li>Ullamco laboris nisi ut aliquip ex ea.</li>
                  <li>Commodo consequat uis aute.</li>
                  <li>Irure dolor in reprehenderit in volup.</li>
                </ul>
                <div className="text-center">
                  <a href="#">Book now</a>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="pricing-bx sky-blue">
                <div className="pricing-hdr">
                  <h3>
                    <span>Gold Plan</span> <sup>₹</sup> 6499
                  </h3>
                  <div className="rivon">Best Value</div>
                </div>
                <ul>
                  <li>Lorem ipsum dolor sit amet.</li>
                  <li>Consectetur adipisicing elit do eiu.</li>
                  <li>Smod tempor incididunt ut labore.</li>
                  <li>Dolore magna aliquat enim ad minim.</li>
                  <li>Veniam quis nostrud exercitation.</li>
                  <li>Ullamco laboris nisi ut aliquip ex ea.</li>
                  <li>Commodo consequat uis aute.</li>
                  <li>Irure dolor in reprehenderit in volup.</li>
                </ul>
                <div className="text-center">
                  <a href="#">Book now</a>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="pricing-bx grn-blue">
                <div className="pricing-hdr">
                  <h3>
                    <span>Sliver Plan</span> <sup>₹</sup> 6499
                  </h3>
                </div>
                <ul>
                  <li>Lorem ipsum dolor sit amet.</li>
                  <li>Consectetur adipisicing elit do eiu.</li>
                  <li>Smod tempor incididunt ut labore.</li>
                  <li>Dolore magna aliquat enim ad minim.</li>
                  <li>Veniam quis nostrud exercitation.</li>
                  <li>Ullamco laboris nisi ut aliquip ex ea.</li>
                  <li>Commodo consequat uis aute.</li>
                  <li>Irure dolor in reprehenderit in volup.</li>
                </ul>
                <div className="text-center">
                  <a href="#">Book now</a>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="pricing-bx grn-yellow">
                <div className="pricing-hdr">
                  <h3>
                    <span>Bronze Plan</span> <sup>₹</sup> 6499
                  </h3>
                </div>
                <ul>
                  <li>Lorem ipsum dolor sit amet.</li>
                  <li>Consectetur adipisicing elit do eiu.</li>
                  <li>Smod tempor incididunt ut labore.</li>
                  <li>Dolore magna aliquat enim ad minim.</li>
                  <li>Veniam quis nostrud exercitation.</li>
                  <li>Ullamco laboris nisi ut aliquip ex ea.</li>
                  <li>Commodo consequat uis aute.</li>
                  <li>Irure dolor in reprehenderit in volup.</li>
                </ul>
                <div className="text-center">
                  <a href="#">Book now</a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Package;
