import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Prolist } from "../../content/Prolist";
import ProductService from "../../services/ProductService";
import { useEffect } from "react";
import HttpHelper from "../../helper/HttpHelper";

function ProductList() {
  const [productList, setProductList] = useState([]);

  const url = HttpHelper.BASE_URL;

  const getProducts = async () => {
    try {
      let qury = {};
      qury.populate = "*";
      let response = await ProductService.getAll(qury);
      if (response.status === 200) {
        // console.log("Products", response.data.data);
        setProductList(response.data.data);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  // const images = productList.attributes?.picture?.data ? productList.attributes.picture.data : [];
  // console.log("State", productList);
  useEffect(() => {
    getProducts();
  }, []);

  return (
    <>
      <div className="main-wrap dashboard-prt">
        <Container>
          <h2 className="main-title">Product List</h2>
          <Row className="mt-5">
            {productList?.map((prodlst) => {
              // console.log("pro",prodlst.attributes);
              return (
                <Col lg={3} md={6} id={prodlst?.id}>
                  <div className="product-bx">
                    <figure className="pro-img">
                      <img
                        src={
                          url +
                          prodlst?.attributes?.picture?.data?.attributes?.url
                            ? url +
                              prodlst?.attributes?.picture?.data?.attributes
                                ?.url
                            : "/images/pro1.png"
                        }
                        alt=""
                        className="img-fluid"
                      />
                      <span className="wishlist">
                        <i className="fa fa-heart"></i>
                      </span>
                      <Link
                        to={`/productdetails/${prodlst?.id}`}
                        className="btn-cart"
                      >
                        Add to Cart
                      </Link>
                    </figure>
                    <h4>
                      {prodlst.attributes.name}{" "}                      
                    </h4>
                    <h5>{prodlst.attributes.price}</h5>
                  </div>
                </Col>
              );
            })}
            {/* {Prolist.map((prodlst) => {
              return (
                <Col lg={3} md={6} id={prodlst.id}>
                  <div className="product-bx">
                    <figure className="pro-img">
                      <img src={prodlst.imgsrc} alt="" className="img-fluid" />
                      <span className="wishlist">
                        <i className="fa fa-heart"></i>
                      </span>
                      <Link to="/productdetails" className="btn-cart">
                        Add to Cart
                      </Link>
                    </figure>
                    <h4>
                      {prodlst.proname} <span>{prodlst.price}</span>
                    </h4>
                  </div>
                </Col>
              );
            })} */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ProductList;
