import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class CityService {
    
    getAll(payload) {
        return axios.get(HttpHelper.API_URL + '/cities', { params: payload }, HttpHelper.getAuthHeader());
    }

    getAllByState(payload) {
         return axios.get(HttpHelper.API_URL + '/cityByState', { params: payload }, HttpHelper.getHeader());
     }

    getOne(id, payload) {
        return axios.get(HttpHelper.API_URL + '/cities/' + id, { params: payload }, HttpHelper.getAuthHeader());
    }

    create(payload) {
        return axios.post(HttpHelper.API_URL + '/cities', payload, HttpHelper.getAuthHeader());
    }

    update(id, payload) {
        return axios.put(HttpHelper.API_URL + "/cities/" + id, payload, HttpHelper.getAuthHeader());
    }

    delete(id) {
        return axios.delete(HttpHelper.API_URL + "/cities/" + id, HttpHelper.getAuthHeader());
    }

}

export default CityService = new CityService();