import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Form } from "react-bootstrap";

const VaginalFilter = ({ handleChange, vaginal }) => {
    const [open12, setOpen12] = useState(true);
    const [isYes, setIsYes] = useState(false);
    const [isNo, setIsNo] = useState(false);
    const [isNa, setIsNa] = useState(false);
    const [vaginaltype, setVaginaltype] = useState({
        CFS: false,
        BFS: false,
    });

    const handleClickCheckbox = (event) => {
        console.log("handleClickCheckbox");
        handleChange(event);
        const { value } = event.target;
        const prevState = { ...vaginaltype, [value]: !vaginaltype[value] };
        setIsYes(() => prevState.CFS || prevState.BFS);
        setIsNo(() => false);
        setIsNa(() => false);
        setVaginaltype(prevState);
    };

    const handleClickRadio = (event) => {
        console.log("handleClickRadio");
        handleChange(event);
        let tid = event.target.id;
        if (tid === "vaginal1") {
            setIsYes(() => true);
            setIsNo(() => false);
            setIsNa(() => false);
        }
        if (tid === "vaginal2") {
            setIsYes(() => false);
            setIsNo(() => true);
            setIsNa(() => false);
            setVaginaltype({
                CFS: false,
                BFS: false,
            });
        }
        if (tid === "vaginal3") {
            setIsYes(() => false);
            setIsNo(() => false);
            setIsNa(() => true);
            setVaginaltype({
                CFS: false,
                BFS: false,
            });
        }
    };

    return (
        <div className="category-list mb-3">
            <div
                className="expand"
                onClick={() => setOpen12(!open12)}
                aria-controls="category12"
                aria-expanded={open12}
            >
                <div className="right-arrow">
                    <i className="fa fa-angle-down"></i>
                </div>
                <div>
                    <h3 className="hd3">Vaginal</h3>
                </div>
            </div>
            <Collapse className="category-body" id="category12" in={open12}>
                <ul>
                    <li>
                        <Form.Check
                            checked={isYes}
                            inline
                            className="cat-checkbox"
                            type="radio"
                            name="vaginal"
                            id="vaginal1"
                            label={`Yes (${vaginal[0]?.Yes})`}
                            value="yes"
                            onChange={handleClickRadio}
                        />
                        <div className="ps-3">
                            <ul>
                                {Object.entries({
                                    CFS: "CFS",
                                    BFS: "BFS",
                                }).map(([key, type], i) => (
                                    <li key={`vaginal_type-${key}`}>
                                        <Form.Check
                                            inline
                                            className="cat-checkbox"
                                            type="checkbox"
                                            name="vaginal_type"
                                            id={`vaginal_type${key}`}
                                            value={key}
                                            label={`${type} (${vaginal[0]?.[key]})`}
                                            checked={vaginaltype[key]}
                                            onChange={handleClickCheckbox}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Form.Check
                            inline
                            checked={isNo}
                            className="cat-checkbox"
                            type="radio"
                            name="vaginal"
                            id="vaginal2"
                            label={`No (${vaginal[0]?.No})`}
                            value="no"
                            onChange={handleClickRadio}
                        />
                    </li>
                    <li>
                        <Form.Check
                            inline
                            checked={isNa}
                            className="cat-checkbox"
                            type="radio"
                            name="vaginal"
                            id="vaginal3"
                            label="N/A"
                            value=""
                            onChange={handleClickRadio}
                        />
                    </li>
                </ul>
            </Collapse>
        </div>
    );
};

export default VaginalFilter;
