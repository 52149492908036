import React from "react";
import { useState, useEffect, useContext, useCallback } from "react";
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import Slider from "react-slick";

import { UserContext } from "../../../context/UserContext";
import ProviderService from "../../../services/ProviderService";
import RaceService from "../../../services/RaceService";
import LangService from "../../../services/LangService";
import StatesService from "../../../services/StatesService";
import CityService from "../../../services/CityService";

import { showLang, showRace } from "../../../helper/UtilityHelper";
import { Toast } from "../../layout/Alert";
import UploadService from "../../../services/UploadService";
import { Link, useNavigate } from "react-router-dom";
import SubscriptionServices from "../../../services/SubscriptionServices";

const slider1 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export default function Create() {
  const { userId, user, isSubscribed } = useContext(UserContext);
  const navigate = useNavigate();
  const [selectedImage, setselectedImage] = useState([]);
  const [imgPath, setImgPath] = useState([
    "/images/no-photo-small.svg",
    "/images/no-photo-small.svg",
    "/images/no-photo-small.svg",
  ]);
  const onFileChangeImages = (event) => {
    let input = event.target;
    if (input.files.length > 0) {
      console.log("file upload ", input.files);
      setselectedImage([]);
      setImgPath([]);
      Array.from(input.files).map((data) => {
        var reader = new FileReader();
        reader.onload = function (e) {
          //setImgPath([reader.result]);
          setImgPath((d) => [...d, reader.result]);
          setselectedImage((c) => [...c, data]);
        };
        reader.readAsDataURL(data);
        console.log(data);
      });
    }
    // if (input.files && input.files[0]) {

    // }
  };

  const [videoSrc, seVideoSrc] = useState("");
  const [selectedVideo, setselectedVideo] = useState(false);
  const [cimButton, setCimButton] = useState(false);
  const [kissingButton, setKissingButton] = useState(false);
  const [allowCreatePackage, setAllowCreatePackage] = useState(false);
  // const [swallowButton, setSwallowButton] = useState(false);
  // const [droolButton, setDroolButton] = useState(false);
  // const [spitButton, setSpitButton] = useState(false);
  // const [isYes, setIsYes] = useState(false);
  const onFileChangeVideo = (event) => {
    setselectedVideo(event.target.files[0]);
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    seVideoSrc(url);
  };

  const [selectedDocs, setSelectedDocs] = useState([]);
  const [docPath, setDocPath] = useState([
    "/images/no-photo-small.svg",
    "/images/no-photo-small.svg",
    "/images/no-photo-small.svg",
  ]);

  const onFileChangeDocs = (event) => {
    let input = event.target;
    if (input.files.length > 0) {
      console.log("docs upload ", input.files);
      setSelectedDocs([]);
      setDocPath([]);
      Array.from(input.files).map((data) => {
        var reader = new FileReader();
        reader.onload = function (e) {
          //setDocPath([reader.result]);
          setDocPath((d) => [...d, reader.result]);
          setSelectedDocs((c) => [...c, data]);
        };
        reader.readAsDataURL(data);
        console.log(data);
      });
    }
  };

  const [inputs, setInputs] = useState({
    name: "",
    language: 1,
    country: "",
    race: 1,
    state: "",
    age: "18",
    city: "",
    call: "Incall",
    phone: "",
    title: "",
    massage: "no",
    threesome: "no",
    donation_hour: "",
    donation_half_hour: "",
    kissing: "no",
    kissing_types: [],
    fellatio: "no",
    fellatio_type: [],
    cunnilingus: "no",
    anilingus: "no",
    anilingus_act: [],
    vaginal: "no",
    vaginal_type: [],
    anal: "no",
    anal_activity: [],
    cum: "no",
    cum_type: [],
    personality: [],
    grooming: [],
    services_provided_approval: true,
    message: "",
    duration_ad: 2,
    duration_ad_unit: "Days",
    ad_bonus_side_panel_ad: false,
    discount_code: "",
    isShowed: true,
  });

  function handleChange(event) {
    // console.log("Event>>>>",event);
    const target = event.target;
    const name = target.name;
    // console.log("Name>>>>",name);

    if (target.type === "checkbox") {
      let copy = { ...inputs };
      if (
        name === "ad_bonus_side_panel_ad" ||
        name === "services_provided_approval"
      ) {
        copy[name] = target.checked ? true : false;
      } else {
        if (target.checked) {
          copy[name].push(target.value);
          console.log("this is log ==>", name);
          if (name === "kissing_types") {
            // console.log("kisin>>", name);
            copy["kissing"] = "yes";
            //  setInputs((values) => ({ ...values, 'kissing': 'yes' }));
            setKissingButton(() => true);
          }
          if (name === "fellatio_type") {
            copy["fellatio"] = "yes";
          }
          if (name === "anilingus_act") {
            copy["anilingus"] = "yes";
          }
          if (name === "vaginal_type") {
            copy["vaginal"] = "yes";
          }
          if (name === "anal_activity") {
            copy["anal"] = "yes";
          }
          if (name === "cum_type") {
            setCimButton(() => true);
          }
        } else {
          copy[name] = copy[name].filter((el) => el !== target.value);
          if (name === "kissing_types") {
            // console.log("kisin>>>>>>", name);
            setKissingButton(() => false);
          }
          if (name === "cum_type") {
            setCimButton(() => false);
          }
        }
      }
      setInputs(copy);
    } else if (target.type === "radio") {
      let value = target.value;
      setInputs((values) => ({ ...values, [name]: value }));

      if (name === "kissing" && value != "yes") {
        setInputs((values) => ({ ...values, kissing_types: [] }));
        // if (target.checked) {
        //   setKissingButton(() => true);
        // } else {
        //   setKissingButton(() => false);
        // }
        setKissingButton((v) => false);
      }
      if (name === "kissing" && value == "yes") {
        setKissingButton((v) => true);
      }
      if (name === "kissing_types") {
        setKissingButton((v) => true);
      }
      if (name === "fellatio" && value != "yes") {
        setInputs((values) => ({ ...values, fellatio_type: [] }));
      }
      if (name === "anilingus" && value != "yes") {
        setInputs((values) => ({ ...values, anilingus_act: [] }));
      }
      if (name === "vaginal" && value != "yes") {
        setInputs((values) => ({ ...values, vaginal_type: [] }));
      }
      if (name === "anal" && value != "yes") {
        setInputs((values) => ({ ...values, anal_activity: [] }));
      }

      if (name === "cum") {
        if (target.checked) {
          setCimButton(() => true);
        } else {
          setCimButton(() => false);
        }
        if (value === "N/A") {
          setCimButton(() => false);
        }
      }
    } else {
      setInputs((values) => ({ ...values, [name]: target.value }));
    }
    console.log("-================================>", inputs);
  }
  const [activePackageDetails, setActivePackageDetails] = useState({});
  // const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  async function handleSubmit(event) {
    event.preventDefault();
    console.log("status", isSubscribed);
    // return false
    if (!isSubscribed === true) {
      Toast.fire({
        icon: "warning",
        title: "Need to buy a package to post ads",
      });
      navigate("/ads/packages/");
      return false;
    }
    var data = inputs;
    if (!cityList.length) {
      data["city"] = "";
    }
    data["anal_activity"] = data["anal_activity"].toString();
    data["anilingus_act"] = data["anilingus_act"].toString();
    data["cum_type"] = data["cum_type"].toString();
    data["fellatio_type"] = data["fellatio_type"].toString();
    data["grooming"] = data["grooming"].toString();
    data["kissing_types"] = data["kissing_types"].toString();
    data["personality"] = data["personality"].toString();
    data["vaginal_type"] = data["vaginal_type"].toString();
    data["user"] = userId;
    console.log(data);
    // return false
    try {
      if (selectedImage.length > 0) {
        setIsLoading(true);
        var formData = new FormData();
        console.log("file upload ", selectedImage);
        Array.from(selectedImage).map((data, i) => {
          formData.append("files", data);
        });
        let responsei = await UploadService.upload(formData);
        if (responsei.status === 200) {
          if (responsei.data.length > 0) {
            data["images"] = responsei.data.map((res) => res.id);

            if (selectedVideo) {
              var formData2 = new FormData();
              formData2.append("files", selectedVideo);
              let responsev = await UploadService.upload(formData2);
              if (responsev.status === 200) {
                responsev.data.map((res) => {
                  data["video"] = res.id;
                });
              }
            }

            if (selectedDocs) {
              var formDataDocs = new FormData();
              Array.from(selectedDocs).map((data, i) => {
                formDataDocs.append("files", data);
              });
              console.log("Docs Selected", selectedDocs);
              let responseDocs = await UploadService.upload(formDataDocs);
              if (responseDocs.status === 200) {
                data["docs"] = responseDocs.data.map((res) => res.id);
              }
            }

            // console.log(data);
            let responsep = await ProviderService.create({ data });
            if (responsep.status === 200) {
              setIsLoading(false);
              Toast.fire({
                icon: "success",
                title: "Your Ad are successfully created",
              });
              navigate("/");
            } else {
              Toast.fire({
                icon: "warning",
                title: "Something went wrong. try again later",
              });
            }
          }
        }
      } else {
        Toast.fire({
          icon: "warning",
          title: "Please select a image",
        });
      }
    } catch (error) {
      setIsLoading(false);
      let message = error.message;
      if (error.code === "ERR_BAD_REQUEST") {
        message = error.response.data.error.message;
      }
      Toast.fire({ icon: "error", title: message });
    }
  }

  const [races, setraces] = useState([]);
  const [lang, setlang] = useState([]);
  async function getData() {
    try {
      console.log("getData");
      let response = await RaceService.get({ populate: "*" });
      if (response.status === 200) {
        setraces(response.data.data);
      }
      response = await LangService.get({ populate: "*" });
      if (response.status === 200) {
        setlang(response.data.data);
      }
    } catch (error) {}
  }

  useEffect(() => {
    getData();
  }, []);

  async function checkAvailablePackages() {
    try {
      if (user?.subscriptions) {
        if (userId && !allowCreatePackage) {
          console.log("checkAvailablePackages");
          // console.log("AlLOW>>>", allowCreatePackage, userId);
          const responseProvider = await ProviderService.getAll({
            filters: {
              user: {
                id: {
                  $eq: userId,
                },
              },
            },
            populate: "*",
            // sort: ["id:desc"],
          });
          let providerCounter = 0;
          if (responseProvider.status === 200) {
            if (responseProvider.data.data) {
              providerCounter = responseProvider?.data?.data?.length;
            }
          }
          console.log("AlLOW>>>", user?.subscriptions);
          const activeSubscriptions = user?.subscriptions?.filter(
            (subscription) => {
              return subscription?.isActive === true;
            }
          );
          let activePackageCounter = 0;

          if (activeSubscriptions.length) {
            let packageRes = await SubscriptionServices.getOne(
              activeSubscriptions[0].id,
              {
                populate: ["package"],
              }
            );
            if (packageRes.status === 200) {
              // console.log("Res", packageRes?.data?.data?.attributes?.package?.data);
              activePackageCounter =
                packageRes?.data?.data?.attributes?.package?.data.attributes
                  .number_of_ads_per_day;
            }
          }
          console.log(activePackageCounter, providerCounter);
          if (activePackageCounter > providerCounter) {
            setAllowCreatePackage(true);
          }
        }
      }
    } catch (error) {}
  }

  useEffect(() => {
    checkAvailablePackages();
    // console.log("Allow>>", allowCreatePackage);
  }, [allowCreatePackage, userId]);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const getState = useCallback(async () => {
    try {
      const response = await StatesService.getAll({
        filters: {
          country_code: {
            $eq: inputs.country,
          },
        },
        fields: ["state_name", "state_code"],
        pagination: { pageSize: 100 },
        sort: ["state_name:asc"],
      });
      if (response.status === 200) {
        setStateList(response.data.data);
      } else {
        setStateList([]);
      }
    } catch (err) {
      console.log(err);
    }
  }, [inputs.country]);

  const getCity = useCallback(async () => {
    try {
      const response = await CityService.getAll({
        filters: {
          state_code: {
            $eq: inputs.state,
          },
        },
        fields: ["city_name", "state_code", "state_name"],
        pagination: { pageSize: 1500 },
        sort: ["city_name:asc"],
      });
      if (response.status === 200) {
        setCityList(response.data.data);
      } else {
        setCityList([]);
      }
    } catch (err) {
      console.log(err);
    }
  }, [inputs.state]);

  useEffect(() => {
    if (inputs.country) {
      getState();
    }
  }, [getState, inputs.country]);

  useEffect(() => {
    if (inputs.state) {
      getCity();
    }
  }, [getCity, inputs.state]);

  // console.log("Cum>>>>>", cimButton);
  console.log("kissing>>>>>", kissingButton);

  if (!allowCreatePackage || isSubscribed === false) {
    return (
      <span className="click-package">
        <Link to="/ads/packages">Click here to buy package</Link>
      </span>
    );
  }

  return (
    <>
      <Row className="mt-5">
        <Col md={6}>
          <div className="provider-form">
            <Form className="row" onSubmit={handleSubmit}>
              <Form.Group controlId="formFile" className="col-md-6 mb-3">
                <Form.Label>Upload Images</Form.Label>
                <Form.Control
                  type="file"
                  name="images"
                  accept="image/*"
                  onChange={onFileChangeImages}
                  multiple
                />
              </Form.Group>
              <Form.Group controlId="formFile" className="col-md-6 mb-3">
                <Form.Label>Upload Provider's Id</Form.Label>
                <Form.Control
                  type="file"
                  name="docs"
                  // accept="image/*"
                  accept="*"
                  onChange={onFileChangeDocs}
                  multiple
                  required
                />
              </Form.Group>
              <span style={{ color: "red", marginLeft: "27rem" }}>
                *Provider's Id is mandatory
              </span>
              <span style={{ color: "red", marginTop: "-25px" }}>
                Recommended Size: 1080x920
              </span>
              <Form.Group controlId="formFile" className="col-md-6 mb-3">
                <Form.Label>Upload Video (30 Sec.)</Form.Label>
                <Form.Control
                  type="file"
                  name="video"
                  accept="video/*"
                  onChange={onFileChangeVideo}
                />
              </Form.Group>

              <hr />

              <Form.Group className="col-md-6 mb-3">
                <Form.Label>Stage Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleChange}
                  value={inputs.name}
                />
              </Form.Group>

              <Form.Group className="col-md-6 mb-3">
                <Form.Label>Language</Form.Label>
                {lang.length ? (
                  <Form.Select
                    aria-label="Default select example"
                    name="language"
                    onChange={handleChange}
                  >
                    {lang.map((row) => (
                      <option key={row.id} value={row.id}>
                        {row.attributes.lang_name}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  <Form.Select aria-label="Default select example">
                    <option value=""></option>
                  </Form.Select>
                )}
              </Form.Group>

              <Form.Group className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  {/* <Form.Control
                                        type="text"
                                        name="country"
                                        onChange={handleChange}
                                        value={inputs.country}
                                    /> */}
                  <Form.Select
                    aria-label="Default select example"
                    name="country"
                    onChange={handleChange}
                    value={inputs.country}
                  >
                    <option value=""></option>
                    <option value="US">United States</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Region/State</Form.Label>
                  {/* <Form.Control
                                        type="text"
                                        name="state"
                                        onChange={handleChange}
                                        value={inputs.state}
                                    /> */}
                  {stateList.length ? (
                    <Form.Select
                      aria-label="Default select example"
                      name="state"
                      onChange={handleChange}
                      value={inputs.state}
                    >
                      <option value=""></option>
                      {stateList.map((row, i) => {
                        return (
                          <option key={i} value={row.attributes.state_code}>
                            {row.attributes.state_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  ) : (
                    <Form.Select aria-label="Default select example" disabled>
                      <option value=""></option>
                    </Form.Select>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  {/* <Form.Control
                                        type="text"
                                        name="city"
                                        onChange={handleChange}
                                        value={inputs.city}
                                    /> */}
                  {cityList.length ? (
                    <Form.Select
                      aria-label="Default select example"
                      name="city"
                      onChange={handleChange}
                      value={inputs.city}
                    >
                      <option value=""></option>
                      {cityList.map((row, i) => {
                        return (
                          <option key={i} value={row.attributes.city_name}>
                            {row.attributes.city_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  ) : (
                    <Form.Select aria-label="Default select example" disabled>
                      <option value=""></option>
                    </Form.Select>
                  )}
                </Form.Group>
              </Form.Group>

              <Form.Group className="col-md-6">
                {races.length ? (
                  <Form.Group className="mb-3">
                    <Form.Label>Race</Form.Label>

                    <Form.Select
                      aria-label="Default select example"
                      name="race"
                      onChange={handleChange}
                    >
                      {races.map((row) => (
                        <option key={row.id} value={row.id}>
                          {row.attributes.race_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3">
                    <Form.Label>Race</Form.Label>

                    <Form.Select aria-label="Default select example">
                      <option value=""></option>
                    </Form.Select>
                  </Form.Group>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>Age:</Form.Label>
                  <Form.Control
                    type="number"
                    name="age"
                    min={18}
                    onChange={handleChange}
                    value={inputs.age}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Call:</Form.Label>
                  <div className="mb-3">
                    <Form.Select
                      name="call"
                      aria-label="Default select example"
                      onChange={handleChange}
                      value={inputs.call}
                    >
                      <option value="Incall">Incall </option>
                      <option value="Outcall">Outcall </option>
                      {/* <option value="Outcall/Incall">Both</option> */}
                    </Form.Select>
                  </div>
                </Form.Group>
              </Form.Group>

              <Form.Group className="col-md-6 mb-3">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  onChange={handleChange}
                  value={inputs.phone}
                />
              </Form.Group>

              <hr />

              <Form.Group className="col-md-12 mb-3">
                <Form.Label>Title:</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  onChange={handleChange}
                  value={inputs.title}
                />
              </Form.Group>

              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Donation:</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="$"
                    type="number"
                    min={0}
                    value={inputs.donation_half_hour}
                    aria-describedby="basic-addon2"
                    name="donation_half_hour"
                    onChange={handleChange}
                  />
                  <InputGroup.Text id="basic-addon2">
                    / Half Hour
                  </InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="$"
                    type="number"
                    min={0}
                    value={inputs.donation_hour}
                    aria-describedby="basic-addon3"
                    name="donation_hour"
                    onChange={handleChange}
                  />
                  <InputGroup.Text id="basic-addon3">/ Hour</InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Massage:</Form.Label>
                <div className="mb-3">
                  <Form.Check
                    type="radio"
                    label="Yes"
                    value="yes"
                    onChange={handleChange}
                    name="massage"
                    id="massage1"
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    value="no"
                    onChange={handleChange}
                    name="massage"
                    id="massage2"
                  />
                </div>
              </Form.Group>

              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Threesome:</Form.Label>
                <div className="mb-3">
                  <Form.Check
                    type="radio"
                    label="Yes"
                    value="yes"
                    onChange={handleChange}
                    name="threesome"
                    id="threesome1"
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    value="no"
                    onChange={handleChange}
                    name="threesome"
                    id="threesome2"
                  />
                </div>
              </Form.Group>
              <hr />

              <Form.Group className="col-md-6 mb-3 d-flex">
                <Form.Label className="me-4">Kissing:</Form.Label>
                <div className="category-body">
                  <ul>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          className=""
                          type="radio"
                          label="Yes"
                          name="kissing"
                          id="kissing1"
                          value="yes"
                          // checked={inputs.kissing === "yes"}
                          checked={kissingButton}
                          onChange={handleChange}
                        />
                      </Form.Check>

                      <div className="ps-3">
                        <ul>
                          {["Light", "Moderate", "Heavy"].map((type) => (
                            <li key={`kissing_types-${type}`}>
                              <Form.Check className="cat-checkbox">
                                <Form.Check
                                  type="radio"
                                  name="kissing_types"
                                  id={`kissing_types${type}`}
                                  value={type}
                                  label={`${type}`}
                                  checked={
                                    inputs.kissing_types.indexOf(type) > -1
                                  }
                                  onChange={handleChange}
                                />
                              </Form.Check>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          className="mt-3"
                          type="radio"
                          label="No"
                          name="kissing"
                          id="kissing2"
                          value="no"
                          // checked={inputs.kissing === "no"}
                          checked={!kissingButton}
                          onChange={handleChange}
                        />
                      </Form.Check>
                    </li>
                  </ul>
                </div>
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 d-flex">
                <Form.Label className="me-4">Fellatio:</Form.Label>
                <div className="category-body">
                  <ul>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          className=""
                          type="radio"
                          label="Yes"
                          name="fellatio"
                          id="fellatio1"
                          value="yes"
                          checked={inputs.fellatio === "yes"}
                          onChange={handleChange}
                        />
                      </Form.Check>

                      <div className="ps-3">
                        <ul>
                          {Object.entries({
                            CBJ: "CBJ",
                            BBJ: "BBJ",
                          }).map(([key, type], i) => (
                            <li key={`default-${key}`}>
                              <Form.Check className="cat-checkbox">
                                <Form.Check
                                  type="checkbox"
                                  name="fellatio_type"
                                  id={`fellatio_type${key}`}
                                  value={key}
                                  label={`${type}`}
                                  checked={
                                    inputs.fellatio_type.indexOf(key) > -1
                                  }
                                  onChange={handleChange}
                                />
                              </Form.Check>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          className="mt-3"
                          type="radio"
                          label="No"
                          name="fellatio"
                          id="fellatio2"
                          value="no"
                          checked={inputs.fellatio === "no"}
                          onChange={handleChange}
                        />
                      </Form.Check>
                    </li>
                  </ul>
                </div>
              </Form.Group>
              <hr />

              <Form.Group className="col-md-6 mb-3 d-flex">
                <Form.Label>Cunnilingus:</Form.Label>
                <div className="mb-3 ms-3">
                  <Form.Check
                    label="Yes"
                    onChange={handleChange}
                    name="cunnilingus"
                    value="yes"
                    id="cunnilingus1"
                    type="radio"
                  />
                  <Form.Check
                    label="No"
                    onChange={handleChange}
                    name="cunnilingus"
                    value="no"
                    id="cunnilingus2"
                    type="radio"
                  />
                </div>
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 d-flex">
                <Form.Label className="me-4">Anilingus:</Form.Label>
                <div className="category-body">
                  <ul>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          type="radio"
                          label="Yes"
                          name="anilingus"
                          id="anilingus1"
                          value="yes"
                          checked={inputs.anilingus === "yes"}
                          onChange={handleChange}
                        />
                      </Form.Check>

                      <div className="ps-3">
                        <ul>
                          {[
                            "Give",
                            "Receive",
                            // "Both",
                          ].map((type) => (
                            <li key={`default-${type}`}>
                              <Form.Check className="cat-checkbox">
                                <Form.Check
                                  type="checkbox"
                                  name="anilingus_act"
                                  id={`anilingus_act_${type}`}
                                  value={type}
                                  label={`${type}`}
                                  checked={
                                    inputs.anilingus_act.indexOf(type) > -1
                                  }
                                  onChange={handleChange}
                                />
                              </Form.Check>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          type="radio"
                          label="No"
                          name="anilingus"
                          id="anilingus2"
                          value="no"
                          checked={inputs.anilingus === "no"}
                          onChange={handleChange}
                        />
                      </Form.Check>
                    </li>
                  </ul>
                </div>
              </Form.Group>
              <hr />

              <Form.Group className="col-md-6 mb-3 d-flex">
                <Form.Label className="me-4">Vaginal:</Form.Label>
                <div className="category-body">
                  <ul>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          type="radio"
                          label="Yes"
                          id="vaginal1"
                          name="vaginal"
                          value="yes"
                          checked={inputs.vaginal === "yes"}
                          onChange={handleChange}
                        />
                      </Form.Check>

                      <div className="ps-3">
                        <ul>
                          {Object.entries({
                            CFS: "CFS",
                            BFS: "BFS",
                          }).map(([key, type], i) => (
                            <li key={`default-${key}`}>
                              <Form.Check className="cat-checkbox">
                                <Form.Check
                                  type="checkbox"
                                  name="vaginal_type"
                                  id={`vaginal_type${key}`}
                                  value={key}
                                  label={`${type}`}
                                  checked={
                                    inputs.vaginal_type.indexOf(key) > -1
                                  }
                                  onChange={handleChange}
                                />
                              </Form.Check>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          type="radio"
                          label="No"
                          id="vaginal2"
                          name="vaginal"
                          value="no"
                          checked={inputs.vaginal === "no"}
                          onChange={handleChange}
                        />
                      </Form.Check>
                    </li>
                  </ul>
                </div>
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 d-flex">
                <Form.Label className="me-4">Anal:</Form.Label>
                <div className="category-body">
                  <ul>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          type="radio"
                          label="Yes"
                          name="anal"
                          id="anal1"
                          value="yes"
                          checked={inputs.anal === "yes"}
                          onChange={handleChange}
                        />
                      </Form.Check>

                      <div className="ps-3">
                        <ul>
                          {Object.entries({
                            CAS: "CAS",
                            BAS: "BAS",
                          }).map(([key, type], i) => (
                            <li key={`default-${key}`}>
                              <Form.Check className="cat-checkbox">
                                <Form.Check
                                  type="checkbox"
                                  name="anal_activity"
                                  id={`anal_activity${key}`}
                                  value={key}
                                  label={`${type}`}
                                  checked={
                                    inputs.anal_activity.indexOf(key) > -1
                                  }
                                  onChange={handleChange}
                                />
                              </Form.Check>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          type="radio"
                          label="No"
                          name="anal"
                          id="anal2"
                          value="no"
                          checked={inputs.anal === "no"}
                          onChange={handleChange}
                        />
                      </Form.Check>
                    </li>
                  </ul>
                </div>
              </Form.Group>
              <hr />

              <Form.Group className="col-md-12 mb-3 d-flex">
                <Form.Label className="me-4">Cum:</Form.Label>
                <div className="category-body">
                  <ul className="row">
                    <div className="col-md-6">
                      <li>
                        <Form.Check className="cat-checkbox">
                          <Form.Check
                            type="radio"
                            label="CIM: Cum In Mouth"
                            id="cum0"
                            name="cum"
                            value="CIM"
                            checked={cimButton}
                            onChange={handleChange}
                          />
                        </Form.Check>
                        <div className="ps-3">
                          <ul>
                            {["Swallow", "Drool", "Spit"].map((type) => (
                              <li key={`default-${type}`}>
                                <Form.Check className="cat-checkbox">
                                  <Form.Check
                                    type="checkbox"
                                    name="cum_type"
                                    id={`cum_type_${type}`}
                                    value={type}
                                    label={`${type} (CIM)`}
                                    onChange={handleChange}
                                  />
                                </Form.Check>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    </div>

                    <div className="col-md-6">
                      {Object.entries({
                        CIP: "CIP: Cum In Pussy",
                        COF: "COF: Cum On Face",
                        COT: "COT: Cum On Tits",
                        COB: "COB: Cum On Body",
                        COA: "COA: Cum On Ass",
                        CIA: "CIA: Cum In Ass",
                      }).map(([key, type], i) => (
                        <li key={`default-${key}`}>
                          <Form.Check className="cat-checkbox">
                            <Form.Check
                              type="checkbox"
                              name="cum_type"
                              id={`cum_type_${key}`}
                              value={key}
                              label={type}
                              onChange={handleChange}
                            />
                          </Form.Check>
                        </li>
                      ))}
                    </div>
                    <hr />

                    <li className="col-md-6 ps-3">
                      {/* <Form.Check className="cat-checkbox">
                        <Form.Check
                          inline
                          name="cum"
                          id="cum1"
                          type="radio"
                          aria-label="cum"
                          value="Anywhere"
                          label="Anywhere"
                          onChange={handleChange}
                        />
                      </Form.Check> */}
                    </li>
                    <li className="col-md-6 ps-x">
                      <Form.Check className="cat-checkbox">
                        <Form.Check
                          inline
                          name="cum"
                          id="cum2"
                          type="radio"
                          aria-label="na"
                          value="N/A"
                          label="N/A"
                          onChange={handleChange}
                        />
                      </Form.Check>
                    </li>
                  </ul>
                </div>
              </Form.Group>
              <hr />

              <Form.Group className="col-md-12 mb-3 d-flex">
                <Form.Label>Personality:</Form.Label>
                <div className="col-sm-8">
                  <Form.Check
                    inline
                    name="personality"
                    id="personality1"
                    className="ms-3"
                    label="Submissive"
                    value="Submissive"
                    type="radio"
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    name="personality"
                    id="personality2"
                    className="ms-3"
                    label="Confident"
                    value="Confident"
                    type="radio"
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    name="personality"
                    id="personality3"
                    className="ms-3"
                    label="Dominant"
                    value="Dominant"
                    type="radio"
                    onChange={handleChange}
                  />
                </div>
              </Form.Group>

              <Form.Group className="col-md-12 mb-3 d-flex">
                <Form.Label>Grooming:</Form.Label>
                <div className="col-sm-8">
                  <Form.Check
                    inline
                    name="grooming"
                    id="grooming1"
                    className="ms-3"
                    label="Natural"
                    value="Natural"
                    type="radio"
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    name="grooming"
                    id="grooming2"
                    className="ms-3"
                    label="Trimmed"
                    value="Trimmed"
                    type="radio"
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    name="grooming"
                    id="grooming3"
                    className="ms-3"
                    label="Bare"
                    value="Bare"
                    type="radio"
                    onChange={handleChange}
                  />
                </div>
              </Form.Group>
              <hr />

              <Form.Group className="col-md-12 mb-3">
                <Form.Check
                  inline
                  checked={inputs.services_provided_approval ? true : false}
                  name="services_provided_approval"
                  label="Other Services Provided Upon Request/Approval"
                  type="checkbox"
                  onChange={handleChange}
                />
                {inputs.services_provided_approval && (
                  <Form.Group className="col-md-12 mt-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="message"
                      type="text"
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
              </Form.Group>
              <hr />

              {/* <Form.Group className="col-md-12 mb-3 d-flex align-items-center justify-content-between">
                <Form.Label>Duration of Ad:</Form.Label>
                <Form.Control
                  name="duration_ad"
                  type="number"
                  className="mx-4"
                  onChange={handleChange}
                />
                <Form.Select
                  name="duration_ad_unit"
                  aria-label="Default select example"
                  onChange={handleChange}
                >
                  <option value="Days">Days</option>
                  <option value="Weeks">Weeks</option>
                  <option value="Months">Months</option>
                  <option value="Years">Years</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="col-md-6 mt-3 d-flex">
                <Form.Check
                  inline
                  name="ad_bonus_side_panel_ad"
                  id="ad_bonus_side_panel_ad"
                  label="Ad bonus side panel mini-ad"
                  type="checkbox"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 d-flex">
                <Form.Label>Discount Code</Form.Label>
                <Form.Control
                  name="discount_code"
                  type="text"
                  onChange={handleChange}
                />
              </Form.Group> */}

              <Form.Group className="col-md-12 mb-3 d-flex">
                <Button
                  size="lg"
                  type="submit"
                  disabled={isLoading}
                  className="btn btn-warning"
                >
                  {isLoading ? (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Col>

        <Col md={6}>
          <div className="mid-bx mb-4">
            <div className="d-flex justify-content-between">
              <div className="lft-img-prt">
                <Slider {...slider1}>
                  {imgPath.map((row, i) => {
                    return (
                      <div key={i}>
                        <img
                          className="img-fluid"
                          src={row}
                          alt={row}
                          key={i}
                        />
                      </div>
                    );
                  })}
                  {/* <div>
                                        <img
                                            className="img-fluid"
                                            src="/images/shadow1.png"
                                            alt=""
                                        />
                                    </div>
                                    <div>
                                        <img
                                            className="img-fluid"
                                            src="/images/shadow2.png"
                                            alt=""
                                        />
                                    </div>
                                    <div>
                                        <img
                                            className="img-fluid"
                                            src="/images/shadow3.png"
                                            alt=""
                                        />
                                    </div>
                                    <div>
                                        <img
                                            className="img-fluid"
                                            src="/images/shadow4.png"
                                            alt=""
                                        />
                                    </div> */}
                </Slider>
              </div>

              <div className="rt-content-prt create-ads-content-prt">
                <div className="hdr-prt">
                  <h3>{inputs.title}</h3>
                </div>

                <div className="p-2">
                  <Row>
                    <Col md={6}>
                      <ul className="info-lst">
                        <li>
                          <span className="name">Name:</span>
                          <span className="info">{inputs.name}</span>
                        </li>
                        <li>
                          <span className="name">Location:</span>
                          <span className="info">
                            {cityList.length ? [inputs.city] : null}
                          </span>
                        </li>
                        <li>
                          <span className="name">Language:</span>
                          <span className="info">
                            {showLang(inputs.language, lang)}
                          </span>
                        </li>

                        <li>
                          <span className="name">Race:</span>
                          <span className="info">
                            {showRace(inputs.race, races)}
                          </span>
                        </li>
                        <li>
                          <span className="name">Massage:</span>
                          <span className="info">
                            {inputs.massage === "yes" ? "Yes" : "No"}
                          </span>
                        </li>

                        <li>
                          <span className="name">Threesome:</span>
                          <span className="info">
                            {inputs.threesome === "yes" ? "Yes" : "No"}
                          </span>
                        </li>
                        <li>
                          <span className="name">Kissing:</span>
                          <span className="info">{inputs.kissing_types}</span>
                        </li>

                        <li>
                          <span className="name">Fellatio:</span>
                          <span className="info">
                            {inputs.fellatio_type.toString()}
                          </span>
                        </li>
                        <li>
                          <span className="name">Cunnilingus:</span>
                          <span className="info">
                            {inputs.cunnilingus === "yes" ? "Yes" : "No"}
                          </span>
                        </li>
                      </ul>
                    </Col>
                    <Col md={6}>
                      <ul className="info-lst">
                        <li>
                          <span className="name">Anilingus:</span>
                          <span className="info">
                            {inputs.anilingus_act.toString()}
                          </span>
                          {/* <span className="info">{inputs.anilingus}</span> */}
                        </li>
                        <li>
                          <span className="name">Vaginal:</span>
                          <span className="info">
                            {inputs.vaginal_type.toString()}
                          </span>
                        </li>
                        <li>
                          <span className="name">Anal:</span>
                          <span className="info">
                            {inputs.anal_activity.toString()}
                          </span>
                        </li>
                        <li>
                          <span className="name">Cum:</span>
                          <span className="info">
                            {inputs.cum ? "See the message below" : null}
                          </span>
                        </li>

                        <li>
                          <span className="name">Personality:</span>
                          <span className="info">{inputs.personality}</span>
                        </li>
                        <li>
                          <span className="name">Grooming:</span>
                          <span className="info">{inputs.grooming}</span>
                        </li>

                        <li>
                          <span className="name">Other:</span>
                          <span className="info">Yes</span>
                        </li>
                        <li>
                          <span className="name">Phone</span>
                          <span className="info">{inputs.phone}</span>
                        </li>
                      </ul>
                      <h4 className="pricing">
                        <em>$</em> {inputs.donation_half_hour}/h,{" "}
                        {inputs.donation_hour}/H <span>{inputs.call}</span>
                      </h4>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className="cum-wrap">
            <div className="cum-inner-wrap">
              <h5>
                <span>Cum:</span> {inputs.cum_type.toString()}
              </h5>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p> */}
            </div>
          </div>
          {/* <Col md={6}>
                   CUM:  {inputs.cum_type}
                    </Col> */}

          {videoSrc ? (
            <video width="100%" controls playsInline src={videoSrc} />
          ) : null}
        </Col>
      </Row>
    </>
  );
}
