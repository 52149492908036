import "./styles.module.css?esbuild-css-modules-plugin-built";
export default {"arrow":"react-tooltip__arrow_KtSkBq","clickable":"react-tooltip__clickable_KtSkBq","dark":"react-tooltip__dark_KtSkBq","error":"react-tooltip__error_KtSkBq","fixed":"react-tooltip__fixed_KtSkBq","info":"react-tooltip__info_KtSkBq","light":"react-tooltip__light_KtSkBq","noArrow":"react-tooltip__no-arrow_KtSkBq","show":"react-tooltip__show_KtSkBq","success":"react-tooltip__success_KtSkBq","tooltip":"react-tooltip__tooltip_KtSkBq","warning":"react-tooltip__warning_KtSkBq"};;
export const arrow = "react-tooltip__arrow_KtSkBq";
export const clickable = "react-tooltip__clickable_KtSkBq";
export const dark = "react-tooltip__dark_KtSkBq";
export const error = "react-tooltip__error_KtSkBq";
export const fixed = "react-tooltip__fixed_KtSkBq";
export const info = "react-tooltip__info_KtSkBq";
export const light = "react-tooltip__light_KtSkBq";
export const noArrow = "react-tooltip__no-arrow_KtSkBq";
export const show = "react-tooltip__show_KtSkBq";
export const success = "react-tooltip__success_KtSkBq";
export const tooltip = "react-tooltip__tooltip_KtSkBq";
export const warning = "react-tooltip__warning_KtSkBq";