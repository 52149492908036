import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Toast } from "../../layout/Alert";

import RateOneHobbiyst from "./RateOneHobbiyst";
import { UserContext } from "../../../context/UserContext";
import CustomerHobbyistService from "../../../services/CustomerHobbyistService";
import Account from "../../layout/Account/Account";

const RateHobbiyst = () => {
  const { userId, isHobbyist, isProvider, user } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [hobbyist, setHobbyist] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function fetchAllUsers(que) {
    let allUsersRes = await CustomerHobbyistService.getAll(que);
    if (allUsersRes.status === 200) {
      // console.log("ALl users", allUsersRes.data.data);
      setHobbyist(() => allUsersRes?.data?.data);
    } else {
      Toast.fire({
        icon: "warning",
        title: "Something went wrong. try again later",
      });
    }
  }

  async function handleAddNumber() {
    // console.log("PHONE NUMBER", phoneNumber);
    if (phoneNumber.length) {
      let addNumberRes = await CustomerHobbyistService.create({
        data: {
          phone_number: phoneNumber,
        },
      });
      if (addNumberRes.status === 200) {
        await fetchAllUsers();
        setPhoneNumber("");
        handleClose();
      }
    }
  }

  useEffect(() => {
    fetchAllUsers({ populate: "*" });
  }, []);

  function handleSearch(search) {
    setHobbyist(() => []);
    if (search.length) {
      let qury = {};
      qury.populate = "*";
      qury.filters = {};
      qury.filters.phone_number = {};
      qury.filters.phone_number.$startsWith = search;
      console.log("Query", qury);
      fetchAllUsers(qury);
    } else {
      fetchAllUsers({ populate: "*" });
    }
  }

  return (
    <Account>
      <Row className="mt-5">
        <Col md={{ span: 12 }}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="top-srch">
              {/* <form> */}
              <input
                placeholder="Search Phone Number"
                type="text"
                onChange={(e) => handleSearch(e.target.value.trim())}
              />
              <button type="button" className="search-icon">
                <i className="fa fa-search"></i>
              </button>
              {/* </form> */}
            </div>
            <button onClick={handleShow} className="btn btn-warning">
              Add Phone Number
            </button>
            {/* <button onClick={handleShow} className="btn btn-warning">
              Add Phone Number + Rate/Comment
            </button> */}
          </div>
          <Row className="mt-5 total-rate-bx">
            {hobbyist.map((row, index) => {
              return <RateOneHobbiyst key={index} row={row} />;
            })}
          </Row>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {/* <div className="lft-profile-prt"> */}
          {/* <div className="balence-bx"> */}
          <form>
            <div className="row">
              <div className="col-12 col-md-9">
                <input
                  type="text"
                  name="phoneNumber"
                  className="form-control rounded-0 screen-hobbyist-number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Add phone number"
                />
              </div>
              <div className="col-12 col-md-3">
                <button
                  className="btn btn-warning w-100"
                  type="button"
                  onClick={handleAddNumber}
                >
                  Add
                </button>
              </div>
            </div>
          </form>
          {/* </div> */}
          {/* </div> */}
        </Modal.Body>
      </Modal>
    </Account>
  );
};

export default RateHobbiyst;
