import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "react-tooltip/dist/react-tooltip.css";

import Sidebar from "../layout/Sidebar";
import RightSidebar from "../layout/RightSidebar";
import Posts from "./Posts";

export default function Home() {
  return (
    <>
      <div className="container">
        <p className="mid-pera mb-0 mt-2 text-start">
          *ALL model performances are role playing ONLY. No illegal activities
          are implied, nor should they be inferred in ANY capacity ever.
        </p>

        <Row>
          <Col lg={2}>
            <div>
              {/* <label for="customRange3" className="form-label">Age</label> */}
            </div>
          </Col>
        </Row>
      </div>
      <div className="main-wrap">
        <Container>
          <Row className="position-relative">
            <Col lg={2}>
              <Sidebar />
            </Col>

            <Col lg={7}>
              <Posts />
            </Col>

            <Col lg={3}>
              <div className="fixme">
                <RightSidebar />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
