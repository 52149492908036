import StorageHelper from "./StorageHelper";

class HttpHelper {
  BASE_URL = process.env.REACT_APP_BASE_URL;
  API_URL = process.env.REACT_APP_API_SERVICE_URL;

  getHeader() {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  getAuthHeader() {
    return {
      headers: {
        Authorization: "Bearer " + StorageHelper.getToken(),
      },
    };
  }
  getHeaderMultipart() {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }

  getAuthHeaderMultiPart() {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + StorageHelper.getToken(),
      },
    };
  }
}

export default HttpHelper = new HttpHelper();
