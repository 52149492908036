import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Form } from "react-bootstrap";

const CallFilter = ({ handleChange, call }) => {
  const [open7, setOpen7] = useState(true);
  const [inCall, setInCall] = useState(false);
  const [outCall, setOutCall] = useState(false);
  const [both, setBoth] = useState(false);

  const handleCheckBox = (event) => {
    if (event.target.id === "Incall") {
      if (event.target.checked) {
        setInCall(true);
        setOutCall(false);
      } else {
        setInCall(false);
      }
      setBoth(false);
    } else {
      if (event.target.checked) {
        setInCall(false);
        setOutCall(true);
      } else {
        setOutCall(false);
      }
      setBoth(false);
    }
    handleChange(event);
  };

  const handleCheckBoth = (event) => {
    console.log(event.target.checked);
    if (event.target.checked) {
      setBoth(true);
      setInCall(true);
      setOutCall(true);
    } else {
      setBoth(false);
      setInCall(false);
      setOutCall(false);
    }
    handleChange(event);
  };

  return (
    <div className="category-list mb-3">
      <div
        className="expand"
        onClick={() => setOpen7(!open7)}
        aria-controls="category7"
        aria-expanded={open7}
      >
        <div className="right-arrow">
          <i className="fa fa-angle-down"></i>
        </div>
        <div>
          <h3 className="hd3">Call</h3>
        </div>
      </div>
      <Collapse className="category-body" id="category7" in={open7}>
        <ul>
          <li>
            <Form.Check
              checked={inCall}
              inline
              className="cat-checkbox"
              id="Incall"
              aria-label="Incall"
              label={`Incall (${call[0]?.Incall})`}
              value="Incall"
              onChange={handleCheckBox}
              name="call"
            />
          </li>
          <li>
            <Form.Check
              checked={outCall}
              inline
              className="cat-checkbox"
              id="Outcall"
              aria-label="Outcall"
              label={`Outcall (${call[0]?.Outcall})`}
              value="Outcall"
              onChange={handleCheckBox}
              name="call"
            />
          </li>
          <li>
            {/* <Form.Check
                            checked={both}
                            inline
                            className="cat-checkbox"
                            id="Both"
                            aria-label="Both"
                            label={`Both (${call[0]?._Both})`}
                            value="Outcall/Incall"
                            onChange={handleCheckBoth}
                            name="call"
                        /> */}
          </li>
        </ul>
      </Collapse>
    </div>
  );
};

export default CallFilter;
