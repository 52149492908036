import React from "react";
import { Link } from "react-router-dom";
import HttpHelper from "../../helper/HttpHelper";

const Newslist = [
    {
        id: "1",
        imgsrc: "/images/pic3.jpg",
        title: "$60 QUICK VISIT SPECIAL",
        pera: "$60 QUICK VISIT Available for in and outcall in downtown San Jose. Call me..",
    },
    {
        id: "2",
        imgsrc: "/images/pic4.jpg",
        title: "Madurita ardiente jugosa y compla..",
        pera: "Hola caballeros mi nombre es victoria una mujer de mente abierta..",
    },
    {
        id: "3",
        imgsrc: "/images/pic5.jpg",
        title: "$60 QUICK VISIT SPECIAL",
        pera: "$60 QUICK VISIT Available for in and outcall in downtown San Jose. Call me..",
    },
    {
        id: "4",
        imgsrc: "/images/pic3.jpg",
        title: "Madurita ardiente jugosa y compla..",
        pera: "Hola caballeros mi nombre es victoria una mujer de mente abierta..",
    },
]


function RightSidebar() {

    const url = HttpHelper.BASE_URL;

    return (
        // <div className="fixme">
        <div className="">
{/* 
            {Newslist.map((nlist) => {
                return (
                    <div className="news-bx" id={nlist.id} key={nlist.id}>
                        <Link to="/" className="news-bx-inner d-flex align-items-center justify-content-between">
                            <div className="news-img">
                                <img style={{ width: "128px", higth: "128px" }} className="img-fluid" src={nlist.imgsrc} alt="" />
                            </div>
                            <div>
                                <h4>{nlist.title}</h4>
                                <p>{nlist.pera}</p>
                            </div>
                        </Link>
                    </div>
                );
            })} */}

        </div>
    );
}

export default RightSidebar;