import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";

import { Toast } from "../../layout/Alert";

import HttpHelper from "../../../helper/HttpHelper";
import VendorTypeService from "../../../services/VendorTypeService";
import VendorService from "../../../services/VendorService";
import AddressService from "../../../services/AddressService";
import UploadService from "../../../services/UploadService";

import { UserContext } from "../../../context/UserContext";
import { PlaceEditForm } from "../../../helper/ValidationHelper";

import Account from "../../layout/Account/Account";
import Loader from "../../layout/Loader";

export default function Edit() {
    let { id } = useParams();
    if (isNaN(id)) {
        id = null;
    }
    const { userId } = useContext(UserContext);

    // Image Form
    const [selectedImage, setselectedImage] = useState(true);
    const [isImgLoading, setImgIsLoading] = useState(false);
    const onFileChangeImages = (event) => {
        setselectedImage(false);
        let input = event.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                setImgPath(reader.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
    };
    async function handleUpload(e) {
        e.preventDefault();
        try {
            setselectedImage(true);
            setImgIsLoading(true);
            let response = await UploadService.upload(new FormData(e.target));
            if (response.status === 200) {
                console.log(response.data[0]);
                let oldImageId = imageId;
                let newImageId = response.data[0].id;
                response = await VendorService.update(id, { data: { image: newImageId } });
                setImgIsLoading(false);
                if (response.status === 200) {
                    setimageId(newImageId);
                    if (oldImageId) {
                        await UploadService.delete(oldImageId);
                    }
                    Toast.fire({
                        icon: "success",
                        title: "Your place are successfully updated",
                    });
                } else {
                    let message = "Please try again later";
                    Toast.fire({ icon: "error", title: message });
                }
            } else {
                setImgIsLoading(false);
                let message = "Please try again later";
                Toast.fire({ icon: "error", title: message });
            }
        } catch (err) {
            setselectedImage(true);
            setImgIsLoading(false);
            let message = err.message;
            // if (err.code === "ERR_BAD_REQUEST") {
            //     message = err.response.data.error;
            // }
            Toast.fire({ icon: "error", title: message });
        }
    }

    // Place Form
    const [isLoading, setIsLoading] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(true);
    const submitHandler = async (input) => {
        try {
            setIsLoading(true);
            input.user = userId;
            input.vendor_type = Number(input.vendor_type);
            let response = await AddressService.update(addressId, { data: input });
            if (response.status === 200) {
                input.address = response.data.data.id;
                response = await VendorService.update(id, { data: input });
                if (response.status === 200) {
                    setIsLoading(false);
                    Toast.fire({
                        icon: "success",
                        title: "Your Place are successfully updated",
                    });
                }
            } else {
                setIsLoading(false);
                Toast.fire({
                    icon: "warning",
                    title: "Something went wrong. try again later",
                });
            }
        } catch (err) {
            setIsLoading(false);
            let message = err.message;
            Toast.fire({ icon: "error", title: message });
        }
    };
    const initialValues = {
        name: "",
        vendor_type: 0,
        location: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        phone: "",
        email: "",
        website: "",
        // image: "",
    };
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: PlaceEditForm,
        onSubmit: (values, action) => {
            submitHandler(values);
        },
    });

    // Get Form Data
    const [imageId, setimageId] = useState(0);
    const [imgPath, setImgPath] = useState('/images/no-photo-small.svg');
    const [addressId, setAddressId] = useState(0);
    const [vendorType, setVendorType] = useState([]);
    async function getData() {
        try {
            let response = await VendorTypeService.getAll({ fields: ["name"] });
            if (response.status === 200) {
                setVendorType(response.data.data);
            }
            response = await VendorService.getVendor(id, { populate: "*" });
            if (response.status === 200) {
                console.log(response.data.data.attributes);
                let place = response.data.data.attributes;
                formik.setFieldValue("name", place.name ? place.name : "");
                formik.setFieldValue("phone", place.phone ? place.phone : "");
                formik.setFieldValue("email", place.email ? place.email : "");
                formik.setFieldValue("website", place.website ? place.website : "");
                if (place.address) {
                    setAddressId(place.address.data.id);
                    let address = place.address.data.attributes;
                    formik.setFieldValue("city", address.city ? address.city : "");
                    formik.setFieldValue("state", address.state ? address.state : "");
                    formik.setFieldValue(
                        "country",
                        address.country ? address.country : ""
                    );
                    formik.setFieldValue(
                        "location",
                        address.location ? address.location : ""
                    );
                    formik.setFieldValue(
                        "postal_code",
                        address.postal_code ? address.postal_code : ""
                    );
                }
                if (place.vendor_type) {
                    formik.setFieldValue("vendor_type", place.vendor_type.data.id);
                }
                if (place.image.data) {
                    const url = HttpHelper.BASE_URL;
                    let img = place.image.data?.attributes;
                    if (img.formats) {
                        setImgPath(url + img.formats?.thumbnail?.url);
                    } else {
                        setImgPath(url + img.url);
                    }
                    setimageId(place.image.data?.id);
                }
            }
            setLoaderVisible(false);
        } catch (error) {
            setLoaderVisible(false);
            let message = error.message;
            if (error.code === "ERR_BAD_REQUEST") {
                message = error.response.data.error;
            }
            Toast.fire({ icon: "error", title: message });
        }
    }
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Account>

            <Loader visible={loaderVisible}>
                <div className="filter-part p-3 mb-3">
                    <h2 className="title1 p-0 mb-3">Edit place</h2>
                    <Row className="mb-3">
                        <Col lg={3} md={4}>
                            <Form onSubmit={handleUpload}>
                                <Row>
                                    <Form.Group
                                        controlId="image"
                                        className="col-md-12 mb-3"
                                    >
                                        <Form.Label>Image</Form.Label>
                                        <Photo url={imgPath}></Photo>
                                        <Form.Control
                                            type="file"
                                            name="files"
                                            accept="image/*"
                                            onChange={(e) => {
                                                onFileChangeImages(e);
                                            }}
                                            className="form-control"
                                            required
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className="col-md-12 d-flex mb-5">
                                        <Button
                                            disabled={selectedImage}
                                            type="submit"
                                            className="btn-warning btn-subscribe"
                                        >
                                            {isImgLoading ? (
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            ) : (
                                                "Upload"
                                            )}
                                        </Button>
                                    </Form.Group>
                                </Row>
                            </Form>
                        </Col>

                        <Col lg={9} md={8}>
                            <Form noValidate onSubmit={formik.handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group
                                        className="col-lg-7 mb-3"
                                        controlId="name"
                                    >
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.name}
                                            isInvalid={
                                                formik.touched.name && formik.errors.name
                                            }
                                            className="form-control"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="col-lg-5 mb-3"
                                        controlId="vendor_type"
                                    >
                                        <Form.Label>Place Type</Form.Label>
                                        <Form.Select
                                            name="vendor_type"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.vendor_type}
                                            isInvalid={
                                                formik.touched.vendor_type &&
                                                formik.errors.vendor_type
                                            }
                                            className="form-control"
                                            required
                                        >
                                            <option></option>
                                            <VendorType data={vendorType}></VendorType>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group
                                        className="col-lg-12 mb-3"
                                        controlId="location"
                                    >
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control
                                            name="location"
                                            type="text"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.location}
                                            isInvalid={
                                                formik.touched.location &&
                                                formik.errors.location
                                            }
                                            className="form-control"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="col-lg-6 mb-3"
                                        controlId="city"
                                    >
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            name="city"
                                            type="text"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.city}
                                            isInvalid={
                                                formik.touched.city && formik.errors.city
                                            }
                                            className="form-control"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="col-lg-6 mb-3"
                                        controlId="state"
                                    >
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            name="state"
                                            type="text"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.state}
                                            isInvalid={
                                                formik.touched.state && formik.errors.state
                                            }
                                            className="form-control"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="col-lg-6 mb-3"
                                        controlId="postal_code"
                                    >
                                        <Form.Label>Postal Code</Form.Label>
                                        <Form.Control
                                            name="postal_code"
                                            type="text"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.postal_code}
                                            isInvalid={
                                                formik.touched.postal_code &&
                                                formik.errors.postal_code
                                            }
                                            className="form-control"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="col-lg-6 mb-3"
                                        controlId="country"
                                    >
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control
                                            name="country"
                                            type="text"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.country}
                                            isInvalid={
                                                formik.touched.country && formik.errors.country
                                            }
                                            className="form-control"
                                            required
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group
                                        className="col-lg-5 mb-3"
                                        controlId="phone"
                                    >
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control
                                            name="phone"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.phone}
                                            isInvalid={
                                                formik.touched.phone && formik.errors.phone
                                            }
                                            className="form-control"
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        className="col-lg-7 mb-3"
                                        controlId="email"
                                    >
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            name="email"
                                            type="email"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                            isInvalid={
                                                formik.touched.email && formik.errors.email
                                            }
                                            className="form-control"
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        className="col-lg-12 mb-3"
                                        controlId="website"
                                    >
                                        <Form.Label>Website</Form.Label>
                                        <Form.Control
                                            name="website"
                                            type="text"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.website}
                                            isInvalid={
                                                formik.touched.website && formik.errors.website
                                            }
                                            className="form-control"
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className="col-md-12 mb-3 d-flex">
                                        <Button
                                            size="lg"
                                            type="submit"
                                            disabled={isLoading}
                                            className="btn btn-warning"
                                        >
                                            {isLoading ? (
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            ) : (
                                                "Save"
                                            )}
                                        </Button>
                                    </Form.Group>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Loader>

        </Account>
    );
}

function VendorType({ data }) {
    return (
        <>
            {data.map((row) => {
                return (
                    <option key={row.id} value={row.id}>
                        {row.attributes.name}
                    </option>
                );
            })}
        </>
    );
}

function Photo({ url }) {
    return (
        <div className="w-100 text-center">
            <img alt="" src={url} className="image w-100" border="0" />
        </div>
    );
}
