import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
// import Modal from "react-bootstrap/Modal";
// import { Modal, Button } from "react-bootstrap";
import Slider from "react-slick";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import HttpHelper from "../../helper/HttpHelper";
// import { DobToAge } from "../../helper/UtilityHelper";
import { UserContext } from "../../context/UserContext";

import LikeService from "../../services/LikeService";
import DislikeService from "../../services/DislikeService";
import CommentService from "../../services/CommentService";
import MessageService from "../../services/MessageService";
import AvgService from "../../services/AvgService";
import { useFormik } from "formik";
import TipService from "../../services/TipService";
import { Toast } from "../layout/Alert";
import TransactionService from "../../services/TransactionService";
import ProcessingCostServices from "../../services/ProcessingCostServices";

const slider1 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export default function Post({ row }) {
  // console.log("ROW", row.attributes.cum_type  );
  const url = HttpHelper.BASE_URL;
  const { userId, isHobbyist, isProvider, user, updateProcessingCosts } =
    useContext(UserContext);

  const attr = row.attributes;
  const images = row.attributes?.images?.data ? row.attributes.images.data : [];
  const videosrc = row.attributes?.video?.data
    ? url + row.attributes.video.data.attributes.url
    : "";
  const provider = row.attributes.user
    ? row.attributes.user.data.attributes
    : "";

  const [like, setlike] = useState(0);
  const [dislike, setdislike] = useState(0);

  const [avg, setAvg] = useState(0);

  const [comments, setcomments] = useState([]);

  const [likecounter, setlikecounter] = useState(
    row.attributes.likes ? row.attributes.likes.data.length : 0
  );
  const [dislikecounter, setdislikecounter] = useState(
    row.attributes.dislikes ? row.attributes.dislikes.data.length : 0
  );
  const [commentcounter, setcommentcounter] = useState(
    row.attributes.comments ? row.attributes.comments.data.length : 0
  );
  const [avgCounter, setAvgCounter] = useState(
    row.attributes.averages ? row.attributes.averages.data.length : 0
  );

  const [messages, setMessages] = useState([]);
  const [messagescounter, setMessagescounter] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);

  const handleTipClick = () => {
    // console.log("CLICKED HANDLE TIP");
    setModalOpen(true);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = function () {
    setIsOpen(() => true);
    console.log(row);
  };

  const handleClose = function () {
    setIsOpen(() => false);
  };

  const submitHandler = async (formvalues) => {
    try {
      setIsLoading(true);
      console.log(
        "Formvalues",
        formvalues.transaction_id,
        formvalues.tip_amount
      );
      let tipResponse = await TipService.create({
        data: {
          transaction_id: formvalues.transaction_id,
          tip_amount: formvalues.tip_amount,
          // ...formvalues,
          user: userId,
          provider: row.id,
        },
      });
      if (tipResponse.status === 200) {
        await TransactionService.create({
          data: {
            transaction_uid: formvalues.transaction_id,
            transaction_amount: formvalues.tip_amount,
            user: userId,
            tip: tipResponse.data.data.id,
          },
        });
        setIsLoading(false);
        Toast.fire({
          icon: "success",
          title: "Your Tip is paid successfully",
        });
        // clearCartItem();
        // navigate("/");
      } else {
        setIsLoading(false);
        Toast.fire({
          icon: "warning",
          title: "Something went wrong. try again later",
        });
      }
      setIsOpen(false);
    } catch (err) {
      setIsLoading(false);
      let message = err.message;
      if (err.code === "ERR_BAD_REQUEST") {
        message = "Something Went Worng!!!";
      }
      Toast.fire({ icon: "error", title: message });
    }
  };

  const initialValues = {
    transaction_id: "",
    tip_amount: "",
    user: "",
    provider: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: CheckoutForm,
    onSubmit: (values, action) => {
      console.log("Value", values, userId, row.id);
      submitHandler(values);
      action.resetForm();
      // resetInputFile();
    },
  });

  async function fetchData() {
    try {
      let qury = {};
      qury.populate = {};
      qury.populate.user = true;
      qury.filters = {};
      qury.filters.provider = {};
      qury.filters.provider.id = {};
      qury.filters.provider.id.$eq = row.id;
      qury.filters.user = {};
      qury.filters.user.id = {};
      qury.filters.user.id.$eq = userId;

      let response = "";
      if (likecounter) {
        response = await LikeService.getAll(qury);
        if (response.status === 200) {
          if (response.data.data.length) {
            setlike(parseInt(response.data.data[0].id));
          }
        }
      }
      if (dislikecounter) {
        response = await DislikeService.getAll(qury);
        if (response.status === 200) {
          if (response.data.data.length) {
            setdislike(parseInt(response.data.data[0].id));
          }
        }
      }
      if (avgCounter) {
        response = await AvgService.getAll(qury);
        if (response.status === 200) {
          if (response.data.data.length) {
            setAvg(parseInt(response.data.data[0].id));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchProcessingCosts() {
    let processingCostRes = await ProcessingCostServices.getAll();
    if (processingCostRes.status === 200) {
      // console.log("processing>>>", processingCostRes.data.data[0].attributes);
      updateProcessingCosts(
        JSON.stringify(processingCostRes.data.data[0].attributes)
      );
    }
  }

  async function fetchComments() {
    try {
      let qury = {};
      qury.populate = {};
      qury.populate.user = {};
      qury.populate.user.fields = ["first_name", "last_name"];
      qury.filters = {};
      qury.filters.provider = {};
      qury.filters.provider.id = {};
      qury.filters.provider.id.$eq = row.id;
      let response = await CommentService.getAll(qury);
      if (response.status === 200) {
        setcomments(response.data.data);
        setcommentcounter(response.data.data.length);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchMessages() {
    try {
      let qury = {};
      qury.populate = {};
      qury.populate.user = {};
      qury.populate.user.fields = ["first_name", "last_name"];
      qury.filters = {};
      qury.filters.provider = {};
      qury.filters.provider.id = {};
      qury.filters.provider.id.$eq = row.id;
      qury.filters.provider.user = {};
      if (isProvider) {
        qury.filters.provider.user.id = {};
        qury.filters.provider.user.id.$eq = userId;
      }

      // qury.filters.user = {};
      // qury.filters.user.id = {};
      //qury.filters.user.id.$eq = userId;
      let response = await MessageService.getAll(qury);
      if (response.status === 200) {
        setMessages(response.data.data);
        setMessagescounter(response.data.data.length);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleLike() {
    if (isHobbyist()) {
      if (userId > 0 && !like) {
        const response = await LikeService.create({
          data: {
            user: userId,
            provider: row.id,
          },
        });
        if (response.status === 200) {
          // const id = dislike;
          setlike(response.data.data.id);
          setlikecounter(likecounter + 1);
          if (dislike) {
            setdislike(0);
            setdislikecounter(dislikecounter - 1);
            await DislikeService.delete(dislike);
          }
          if (avg) {
            setAvg(0);
            setAvgCounter(avgCounter - 1);
            await AvgService.delete(avg);
          }
        }
      }
    }
  }

  async function handleDislike() {
    if (isHobbyist()) {
      if (userId > 0 && !dislike) {
        const response = await DislikeService.create({
          data: {
            user: userId,
            provider: row.id,
          },
        });
        if (response.status === 200) {
          setdislike(response.data.data.id);
          setdislikecounter(dislikecounter + 1);
          if (like) {
            setlike(0);
            setlikecounter(likecounter - 1);
            await LikeService.delete(like);
          }
          if (avg) {
            setAvg(0);
            setAvgCounter(avgCounter - 1);
            await AvgService.delete(avg);
          }
        }
      }
    }
  }

  async function handleAvg() {
    console.log("Clicked");
    if (isHobbyist()) {
      console.log("first if");
      if (userId > 0 && !avg) {
        console.log("DATA PAYLOAD>>>", userId, row.id);
        const response = await AvgService.create({
          data: {
            user: userId,
            provider: row.id,
          },
        });
        if (response.status === 200) {
          console.log("RES AVG>>>", response.data);
          // const id = avg;
          setAvg(response.data.data.id);
          setAvgCounter(avgCounter + 1);
          if (like) {
            setlike(0);
            setlikecounter(likecounter - 1);
            await LikeService.delete(like);
          }
          if (dislike) {
            setdislike(0);
            setdislikecounter(dislikecounter - 1);
            await DislikeService.delete(dislike);
          }
        }
      }
    }
  }

  const [visible, setVisible] = useState(true);
  const [slider, setSlider] = useState(slider1);
  const ClickFun = () => {
    setVisible((visible) => !visible);
    if (visible) {
      setSlider({ ...slider, slidesToShow: 1 });
    } else {
      setSlider({ ...slider, slidesToShow: 1 });
    }
  };

  const [slideDown, setslideDown] = useState(false);
  const [slideDown2, setslideDown2] = useState(true);
  const [slideDown3, setslideDown3] = useState(false);
  const [slideDown4, setslideDown4] = useState(false);

  const handleClick = (event) => {
    setslideDown((current) => {
      if (!current) {
        fetchComments();
      }
      return !current;
    });
    setslideDown2((c) => false);
    setslideDown3((c) => false);
    setslideDown4((c) => false);
  };
  const handleClick2 = (event) => {
    setslideDown((c) => false);
    setslideDown3((c) => false);
    setslideDown2((current) => {
      if (!current) {
        fetchMessages();
      }
      return !current;
    });
    setslideDown4((c) => false);
  };
  const handleClick3 = (event) => {
    setslideDown((c) => false);
    setslideDown2((c) => false);
    setslideDown3((current) => !current);
    setslideDown4((c) => false);
  };
  const handleClick4 = (event) => {
    setslideDown((c) => false);
    setslideDown2((c) => false);
    setslideDown3((c) => false);
    setslideDown4((current) => !current);
  };

  // console.log("MESSAGE>>>>>>>", attr.message);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
    fetchProcessingCosts();
  }, []);

  useEffect(() => {
    if (!userId && (like || dislike)) {
      setAvg(0);
      setlike(0);
      setdislike(0);
    }
  }, [userId, like, dislike]);

  return (
    <div className="mid-bx mb-3">
      <span className="cross" onClick={ClickFun}>
        <img src="/images/cross.png" alt="cross" />
      </span>

      <div className="mid-bx-inner d-flex justify-content-between">
        {images ? (
          <div className="lft-img-prt">
            <Slider {...slider}>
              {images.map((row, id) => {
                return (
                  <div key={id}>
                    <img
                      className="img-fluid"
                      src={url + row.attributes.url}
                      alt={row.attributes.name}
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
        ) : null}

        {visible ? (
          <div className="rt-content-prt">
            <div className="hdr-prt">
              <h3>{attr.title}</h3>
            </div>

            <div className="p-2">
              <Row>
                <Col md={6}>
                  <ul className="info-lst">
                    <li>
                      <span className="name">Name:</span>
                      <span className="info">{attr.name}</span>
                    </li>
                    <li>
                      <span className="name">Location:</span>
                      <span className="info">{attr.city}</span>
                    </li>
                    <li>
                      <span className="name">Language:</span>
                      <span className="info">
                        {attr?.language?.data?.attributes?.lang_name}
                      </span>
                    </li>
                    <li>
                      <span className="name">Age:</span>
                      <span className="info">{attr?.age}</span>
                    </li>

                    <li>
                      <span className="name">Race:</span>
                      <span className="info">
                        {attr.race.data.attributes.race_name}
                      </span>
                    </li>
                    <li>
                      <span className="name">Massage:</span>
                      <span className="info">
                        {attr?.massage === "yes" ? "Yes" : "No"}
                      </span>
                    </li>

                    <li>
                      <span className="name">Threesome:</span>
                      <span className="info">
                        {attr?.threesome === "yes" ? "Yes" : "No"}
                      </span>
                    </li>
                    <li>
                      <span className="name">Kissing:</span>
                      {/* <span className="info">{attr.kissing}</span> */}
                      <span className="info">
                        {attr?.kissing_types?.toString()
                          ? attr?.kissing_types?.toString()
                          : "No"}
                      </span>
                    </li>

                    <li>
                      <span className="name">Fellatio:</span>
                      {/* <span className="info">{attr.fellatio}</span> */}
                      {attr.fellatio === "no" ? (
                        <span className="info">No</span>
                      ) : (
                        <span className="info">
                          {attr.fellatio === "yes"
                            ? "Yes"
                            : attr.fellatio_type.toString()}
                        </span>
                      )}
                      {/* <span className="info">
                        {attr.fellatio === "yes"
                          ? "Yes"
                          : attr.fellatio_type.toString()}
                      </span> */}
                    </li>
                    <li>
                      <span className="name">Cunnilingus:</span>
                      <span className="info">
                        {attr?.cunnilingus === "yes" ? "Yes" : "No"}
                      </span>
                    </li>
                  </ul>
                </Col>
                <Col md={6}>
                  <ul className="info-lst">
                    <li>
                      <span className="name">Anilingus:</span>
                      {attr.anilingus === "no" ? (
                        <span className="info">No</span>
                      ) : (
                        <span className="info">
                          {attr.anilingus === "yes"
                            ? "Yes"
                            : attr.anilingus_act.toString()}
                        </span>
                      )}
                      {/* <span className="info">
                        {attr.anilingus === "yes"
                          ? "Yes"
                          : attr.anilingus_act.toString()}
                      </span> */}
                      {/* <span className="info">{attr.anilingus}</span> */}
                    </li>
                    <li>
                      <span className="name">Vaginal:</span>
                      {/* <span className="info">{attr.vaginal}</span> */}
                      {attr.vaginal === "no" ? (
                        <span className="info">No</span>
                      ) : (
                        <span className="info">
                          {attr.vaginal === "yes"
                            ? "Yes"
                            : attr.vaginal_type.toString()}
                        </span>
                      )}
                      {/* <span className="info">
                        {attr.vaginal === "yes"
                          ? "Yes"
                          : attr.vaginal_type.toString()}
                      </span> */}
                    </li>
                    <li>
                      <span className="name">Anal:</span>
                      {/* <span className="info">{attr.anal}</span> */}
                      {attr.anal === "no" ? (
                        <span className="info">No</span>
                      ) : (
                        <span className="info">
                          {attr.anal === "yes"
                            ? "Yes"
                            : attr.anal_activity.toString()}
                        </span>
                      )}
                      {/* <span className="info">
                        {attr.anal === "yes"
                          ? "Yes"
                          : attr.anal_activity.toString()}
                      </span> */}
                    </li>
                    <li>
                      <span className="name">Cum:</span>
                      <span className="info">
                        {attr?.cum === "CIM"
                          ? attr?.cum
                          : "See the message below"}
                      </span>
                    </li>

                    <li>
                      <span className="name">Personality:</span>
                      <span className="info">{attr.personality}</span>
                    </li>
                    <li>
                      <span className="name">Grooming:</span>
                      <span className="info">{attr.grooming}</span>
                    </li>

                    <li>
                      <span className="name">Other:</span>
                      <span className="info">Yes</span>
                    </li>
                    <li>
                      <span className="name">Phone</span>
                      <span className="info">{attr.phone}</span>
                    </li>
                  </ul>
                  <h4 className="pricing">
                    <em>$</em>: {attr.donation_half_hour}/h {attr.donation_hour}
                    /H <span>{attr.call}</span>
                  </h4>
                </Col>
              </Row>
            </div>
          </div>
        ) : null}
      </div>

      <div className="d-flex align-items-center justify-content-between mt-2">
        <ul className="feedback-lst">
          <LikeBtn
            like={like}
            likecounter={likecounter}
            handleLike={handleLike}
          />
          <AvgBtn avg={avg} avgCounter={avgCounter} handleAvg={handleAvg} />
          <DislikeBtn
            dislike={dislike}
            dislikecounter={dislikecounter}
            handleDislike={handleDislike}
          />
          <CommentBtn
            slideDown={slideDown}
            handleClick={handleClick}
            commentcounter={commentcounter}
          />
          {videosrc ? (
            <PlayBtn slideDown4={slideDown4} handleClick4={handleClick4} />
          ) : null}
          <MessageBtn
            slideDown2={slideDown2}
            handleClick2={handleClick2}
            messagescounter={messagescounter}
          />
          <MailBtn
            provider={provider}
            slideDown3={slideDown3}
            handleClick3={handleClick3}
          />
        </ul>
        <span>
          Cum: {row?.attributes?.cum_type ? row?.attributes?.cum_type : "No"}
        </span>
        <Button variant="outline-warning" onClick={() => handleOpen()}>
          TIP $
        </Button>
        {isHobbyist() ? (
          <Modal
            size="lg"
            show={isOpen}
            onHide={handleClose}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Tip for :{row.attributes.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h3>{user.username}</h3>
              <br />
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group mb-3">
                  <input
                    className="form-control tip-number"
                    type="number"
                    min={0}
                    name="tip_amount"
                    placeholder="Amount"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.tip_amount}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Transaction ID"
                    name="transaction_id"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.transaction_id}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-4 mb-3">
                      <img
                        className="img-fluid"
                        src="/images/qr.png"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-8 mb-3">
                      <ul className="step-lst">
                        <li>Step 1: Scan the QR code.</li>
                        <li>Step 2: Enter the total amount.</li>
                        <li>Step 3: Pay the amount.</li>
                        <li>Step 4: Enter the Transaction Id on tips form.</li>
                        <li>
                          Step 5: Verify your details and submit the form.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3 text-end">
                  <Button type="submit">Submit</Button>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
              <Button onClick={handleClose}>Cancel</Button>
            </Modal.Footer> */}
          </Modal>
        ) : null}
      </div>

      {slideDown ? (
        <div className="list-bx">
          <CommentList comments={comments} />
          <CommentForm row={row} fetchComments={fetchComments} />
        </div>
      ) : null}

      {slideDown2 ? (
        <div className="list-bx">
          <MessageList messages={messages} />
          <MessageForm row={row} fetchMessages={fetchMessages} />
        </div>
      ) : null}

      {slideDown3 ? (
        <div className="list-bx">
          <Form.Group className="col-md-12 mb-3">
            <Form.Label>To</Form.Label>
            <Form.Control type="text" />
          </Form.Group>
          <Form.Group className="col-md-12 mt-3">
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>
          <Form.Group className="col-md-4 mt-3">
            <Button className="btn btn-warning" type="Submit">
              Send
            </Button>
          </Form.Group>
        </div>
      ) : null}

      <Modal
        size="lg"
        show={slideDown4}
        onHide={handleClick4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{attr.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {videosrc ? (
            <video width="100%" controls playsInline src={videosrc} />
          ) : null}
        </Modal.Body>
      </Modal>
    </div>
  );
}

function LikeBtn({ like, likecounter, handleLike }) {
  return (
    <li id="like" onClick={handleLike}>
      {like ? (
        <i className="fa fa-thumbs-up like"></i>
      ) : (
        <i className="fa fa-thumbs-up"></i>
      )}{" "}
      {likecounter}
      <Tooltip anchorSelect="#like" content="Like" />
    </li>
  );
}

function AvgBtn({ avg, avgCounter, handleAvg }) {
  console.log(avg, avgCounter);
  return (
    <li id="avg" onClick={handleAvg}>
      {avg > 0 ? (
        <span style={{ backgroundImage: `url(images/ico1.png)` }}>
          <img className="hvr-img" src="/images/ico1.png" alt="" />
        </span>
      ) : (
        <span style={{ backgroundImage: `url(images/ico1.png)` }}>
          <img className="hvr-img" src="/images/ico1-x.png" alt="" />
        </span>
      )}
      {avgCounter}
      <Tooltip anchorSelect="#avg" content="Avg." />
    </li>
  );
}

function DislikeBtn({ dislike, dislikecounter, handleDislike }) {
  return (
    <li id="down" onClick={handleDislike}>
      {dislike ? (
        <i className="fa fa-thumbs-down dislike"></i>
      ) : (
        <i className="fa fa-thumbs-down"></i>
      )}{" "}
      {dislikecounter}
      <Tooltip anchorSelect="#down" content="Dislike" />
    </li>
  );
}

function CommentBtn({ slideDown, handleClick, commentcounter }) {
  return (
    <li
      id="comment"
      onClick={handleClick}
      className={slideDown ? "active" : ""}
    >
      <i className="fa fa-commenting"></i> {commentcounter}
      <Tooltip anchorSelect="#comment" content="Comment" />
    </li>
  );
}

function PlayBtn({ slideDown4, handleClick4 }) {
  return (
    <li id="play" onClick={handleClick4} className={slideDown4 ? "active" : ""}>
      <i className="fa fa-play-circle-o"></i>
      <Tooltip anchorSelect="#play" content="Play" />
    </li>
  );
}

function MessageBtn({ slideDown2, handleClick2, messagescounter }) {
  return (
    <li
      id="message"
      onClick={handleClick2}
      className={slideDown2 ? "active" : ""}
    >
      <i className="fa fa-comments-o"></i>
      {/* {messagescounter} */}

      <Tooltip anchorSelect="#message" content="Message" />
    </li>
  );
}

function MailBtn({ provider, slideDown3, handleClick3 }) {
  return (
    <a
      id="mail"
      // onClick={handleClick3}
      // className={slideDown3 ? "active" : ""}
      href={"mailto:" + provider.email}
    >
      <i className="fa fa-envelope-o"></i>
      <Tooltip anchorSelect="#mail" content="Mail" />
    </a>
    // <li id="mail" onClick={handleClick3} className={slideDown3 ? "active" : ""}>
    //   <i className="fa fa-envelope-o"></i>
    //   <Tooltip anchorSelect="#mail" content="Mail" />
    // </li>
  );
}

const calculateTimeDifference = (commentTimestamp) => {
  const currentTimestamp = new Date().getTime();
  const commentTime = new Date(commentTimestamp).getTime();
  const timeDifference = currentTimestamp - commentTime;
  const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60)); // Convert milliseconds to hours

  if (hoursAgo < 24) {
    return `${hoursAgo} ${hoursAgo === 1 ? "hour" : "hours"} ago`;
  } else if (hoursAgo < 24 * 10) {
    const daysAgo = Math.floor(hoursAgo / 24);
    return `${daysAgo} ${daysAgo === 1 ? "day" : "days"} ago`;
  } else {
    const creationDate = new Date(commentTimestamp).toLocaleDateString();
    return `Commented on ${creationDate}`;
  }
};

function CommentList({ comments }) {
  // console.log("Comments>>>>>", comments);
  if (!comments.length) {
    return null;
  }

  return (
    <Row>
      <div>
        <ul className="comment-wrap">
          {comments.map((row) => {
            const user = row.attributes.user?.data.attributes;
            return (
              <>
                {/* <div className="comment-text">{row.attributes.comment} </div>
            <span>
              <small>
                {user.first_name} {user.last_name}
              </small>
            </span> */}
                <li key={row.id}>
                  <div className="comment-inner">
                    <h4>
                      {user.first_name} {user.last_name}{" "}
                      <span>
                        {calculateTimeDifference(row.attributes.createdAt)}
                      </span>
                    </h4>
                    <p>{row.attributes.comment}</p>
                    {/* <div className="like-reply">
                      <ul>
                        <li><i className="far fa-thumbs-up"></i> 2</li>
                        <li><i className="far fa-thumbs-down"></i> 1</li>
                        <li><button className="reply-btn">Reply</button></li>
                      </ul>
                    </div>
                    <button className="replies-btn"><i className="fas fa-angle-down"></i> replies</button> */}
                  </div>
                </li>
                {/* <li> */}
                {/* <div className="comment-inner">
                    <h4>name@g.com</h4>
                    <p>All model performances are role playing ONLY, No illegal activities are implied nor should they be inferred in any capacity ever</p> */}
                {/* <div className="like-reply">
                      <ul>
                        <li><i className="far fa-thumbs-up"></i> 2</li>
                        <li><i className="far fa-thumbs-down"></i> 1</li>
                        <li><button className="reply-btn">Reply</button></li>
                      </ul>
                    </div>
                    <button className="replies-btn"><i className="fas fa-angle-down"></i> replies</button> */}
                {/* </div> */}
                {/* 2nd comment */}
                {/* <ul>
                    <li> */}
                {/* <div className="comment-inner">
                      <h4>name@g.com</h4>
                      <p>All model performances are role playing ONLY, No illegal activities are implied nor should they be inferred in any capacity ever</p>
                      <div className="like-reply">
                        <ul>
                          <li><i className="far fa-thumbs-up"></i> 2</li>
                          <li><i className="far fa-thumbs-down"></i> 1</li>
                          <li><button className="reply-btn">Reply</button></li>
                        </ul>
                      </div>
                      <button className="replies-btn"><i className="fas fa-angle-down"></i> replies</button>
                    </div> */}
                {/* 3rd comment */}
                {/*<ul>
                        <li>
                           <div className="comment-inner">
                          <h4>name@g.com</h4>
                          <p>All model performances are role playing ONLY, No illegal activities are implied nor should they be inferred in any capacity ever</p>
                          <div className="like-reply">
                            <ul>
                              <li><i className="far fa-thumbs-up"></i> 2</li>
                              <li><i className="far fa-thumbs-down"></i> 1</li>
                              <li><button className="reply-btn">Reply</button></li>
                            </ul>
                          </div>
                          <button className="replies-btn"><i className="fas fa-angle-down"></i> replies</button>
                        </div> 
                        </li>
                      </ul>*/}
                {/* </li>
                  </ul> 
                </li>*/}
              </>
            );
          })}
        </ul>
      </div>
    </Row>
  );
}

function CommentForm({ row, fetchComments }) {
  const { isHobbyist, userId } = useContext(UserContext);
  const [comment, setcomment] = useState("");

  if (!isHobbyist()) {
    return null;
  }

  async function handleComment() {
    setcomment("");
    const response = await CommentService.create({
      data: {
        user: userId,
        provider: row.id,
        comment: comment.trim(),
      },
    });
    if (response.status === 200) {
      fetchComments();
    }
  }

  return (
    <Row>
      <Col sm={9} lg={10} className="mb-3">
        <Form.Group className="col-12">
          <Form.Control
            type="text"
            value={comment}
            className="col-12"
            placeholder="leave your thoughts here"
            onChange={(e) => setcomment(e.target.value)}
          />
        </Form.Group>
      </Col>
      <Col sm={3} lg={2}>
        <Form.Group className="col-12">
          <Button
            type="button"
            onClick={handleComment}
            disabled={!comment.length}
            className="btn btn-warning col-12"
          >
            Post
          </Button>
        </Form.Group>
      </Col>
    </Row>
  );
}

function MessageList({ messages }) {
  if (!messages.length) {
    return null;
  }

  return (
    <Row>
      <ul>
        {messages.map((row) => {
          const user = row.attributes.user?.data.attributes;
          return (
            <li key={row.id}>
              {row.attributes.text}
              {/* <span>
                                <small>
                                    {user.first_name} {user.last_name}
                                </small>
                            </span> */}
            </li>
          );
        })}
      </ul>
    </Row>
  );
}

function MessageForm({ row, fetchMessages }) {
  const { isHobbyist, userId } = useContext(UserContext);
  const [comment, setcomment] = useState(row.attributes.message);
  const [cumTypes, setCumTypes] = useState(row.attributes.cum_types);

  // console.log("Cumm Types>>", cumTypes);
  if (!isHobbyist()) {
    return null;
  }

  async function handleComment() {
    setcomment("");
    const response = await MessageService.create({
      data: {
        user: userId,
        provider: row.id,
        text: comment.trim(),
      },
    });
    if (response.status === 200) {
      fetchMessages();
    }
  }

  return (
    <Row>
      <Col sm={12} lg={12} className="">
        <div
          className=""
          style={{ background: "rgba(0, 0, 0, 0.05)", padding: "12px" }}
        >
          <div className="">
            {" "}
            {comment} {cumTypes}
          </div>
        </div>
      </Col>
      {/* <Col sm={9} lg={10} className="mb-3">
        <Form.Group className="col-12">
          <Form.Control
            type="text"
            value={comment}
            disabled
            className="col-12"
            onChange={(e) => setcomment(e.target.value)}
          />
        </Form.Group>
      </Col> */}
      {/* <Col sm={3} lg={2}>
        <Form.Group className="col-12">
          <Button
            type="button"
            onClick={handleComment}
            disabled={!comment.length}
            className="btn btn-warning col-12"
          >
            Send
          </Button>
        </Form.Group>
      </Col> */}
    </Row>
  );
}
