import React, { useRef, useEffect, useState, useContext } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useFormik } from "formik";

import { Toast } from "../../layout/Alert";

import VendorTypeService from "../../../services/VendorTypeService";
import VendorService from "../../../services/VendorService";
import AddressService from "../../../services/AddressService";

import { UserContext } from "../../../context/UserContext";
import { PlaceAddForm } from "../../../helper/ValidationHelper";

import Account from "../../layout/Account/Account";

export default function Add() {
    const { userId } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);

    const ref = useRef();
    const [imgPath, setImgPath] = useState('/images/no-photo-small.svg');
    const [selectedImage, setselectedImage] = useState(false);
    const onFileChangeImages = (event) => {
        let input = event.target;
        console.log(input.files[0]);
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                setImgPath(reader.result);
            };
            reader.readAsDataURL(input.files[0]);
            setselectedImage(input.files[0]);
            formik.setFieldValue("image", input.files[0].name);
        }
    };
    const resetInputFile = () => {
        ref.current.value = "";
        setImgPath('/images/no-photo-small.svg');
    };

    const submitHandler = async (input) => {
        try {
            setIsLoading(true);
            delete input.image;
            input.user = userId;
            input.vendor_type = Number(input.vendor_type);
            let response = await AddressService.create({ data: input });
            if (response.status === 200) {
                input.address = response.data.data.id;
                var formData = new FormData();
                if (selectedImage) {
                    formData.append("files.image", selectedImage);
                }
                formData.append("data", JSON.stringify(input));
                response = await VendorService.create(formData);
                if (response.status === 200) {
                    setIsLoading(false);
                    Toast.fire({
                        icon: "success",
                        title: "Your Place are successfully submitted",
                    });
                }
            } else {
                setIsLoading(false);
                Toast.fire({
                    icon: "warning",
                    title: "Something went wrong. try again later",
                });
            }
        } catch (err) {
            setIsLoading(false);
            let message = err.message;
            // if (err.code === "ERR_BAD_REQUEST") {
            //     message = "Invalid email or password";
            // }
            Toast.fire({ icon: "error", title: message });
        }
    };

    const initialValues = {
        name: "",
        vendor_type: "",
        location: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        phone: "",
        email: "",
        website: "",
        image: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: PlaceAddForm,
        onSubmit: (values, action) => {
            submitHandler(values);
            action.resetForm();
            resetInputFile();
        },
    });

    return (
        <Account>

            <div className="filter-part p-3 mb-3">
                <h2 className="title1 p-0 mb-3">Add a new place</h2>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col lg={3} md={4}>
                            <Row className="mb-3">
                                <Form.Group
                                    controlId="image"
                                    className="col-md-12 mb-3"
                                >
                                    <Form.Label>Image</Form.Label>
                                    <Photo url={imgPath}></Photo>
                                    <Form.Control
                                        ref={ref}
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        onChange={(e) => {
                                            onFileChangeImages(e);
                                        }}
                                        onBlur={formik.handleBlur}
                                        isInvalid={
                                            formik.touched.image && formik.errors.image
                                        }
                                        className="form-control"
                                        required
                                    />
                                </Form.Group>
                            </Row>
                        </Col>
                        <Col lg={9} md={8}>
                            <Row className="mb-3">
                                <Form.Group
                                    className="col-md-7 mb-3"
                                    controlId="name"
                                >
                                    <Form.Label>Name <sup>*</sup></Form.Label>
                                    <Form.Control
                                        name="name"
                                        type="text"
                                        autoComplete="off"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                        isInvalid={
                                            formik.touched.name && formik.errors.name
                                        }
                                        className="form-control"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="col-md-5 mb-3"
                                    controlId="vendor_type"
                                >
                                    <Form.Label>Place Type <sup>*</sup></Form.Label>
                                    <Form.Select
                                        name="vendor_type"
                                        autoComplete="off"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.vendor_type}
                                        isInvalid={
                                            formik.touched.vendor_type &&
                                            formik.errors.vendor_type
                                        }
                                        className="form-control"
                                        required
                                    >
                                        <option></option>
                                        <VendorType></VendorType>
                                    </Form.Select>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group
                                    className="col-md-12 mb-3"
                                    controlId="location"
                                >
                                    <Form.Label>Address <sup>*</sup></Form.Label>
                                    <Form.Control
                                        name="location"
                                        type="text"
                                        autoComplete="off"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.location}
                                        isInvalid={
                                            formik.touched.location && formik.errors.location
                                        }
                                        className="form-control"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="col-md-6 mb-3"
                                    controlId="city"
                                >
                                    <Form.Label>City <sup>*</sup></Form.Label>
                                    <Form.Control
                                        name="city"
                                        type="text"
                                        autoComplete="off"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.city}
                                        isInvalid={
                                            formik.touched.city && formik.errors.city
                                        }
                                        className="form-control"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="col-md-6 mb-3"
                                    controlId="state"
                                >
                                    <Form.Label>State <sup>*</sup></Form.Label>
                                    <Form.Control
                                        name="state"
                                        type="text"
                                        autoComplete="off"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.state}
                                        isInvalid={
                                            formik.touched.state && formik.errors.state
                                        }
                                        className="form-control"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="col-md-6 mb-3"
                                    controlId="postal_code"
                                >
                                    <Form.Label>Postal Code <sup>*</sup></Form.Label>
                                    <Form.Control
                                        name="postal_code"
                                        type="text"
                                        autoComplete="off"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.postal_code}
                                        isInvalid={
                                            formik.touched.postal_code &&
                                            formik.errors.postal_code
                                        }
                                        className="form-control"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="col-md-6 mb-3"
                                    controlId="country"
                                >
                                    <Form.Label>Country <sup>*</sup></Form.Label>
                                    <Form.Control
                                        name="country"
                                        type="text"
                                        autoComplete="off"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.country}
                                        isInvalid={
                                            formik.touched.country && formik.errors.country
                                        }
                                        className="form-control"
                                        required
                                    />
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group
                                    className="col-md-5 mb-3"
                                    controlId="phone"
                                >
                                    <Form.Label>Phone <sup>*</sup></Form.Label>
                                    <Form.Control
                                        name="phone"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.phone}
                                        isInvalid={
                                            formik.touched.phone && formik.errors.phone
                                        }
                                        className="form-control"
                                        required
                                    />
                                </Form.Group>

                                <Form.Group
                                    className="col-md-7 mb-3"
                                    controlId="email"
                                >
                                    <Form.Label>Email <sup>*</sup></Form.Label>
                                    <Form.Control
                                        name="email"
                                        type="email"
                                        autoComplete="off"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        isInvalid={
                                            formik.touched.email && formik.errors.email
                                        }
                                        className="form-control"
                                        required
                                    />
                                </Form.Group>

                                <Form.Group
                                    className="col-md-12 mb-3"
                                    controlId="website"
                                >
                                    <Form.Label>Website <sup>*</sup></Form.Label>
                                    <Form.Control
                                        name="website"
                                        type="text"
                                        autoComplete="off"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.website}
                                        isInvalid={
                                            formik.touched.website && formik.errors.website
                                        }
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="col-md-12 mb-3 d-flex">
                                    <Button
                                        size="lg"
                                        type="submit"
                                        disabled={isLoading}
                                        className="btn btn-warning"
                                    >
                                        {isLoading ? (
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            "Submit"
                                        )}
                                    </Button>
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </div>

        </Account>
    );
}

function VendorType() {
    const [data, setData] = useState([]);

    async function getAll() {
        const response = await VendorTypeService.getAll({ fields: ["name"] });
        if (response.status === 200) {
            setData(response.data["data"]);
        }
    }

    useEffect(() => {
        try {
            getAll();
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <>
            {data.map((row) => {
                return (
                    <option key={row.id} value={row.id}>
                        {row.attributes.name}
                    </option>
                );
            })}
        </>
    );
}

function Photo({ url }) {
    return (
        <div className="w-100 text-center">
            <img alt="" src={url} className="image w-100" border="0" />
        </div>
    );
}