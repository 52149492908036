import React from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";

function Faq() {
  return (
    <>
      <div className="main-wrap dashboard-prt">
        <Container>
          <h2 className="main-title">Frequently Asked Questions</h2>
          <Row className="mt-5">
            <Col md={{ span: 10, offset: 1 }}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  {/* <Accordion.Header>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body> */}
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    How do I contact a site administrator?
                  </Accordion.Header>
                  <Accordion.Body>
                    James Divine - ceo.hotspicygirls@gmail.com
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Who do I address legal questions?
                  </Accordion.Header>
                  <Accordion.Body>
                    Registered Agent for Hot Spicy Girls LLC CALIFORNIA AGENT
                    Zen Business Inc. 2520 Venture Oaks Way Suite 120
                    Sacramento, CA 95833 844-493-6249
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    consectetur adipiscing elit eiusmod tempor?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    consectetur adipiscing elit eiusmod tempor?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Faq;
