import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse';
import { Form } from 'react-bootstrap';

const CunnilingusFilter = ({ handleChange,cunnilingus }) => {

    const [open10, setOpen10] = useState(true);

    return (
        <div className="category-list mb-3">
            <div className="expand" onClick={() => setOpen10(!open10)} aria-controls="category10" aria-expanded={open10}>
                <div className="right-arrow"><i className='fa fa-angle-down'></i></div>
                <div><h3 className="hd3">Cunnilingus</h3></div>
            </div>
            <Collapse className="category-body" id="category10" in={open10}>
                <ul>
                    <li>
                        <Form.Check inline className="cat-checkbox" type="radio" name="cunnilingus" id="cunnilingus1" label={`Yes (${cunnilingus[0]?.Yes})`} value="yes" onChange={handleChange} />
                    </li>
                    <li>
                        <Form.Check inline className="cat-checkbox" type="radio" name="cunnilingus" id="cunnilingus2" label={`No (${cunnilingus[0]?.No})`} value="no" onChange={handleChange} />
                    </li>
                    <li>
                        <Form.Check inline className="cat-checkbox" type="radio" name="cunnilingus" id="cunnilingus3" label={`N/A`} value="" onChange={handleChange} />
                    </li>
                </ul>
            </Collapse>
        </div>

    )
}

export default CunnilingusFilter