import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class RaceService {

    get(payload) {
        return axios.get(HttpHelper.API_URL + '/races', { params: payload }, HttpHelper.getHeader());
    }

}

export default new RaceService();