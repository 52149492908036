import axios from "axios";
import HttpHelper from "../helper/HttpHelper";

class ProcessingCostServices {
  getAll(payload) {
    return axios.get(
      HttpHelper.API_URL + "/processing-costs",
      { params: payload },
      HttpHelper.getHeader()
    );
  }

  getOne(id, payload) {
    return axios.get(
      HttpHelper.API_URL + "/processing-costs/" + id,
      { params: payload },
      HttpHelper.getHeader()
    );
  }

  create(payload) {
    return axios.post(
      HttpHelper.API_URL + "/processing-costs",
      payload,
      HttpHelper.getAuthHeader()
    );
  }

  update(id, payload) {
    return axios.put(
      HttpHelper.API_URL + "/processing-costs/" + id,
      payload,
      HttpHelper.getAuthHeader()
    );
  }

  delete(id) {
    return axios.delete(
      HttpHelper.API_URL + "/processing-costs/" + id,
      HttpHelper.getAuthHeader()
    );
  }
}

export default ProcessingCostServices = new ProcessingCostServices();
