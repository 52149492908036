import React, { useRef, useEffect, useState, useContext } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";

import { Toast } from "../../layout/Alert";

import VendorTypeService from "../../../services/VendorTypeService";
import ProductService from "../../../services/ProductService";

import { UserContext } from "../../../context/UserContext";
import { ProductAddForm } from "../../../helper/ValidationHelper";

import Account from "../../layout/Account/Account";
import UploadService from "../../../services/UploadService";
import { useNavigate } from "react-router-dom";

export default function ProductAdd() {
  const { userId } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();
  const [imgPath, setImgPath] = useState("/images/no-photo-small.svg");
  const [selectedImage, setselectedImage] = useState(false);
  const onFileChangeImages = (event) => {
    let input = event.target;
    console.log(input.files[0]);
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        setImgPath(reader.result);
      };
      reader.readAsDataURL(input.files[0]);
      setselectedImage(input.files);
      formik.setFieldValue("picture", input.files[0].name);
    }
  };
  const resetInputFile = () => {
    ref.current.value = "";
    setImgPath("/images/no-photo-small.svg");
  };

  const submitHandler = async (input) => {
    try {
      if (selectedImage.length > 0) {
        setIsLoading(true);
        input.user = userId;
        // delete input.picture;
        var formData = new FormData();
        console.log("file upload ", selectedImage);
        Array.from(selectedImage).map((data, i) => {
          formData.append("files", data);
        });
        let responseImage = await UploadService.upload(formData);
        if (responseImage.status === 200) {
          if (responseImage.data.length > 0) {
            input["picture"] = responseImage.data.map((res) => res.id);
          }
        } else {
          Toast.fire({
            icon: "warning",
            title: "Something went wrong. try again later",
          });
        }
        // formData.append("data", JSON.stringify(input));
        const response = await ProductService.create({ data: input });
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        } else {
          setIsLoading(false);
          Toast.fire({
            icon: "success",
            title: "Your Product are successfully added",
          });
          navigate("/products/view");
        }
      } else {
        Toast.fire({
          icon: "warning",
          title: "Please select a image",
        });
      }
    } catch (err) {
      setIsLoading(false);
      Toast.fire({ icon: "error", title: "Network response was not ok" });
    }
  };

  const initialValues = {
    name: "",
    description: "",
    price: "",
    picture: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ProductAddForm,
    onSubmit: (values, action) => {
      submitHandler(values);
      action.resetForm();
      resetInputFile();
    },
  });

  return (
    <Account>
      <div className="filter-part p-3 mb-3">
        <h2 className="title1 p-0 mb-3">Add New Product</h2>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={4} md={4}>
              <Row className="mb-3">
                <Form.Group controlId="image" className="col-md-12 mb-3">
                  <Form.Label>Image</Form.Label>
                  <Photo url={imgPath}></Photo>
                  <Form.Control
                    ref={ref}
                    type="file"
                    name="picture"
                    accept="image/*"
                    onChange={(e) => {
                      onFileChangeImages(e);
                    }}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.picture && formik.errors.picture}
                    className="form-control"
                    required
                  />
                </Form.Group>
              </Row>
            </Col>
            <Col lg={8} md={8}>
              <Row className="mb-3">
                <Form.Group className="col-md-12 mb-3" controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="name"
                    type="text"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    isInvalid={formik.touched.name && formik.errors.name}
                    className="form-control"
                    required
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group className="col-mb-12 mb-3" controlId="name">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    name="description"
                    type="text"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    isInvalid={
                      formik.touched.description && formik.errors.description
                    }
                    className="form-control"
                    required
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group className="col-mb-12 mb-3" controlId="name">
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    name="price"
                    type="text"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.price}
                    isInvalid={formik.touched.price && formik.errors.price}
                    className="form-control"
                    required
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group className="col-md-12 mb-3 d-flex">
                  <Button
                    size="lg"
                    type="submit"
                    disabled={isLoading}
                    className="btn btn-warning"
                  >
                    {isLoading ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Form.Group>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </Account>
  );
}

function VendorType() {
  const [data, setData] = useState([]);

  async function getAll() {
    const response = await VendorTypeService.getAll({ fields: ["name"] });
    if (response.status === 200) {
      setData(response.data["data"]);
    }
  }

  useEffect(() => {
    try {
      getAll();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      {data.map((row) => {
        return (
          <option key={row.id} value={row.id}>
            {row.attributes.name}
          </option>
        );
      })}
    </>
  );
}

function Photo({ url }) {
  return (
    <div className="w-100 text-center">
      <img alt="" src={url} className="image w-100" border="0" />
    </div>
  );
}
