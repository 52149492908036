import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse';
import { Form } from 'react-bootstrap';


const ThreesomeFIlter = ({ handleChange,threesome }) => {

    const [open15, setOpen15] = useState(true);

    return (
        <div className="category-list mb-3">
            <div className="expand" onClick={() => setOpen15(!open15)} aria-controls="category15" aria-expanded={open15}>
                <div className="right-arrow"><i className='fa fa-angle-down'></i></div>
                <div><h3 className="hd3">Threesome</h3></div>
            </div>
            <Collapse className="category-body" id="category15" in={open15}>
                <ul>
                    <li>
                        <Form.Check inline className="cat-checkbox" type="radio" name="threesome" id="threesome1" label={`Yes (${threesome[0]?.Yes})`} value="yes" onChange={handleChange} />
                    </li>
                    <li>
                        <Form.Check inline className="cat-checkbox" type="radio" name="threesome" id="threesome2" label={`No (${threesome[0]?.No})`} value="no" onChange={handleChange} />
                    </li>
                    <li>
                        <Form.Check inline className="cat-checkbox" type="radio" name="threesome" id="threesome3" label="N/A" value="" onChange={handleChange} />
                    </li>
                </ul>
            </Collapse>
        </div>
    )
}

export default ThreesomeFIlter;