import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import Account from "../layout/Account/Account";

import { UserContext } from "../../context/UserContext";
import HttpHelper from "../../helper/HttpHelper";

function ProfileView() {
  const url = HttpHelper.BASE_URL;
  const { init, user } = useContext(UserContext);

  const [avatar, setavatar] = useState(init.user.avatar.url);

  useEffect(() => {
    if (user && user?.avatar !== null && user?.avatar?.id > 0) {
      if (user?.avatar?.formats !== null) {
        setavatar(url + user?.avatar?.formats?.thumbnail?.url);
      } else {
        setavatar(url + user?.avatar?.url);
      }
    }
  }, [user]);

  return (
    <Account>
      <div className="filter-part p-3 mb-3">
        <h3 className="title1">My Profile</h3>
        <Row className="mt-4">
          <Col md={4}>
            <div className="lft-profile-prt">
              <div className="file-upload">
                {/* <div className="file-upload-content">
                                                    <img className="file-upload-image" src="#" alt="your image" />
                                                </div> */}

                <div className="image-upload-wrap">
                  <input
                    className="file-upload-input"
                    type="file"
                    accept="image/*"
                  />
                  <div className="drag-img">
                    <img className="img-fluid" src={avatar} />
                  </div>
                </div>

                {/* <button className="file-upload-btn" type="button" onclick="$('.file-upload-input').trigger( 'click' )"><i className="fa fa-camera"></i></button> */}
              </div>
              <br />
              <Link to="/profile/edit" className="btn-subscribe mt-5">
                EDIT PROFILE
              </Link>
            </div>
          </Col>
          <Col md={8}>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="custom-fld">
                <h4>
                  Name{" "}
                  <span>
                    {user.first_name} {user.last_name}
                  </span>
                </h4>
              </div>
              <div className="custom-fld">
                <h4>
                  Address <span>{user.city}</span>
                </h4>
              </div>
              <div className="custom-fld">
                <h4>
                  Date of Birth<span>{user.dob}</span>
                </h4>
              </div>
              <div className="custom-fld">
                <h4>
                  Phone Number <span>{user.phone_number}</span>
                </h4>
              </div>
              <div className="custom-fld">
                <h4>
                  Email Id <span>{user.email}</span>
                </h4>
              </div>
              <div className="custom-fld">
                <h4>
                  User Name <span>{user.username}</span>
                </h4>
              </div>
              <div className="custom-fld">
                <h4>
                  Role <span>{user.user_type}</span>
                </h4>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Account>
  );
}

export default ProfileView;
