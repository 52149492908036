import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse';
import { Form } from 'react-bootstrap';

const LanguageFilter = ({lang, handleChange}) => {

   
    const [open, setOpen] = useState(true);
    const [isYes, setIsYes] = useState(false);
    const [isNo, setIsNo] = useState(false);
    const [languageChecked, setLanguageChecked] = useState([{}]);
    const handleClickCheckbox = (event) => {
        console.log('handleClickCheckbox')
        handleChange(event);
        const { value } = event.target;
        if(value){
            const prevState = { ...languageChecked, [value]: !languageChecked[value] };
            setLanguageChecked(prevState);
        }else{
            if(event.target.checked){
                const prevState = [];
                setLanguageChecked(prevState);
            }else{
                const prevState = { ...languageChecked, ['test']: !languageChecked['test'] };
                setLanguageChecked(prevState);
            }

        }
        console.log('lang check uncheck is call ',languageChecked)
    };
    return (
        <div className="category-list mb-3">
            <div className="expand" onClick={() => setOpen(!open)} aria-controls="category1" aria-expanded={open}>
                <div className="right-arrow"><i className='fa fa-angle-down'></i></div>
                <div><h3 className="hd3">Language</h3></div>
            </div>
            <Collapse className="category-body" id="category1" in={open}>
                <ul>
                    {lang.map((row) => (
                        <li key={row.id}>
                            {row.lang_name === "N/A" ?
                                <Form.Check inline className="cat-checkbox" id={row.lang_name + row.id} label={row.lang_name} value="" onChange={handleClickCheckbox} name="language"  checked={languageChecked?.length === 0 ? true : false} /> :
                                <Form.Check inline className="cat-checkbox" id={row.lang_name + row.id} label={row.lang_name+' ('+row.provider+')'} value={row.id} onChange={handleClickCheckbox} name="language" checked={languageChecked[row.id]} />
                            }
                        </li>
                    ))}
                </ul>
            </Collapse>
        </div>
    )
}

export default LanguageFilter