import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse';
import { Form } from 'react-bootstrap';


const MassageFilter = ({ handleChange,massage }) => {

    const [open16, setOpen16] = useState(true);

    return (
        <div className="category-list mb-3">
            <div className="expand" onClick={() => setOpen16(!open16)} aria-controls="category16" aria-expanded={open16}>
                <div className="right-arrow"><i className='fa fa-angle-down'></i></div>
                <div><h3 className="hd3">Massage</h3></div>
            </div>
            <Collapse className="category-body" id="category16" in={open16}>
                <ul>
                    <li>
                        <Form.Check inline className="cat-checkbox" type="radio" name="massage" id="massage1" label={`Yes (${massage[0]?.Yes})`} value="yes" onChange={handleChange} />
                    </li>
                    <li>
                        <Form.Check inline className="cat-checkbox" type="radio" name="massage" id="massage2" label={`No (${massage[0]?.No})`} value="no" onChange={handleChange} />
                    </li>
                    <li>
                        <Form.Check inline className="cat-checkbox" type="radio" name="massage" id="massage3" label="N/A" value="" onChange={handleChange} />
                    </li>
                </ul>
            </Collapse>
        </div>
    )
}
export default MassageFilter;