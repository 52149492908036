import React, { useContext } from "react";
import { NavLink, Link } from "react-router-dom";

import { UserContext } from "../../../context/UserContext";

function LeftSidebar() {
  const { logout, isVender, isHobbyist, isProvider } = useContext(UserContext);

  return (
    <div className="sidebar p-3 mb-3">
      <ul>
        <li>
          <NavLink to="/profile/view">
            <div className="icon_menu">
              <i className="fas fa-user"></i>
            </div>
            My Profile
          </NavLink>
        </li>
        {isProvider() && (
          <>
            <li>
              <NavLink to="/ads/view">
                <div className="icon_menu">
                  <i className="fas fa-images"></i>
                </div>
                My Ads
              </NavLink>
            </li>
            <li>
              <NavLink to="/place/view">
                <div className="icon_menu">
                  <i className="fas fa-map-marker"></i>
                </div>
                My Places
              </NavLink>
            </li>
            <li>
              <NavLink to="/provider/wallet">
                <div className="icon_menu">
                  <i className="fas fa-wallet"></i>
                </div>
                My Wallet
              </NavLink>
            </li>
            <li>
              <NavLink to="/provider/subscription">
                <div className="icon_menu">
                  <i class="fas fa-credit-card"></i>
                </div>
                My Subscription
              </NavLink>
            </li>
            <li>
              <NavLink to="/provider/ratehobyist">
                <div className="icon_menu">
                  <i class="fas fa-comment"></i>
                </div>
                Screen Hobbyists (Rate/Comment on Hobbyists)
              </NavLink>
            </li>
            <li>
              {/* <NavLink to="/provider/subscription"> */}
              <a href="mailto:ceo.hotspicygirls@gmail.com">
                <div className="icon_menu">
                  <i class="fas fa-at"></i>
                </div>
                Review Mail
              </a>
              {/* </NavLink> */}
            </li>
          </>
        )}
        {isHobbyist() || isVender() ? (
          <li>
            <NavLink to="/orders/view">
              <div className="icon_menu">
                <i className="fas fa-list"></i>
              </div>
              My Orders
            </NavLink>
          </li>
        ) : null}
        {isVender() ? (
          <li>
            <NavLink to="/products/view">
              <div className="icon_menu">
                <i className="fas fa-box"></i>
              </div>
              My Products
            </NavLink>
          </li>
        ) : null}
        <li>
          <NavLink to="/profile/changepassword">
            <div className="icon_menu">
              <i className="fas fa-key"></i>
            </div>
            Change Password
          </NavLink>
        </li>
        {/* <li>
                    <Link onClick={() => logout()}>
                        <div className="icon_menu">
                            <i className="fa fa-power-off"></i>
                        </div>
                        Logout
                    </Link>
                </li> */}
      </ul>
    </div>
  );
}

export default LeftSidebar;
