import axios from "axios";
import HttpHelper from "../helper/HttpHelper";

class FilterService {
  get(payload) {
    return axios.get(
      HttpHelper.API_URL + "/filterdata",
      { params: payload },
      HttpHelper.getHeader()
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default FilterService = new FilterService();
