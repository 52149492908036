import React, { useState, useEffect, useContext, useMemo } from "react";
import { Form, Button, Modal, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

import HttpHelper from "../../../helper/HttpHelper";
import ProviderService from "../../../services/ProviderService";
import { UserContext } from "../../../context/UserContext";

import Loader from "../../layout/Loader";
import { Toast } from "../../layout/Alert";
import SubscriptionServices from "../../../services/SubscriptionServices";

export default function AdsList() {
  const { userId, user } = useContext(UserContext);

  const activeSubscriptions = useMemo(() => {
    if (user?.subscriptions) {
      return user?.subscriptions?.filter((subscription) => {
        return subscription?.isActive === true;
      });
    } else {
      return [];
    }
  }, [user?.subscriptions]);

  const [data, setData] = useState([]);
  const [visible, setvisible] = useState(true);
  const [numberOfAds, setNumberOfAds] = useState(true);
  const [query, setQuery] = useState({
    filters: {
      user: {
        id: {
          $eq: userId,
        },
      },
    },
    populate: "*",
    pagination: { pageSize: 10 },
    sort: ["id:desc"],
  });

  const handlePerPageOnchange = (event) => {
    let tempQuery = query;
    tempQuery.pagination.pageSize = event.target.value;
    setQuery(tempQuery);
    getAll();
    //console.log(event.target.value)
  };

  async function getAll() {
    try {
      const response = await ProviderService.getAll(query);
      if (response.status === 200) {
        if (response.data.data) {
          setData(response.data.data);
        }
      }
      setvisible(false);
    } catch (error) {
      setvisible(false);
      let message = error.message;
      Toast.fire({ icon: "error", title: message });
    }
  }

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [itemId, setitemId] = useState(0);
  const [show, setShow] = useState(false);
  const handleShow = (id) => {
    setShow(true);
    setitemId(id);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleAction = async () => {
    handleClose();
    // console.log(itemId);
    const response = await ProviderService.delete(itemId);
    if (response.status === 200) {
      Toast.fire({
        icon: "success",
        title: "Your Ad are successfully deleted",
      });
      getAll();
    }
  };

  async function fetchPackageDetails() {
    let packageRes = await SubscriptionServices.getOne(
      activeSubscriptions[0].id,
      {
        populate: ["package"],
      }
    );
    if (packageRes.status === 200) {
      // console.log("Res", packageRes?.data?.data?.attributes?.package?.data);
      setNumberOfAds(packageRes?.data?.data?.attributes?.package?.data);
    }
  }

  useEffect(() => {
    // console.log(activeSubscriptions);
    if (activeSubscriptions.length) {
      fetchPackageDetails();
    }
  }, [activeSubscriptions]);

  const remainingAds = useMemo(() => {
    return parseInt(
      parseInt(numberOfAds?.attributes?.number_of_ads_per_day) - data.length
    );
  }, [data.length, numberOfAds?.attributes?.number_of_ads_per_day]);
  // console.log('DATA>>>>',data);
  return (
    <>
      <Loader visible={visible}>
        <div className="filter-part p-3 mb-3">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className="title1 p-0">My Ads</h3>
            <h4 className="m-0">Ads Posted :</h4>
            <Badge bg="primary">
              {data.length}/{numberOfAds?.attributes?.number_of_ads_per_day}
            </Badge>
            <h4 className="m-0">Remaining Ads :</h4>
            <Badge bg="primary">{remainingAds}</Badge>
            <div className="rt-filter-prt d-flex align-items-center justify-content-end">
              <Form.Select onChange={handlePerPageOnchange} size="sm">
                <option value={10}>10 Per Page</option>
                <option value={20}>20 Per Page</option>
                <option value={30}>30 Per Page</option>
                <option value={40}>40 Per Page</option>
              </Form.Select>
              {remainingAds !== 0 ? (
                <Link to={"/ads/submit"} className="btn btn-success">
                  + Post Ads
                </Link>
              ) : (
                <span style={{ color: "red", fontWeight: 'bold' }}>
                  Please contact to admin to refill your number of ads
                </span>
              )}
            </div>
          </div>
          <hr />

          {data.map((rows) => {
            // console.log(rows.attributes);
            const attr = rows?.attributes;
            const images = rows?.attributes?.images;
            return (
              <div className="card-wrapper" key={rows.id}>
                <div className="card-view d-flex">
                  <div className="info info-left flex-grow-1">
                    <div className="image-wrapper">
                      <Photo images={images}></Photo>
                    </div>

                    <div className="info">
                      <h6 className="title color-link weight-600 m-b-5">
                        {attr?.title}
                      </h6>
                      <About data={attr} />
                      <Address data={attr} />
                      <p>{attr?.phone}</p>
                    </div>
                  </div>
                  <div className="info-right d-flex align-items-center justify-content-between">
                    <Link
                      title="Edit"
                      to={"/ads/edit/" + rows.id}
                      className="btn btn-outline-light text-dark"
                    >
                      <i className="fas fa-edit"></i>
                    </Link>
                    <Button
                      title="Delete"
                      variant="outline-light"
                      onClick={() => handleShow(rows.id)}
                      className="btn btn-outline-light text-dark"
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Loader>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Delete this place?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleAction}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function Photo(props) {
  const url = HttpHelper.BASE_URL;
  if (props.images.data) {
    let img = props.images.data[0].attributes;
    if (img.formats) {
      return (
        <img
          alt={img.formats.thumbnail.name}
          src={url + img.formats.thumbnail.url}
          className="image"
          border="0"
        />
      );
    }
    return (
      <img alt={img.name} src={url + img.url} className="image" border="0" />
    );
  }
  return (
    <img
      alt="no"
      src="/images/no-photo-small.svg"
      className="image"
      border="0"
    />
  );
}

function About(props) {
  if (props.data !== null) {
    let attr = props.data;
    return (
      <p>
        {attr.age} year old, {attr.language.data.attributes.lang_name},{" "}
        {attr.race.data.attributes.race_name}, {attr.personality},{" "}
        {attr.grooming}
      </p>
    );
  }
  return null;
}

function Address(props) {
  if (props.data !== null) {
    let attr = props.data;
    return <p>{[attr.city, attr.state, attr.country].join(", ")}</p>;
  }
  return null;
}
