import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class AddressService {

    getAll(payload) {
        return axios.get(HttpHelper.API_URL + '/addresses', { params: payload }, HttpHelper.getHeader());
    }

    getOne(id, payload) {
        return axios.get(HttpHelper.API_URL + '/addresses/' + id, { params: payload }, HttpHelper.getHeader());
    }

    create(payload) {
        return axios.post(HttpHelper.API_URL + '/addresses', payload, HttpHelper.getAuthHeader());
    }

    update(id, payload) {
        return axios.put(HttpHelper.API_URL + "/addresses/" + id, payload, HttpHelper.getAuthHeader());
    }

    delete(id) {
        return axios.delete(HttpHelper.API_URL + "/addresses/" + id, HttpHelper.getAuthHeader());
    }

}

export default new AddressService();