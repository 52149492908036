import axios from "axios";
import HttpHelper from "../helper/HttpHelper";

class UploadService {
  upload(payload) {
    return axios.post(
      HttpHelper.API_URL + "/upload",
      payload,
      HttpHelper.getAuthHeader()
    );
  }
  uploadWithoutAuth(payload) {
    return axios.post(
      HttpHelper.API_URL + "/upload",
      payload,
      HttpHelper.getHeaderMultipart()
    );
  }

  getOne(id) {
    return axios.get(
      HttpHelper.API_URL + "/upload/files/" + id,
      HttpHelper.getAuthHeader()
    );
  }

  delete(id) {
    return axios.delete(
      HttpHelper.API_URL + "/upload/files/" + id,
      HttpHelper.getAuthHeader()
    );
  }
}

export default new UploadService();
