import React, { useState, useContext } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";

import { Toast } from "../layout/Alert";

import AuthService from "../../services/AuthService";
// import UserService from "../../services/UserService";
import { UserContext } from "../../context/UserContext";
import { LoginForm } from "../../helper/ValidationHelper";
import UserService from "../../services/UserService";

function Login() {
  // const { updateUser, updateToken, updateUserId } = useContext(UserContext);
  const { updateToken, updateUserId, updateIsSubscribed } =
    useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (formvalues) => {
    try {
      setIsLoading(true);
      let input = {};
      input.identifier = formvalues.email;
      input.password = formvalues.password;
      let response = await AuthService.Login(input);
      if (response.status === 200) {
        updateToken(response.data.jwt);
        updateUserId(response.data.user.id);
        let userRes = await UserService.getOne(response.data.user.id, {
          populate: "*",
        });
        if (userRes.status === 200) {
          console.log("RES>>>>", userRes.data.subscriptions);
          if (userRes?.data?.subscriptions.length) {
            updateIsSubscribed(true);
          } else {
            updateIsSubscribed(false);
          }
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        Toast.fire({
          icon: "warning",
          title: "Something went wrong. try again later",
        });
      }
    } catch (err) {
      setIsLoading(false);
      let message = err.message;
      if (err.code === "ERR_BAD_REQUEST") {
        message = "Invalid email or password";
      }
      Toast.fire({ icon: "error", title: message });
    }
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: LoginForm,
    onSubmit: (values, action) => {
      submitHandler(values);
      action.resetForm();
    },
  });

  return (
    <>
      <div className="main-wrap">
        <Container>
          <h2 className="main-title">Login</h2>
          <Row className="mt-5">
            <Col lg={{ span: 6, offset: 3 }} md={12}>
              <div className="sign-form-wrap">
                <div className="signin-form">
                  <Form
                    noValidate
                    onSubmit={formik.handleSubmit}
                    className="row"
                  >
                    <Form.Group className="col-md-12 mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        autoComplete="off"
                        placeholder="example@gmail.com"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        isInvalid={formik.touched.email && formik.errors.email}
                        className="form-control"
                        required
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <span className="text-danger small">
                          <strong>{formik.errors.email}</strong>
                        </span>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="col-md-12 mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        isInvalid={
                          formik.touched.password && formik.errors.password
                        }
                        className="form-control"
                        required
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <span className="text-danger small">
                          <strong>{formik.errors.password}</strong>
                        </span>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="col-md-12 mb-3">
                      <div className="login-forgot-pass">
                        <Link
                          className="forgot-password-btn"
                          to="/forgotPassword"
                        >
                          Forgot Password ?
                        </Link>
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="col-md-12 mb-3 d-flex"
                      controlId="Remember"
                    >
                      <Form.Check name="Remember" aria-label="Remember" />
                      <Form.Check.Label className="ms-2" required>
                        Remember Me
                      </Form.Check.Label>
                    </Form.Group>
                    <Form.Group className="col-md-12 mb-3 d-flex align-items-center">
                      {/* <Button className="btn btn-warning" type="submit">Submit</Button> */}
                      <Button
                        disabled={isLoading}
                        type="submit"
                        className="btn btn-warning"
                      >
                        {isLoading ? (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Sign In"
                        )}
                      </Button>
                      <p className="ms-3 mb-0">
                        Don't have an account?{" "}
                        <Link to="/Register">Register</Link>
                      </p>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
