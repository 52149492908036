import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import HttpHelper from "../../helper/HttpHelper";
import vendorService from "../../services/VendorService";
import RightSidebar from "../layout/RightSidebar";
import LeftSidebar from "../layout/LeftSidebar";
import Loader from "../layout/Loader";

import { UserContext } from "../../context/UserContext";
// import PaginationComponent from "../layout/PaginationComponent";

export default function List(props) {
    const url = HttpHelper.BASE_URL;

    const { isLogin, isProvider } = useContext(UserContext);

    const [data, setData] = useState([]);
    const [visible, setvisible] = useState(true);
    const [query, setQuery] = useState({
        filters: {
            vendor_type: {
                type: {
                    $eq: props.type,
                },
            },
        },
        populate: "*",
        pagination: { pageSize: 10 },
        sort: ['id:desc']
    })

    const handlePerPageOnchange = (event) => {
        let tempQuery = query
        tempQuery.pagination.pageSize = event.target.value;
        setQuery(tempQuery)
        getAll();
        //console.log(event.target.value)
    }

    async function getAll() {
        try {
            setvisible(true);

            const response = await vendorService.getAll(query);
            if (response.status === 200) {
                setData(response.data["data"]);
                //console.log("Response",response.data.meta.pagination)
            }
            setvisible(false);
        } catch (error) {
            setvisible(false);
        }
    }

    useEffect(() => {
        getAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="main-wrap">
                <Container>
                    <Row>
                        {props.category && (
                            <Col md={2}>
                                <LeftSidebar category={props.category} />
                            </Col>
                        )}

                        <Col md={props.category ? 7 : 9}>
                            <Loader visible={visible}>
                                <div className="filter-part">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="w-100">
                                            <h4>40 San Jose Erotic Massage Parlors</h4>
                                            <div className="sort-buttons d-flex align-items-center flex-wrap">
                                                <a
                                                    href="#"
                                                    title="Sort by Rating"
                                                    className="text-decoration-none item d-flex align-items-center weight-600 f-s-12 me-4"
                                                >
                                                    <svg
                                                        width="10"
                                                        height="15"
                                                        viewBox="0 0 10 15"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <title>sort-arrows</title>
                                                        <path
                                                            d="M5 15L0.669873 9L9.33013 9L5 15Z"
                                                            id="up-arrow"
                                                            fill="currentColor"
                                                        ></path>
                                                        <path
                                                            d="M5 0L9.33013 6H0.669873L5 0Z"
                                                            id="down-arrow"
                                                            fill="currentColor"
                                                        ></path>
                                                    </svg>
                                                    <span className="text text-uppercase ps-2">Rating</span>
                                                </a>

                                                <a
                                                    href="#"
                                                    title="Sort by # of Reviews"
                                                    className=" text-decoration-none item d-flex align-items-center weight-600 f-s-12 me-4"
                                                >
                                                    <svg
                                                        width="10"
                                                        height="15"
                                                        viewBox="0 0 10 15"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <title>sort-arrows</title>
                                                        <path
                                                            d="M5 15L0.669873 9L9.33013 9L5 15Z"
                                                            id="up-arrow"
                                                            fill="currentColor"
                                                        ></path>
                                                        <path
                                                            d="M5 0L9.33013 6H0.669873L5 0Z"
                                                            id="down-arrow"
                                                            fill="currentColor"
                                                        ></path>
                                                    </svg>
                                                    <span className="text text-uppercase ps-2">
                                                        # of Reviews
                                                    </span>
                                                </a>

                                                <a
                                                    href="#"
                                                    title="Sort by Review date"
                                                    className=" text-decoration-none item d-flex align-items-center weight-600 f-s-12 me-4"
                                                >
                                                    <svg
                                                        width="10"
                                                        height="15"
                                                        viewBox="0 0 10 15"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <title>sort-arrows</title>
                                                        <path
                                                            d="M5 15L0.669873 9L9.33013 9L5 15Z"
                                                            id="up-arrow"
                                                            fill="currentColor"
                                                        ></path>
                                                        <path
                                                            d="M5 0L9.33013 6H0.669873L5 0Z"
                                                            id="down-arrow"
                                                            fill="currentColor"
                                                        ></path>
                                                    </svg>
                                                    <span className="text text-uppercase ps-2">
                                                        Review date
                                                    </span>
                                                </a>

                                                <a
                                                    href="#"
                                                    title="Sort by Videos"
                                                    className=" text-decoration-none item d-flex align-items-center weight-600 f-s-12 me-4"
                                                >
                                                    <svg
                                                        width="10"
                                                        height="15"
                                                        viewBox="0 0 10 15"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <title>sort-arrows</title>
                                                        <path
                                                            d="M5 15L0.669873 9L9.33013 9L5 15Z"
                                                            id="up-arrow"
                                                            fill="currentColor"
                                                        ></path>
                                                        <path
                                                            d="M5 0L9.33013 6H0.669873L5 0Z"
                                                            id="down-arrow"
                                                            fill="currentColor"
                                                        ></path>
                                                    </svg>
                                                    <span className="text text-uppercase ps-2">Videos</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="rt-filter-prt d-flex align-items-center justify-content-end">
                                            <Form.Select onChange={handlePerPageOnchange} size="sm">
                                                <option value={10}>10 Per Page</option>
                                                <option value={20}>20 Per Page</option>
                                                <option value={30}>30 Per Page</option>
                                                <option value={40}>40 Per Page</option>
                                            </Form.Select>
                                            {isProvider() && <Link to={"/place/add"} className="btn btn-success"> Add Place</Link>}
                                            {!isLogin() && <Link to={"/login"} className="btn btn-success"> Add Place</Link>}
                                        </div>
                                    </div>
                                    <hr />

                                    {data.map((row) => {
                                        return (
                                            <div className="card-wrapper" key={row.id}>
                                                {/* <Link
                                                to={props.type + "/" + row.id}
                                                className="card-view d-flex"
                                            > */}
                                                <div className="card-view d-flex">
                                                    <div className="info info-left flex-grow-1">
                                                        <div className="image-wrapper">
                                                            <img
                                                                src={
                                                                    row.attributes.image.data
                                                                        ? url +
                                                                        row.attributes.image.data.attributes
                                                                            .formats.thumbnail.url
                                                                        : "/images/no-photo-small.svg"
                                                                }
                                                                alt=""
                                                                className="image"
                                                                border="0"
                                                            />
                                                        </div>
                                                        <div className="info">
                                                            <h6 className="title color-link weight-600 m-b-5">
                                                                {row.attributes.name}
                                                            </h6>
                                                            <p>{row.attributes.phone}</p>
                                                            {row.attributes?.vendor_type && <p>{row.attributes?.vendor_type?.data?.attributes?.type}</p>}
                                                            {row.attributes?.address && <Address
                                                                data={row.attributes?.address?.data}
                                                            />}
                                                        </div>
                                                    </div>
                                                    <div className="info-right d-flex align-items-center justify-content-between">
                                                        <p className="pe-4 m-0">2 Reviews</p>
                                                        <img src="/images/star2.png" alt="" />
                                                    </div>
                                                    {/* </Link> */}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {/* <PaginationComponent
                                    pagination={meta.pagination}
                                    queryUpdate={queryUpdate}
                                    query={query}
                                /> */}
                            </Loader>
                        </Col>

                        <Col md={3}>
                            <RightSidebar />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

function Address(props) {
    if (props.data !== null) {
        let data = props.data.attributes;
        return <p>{[data.location, data.city, data.state, data.postal_code, data.country].join(", ")}</p>;
        // return <p>{[data.location, data.city].join(", ")}</p>;
    }
    return "";
}
