import React, { useState, useContext } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { DobToAge } from "../../helper/UtilityHelper";
import UploadService from "../../services/UploadService";
import { UserContext } from "../../context/UserContext";

function Register() {
  const field = {
    username: "",
    password: "",
    cpassword: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    dob: "",
    city: "",
    user_type: "",
  };

  const [inputs, setInputs] = useState(field);
  const [valid, setvalid] = useState(field);
  // const [validmsg, setvalidmsg] = useState(field);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const { updateToken, updateUserId } = useContext(UserContext);
  const [docPath, setDocPath] = useState([
    "/images/no-photo-small.svg",
    "/images/no-photo-small.svg",
    "/images/no-photo-small.svg",
  ]);
  const [usertype] = useState([
    { id: "Hobbyist", val: "Hobbyist" },
    { id: "Provider", val: "Provider" },
    { id: "Vender", val: "Vender" },
  ]);

  const navigate = useNavigate();

  const onFileChangeDocs = (event) => {
    let input = event.target;
    if (input.files.length > 0) {
      // console.log("docs upload ", input.files);
      setSelectedDocs([]);
      setDocPath([]);
      Array.from(input.files).map((data) => {
        var reader = new FileReader();
        reader.onload = function (e) {
          //setDocPath([reader.result]);
          setDocPath((d) => [...d, reader.result]);
          setSelectedDocs((c) => [...c, data]);
        };
        reader.readAsDataURL(data);
        // console.log(data);
      });
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    let data = inputs;
    // console.log(error);
    if (!error) {
      // console.log(inputs);
      // return false;
      try {
        if (selectedDocs.length > 0) {
          var formDataDocs = new FormData();
          Array.from(selectedDocs).map((data, i) => {
            formDataDocs.append("files", data);
          });
          // console.log("Docs Selected", selectedDocs);
          let responseDocs = await UploadService.uploadWithoutAuth(
            formDataDocs
          );
          if (responseDocs.status === 200) {
            data["docs"] = responseDocs.data.map((res) => res.id);
          }
        }
        const response = await AuthService.Register(data);
        if (response.status === 200) {
          if (response?.data?.user?.user_type === "Provider") {
            await updateUserId(response.data.user.id);
            navigate("/package");
          } else {
            // window.location.reload();
            navigate("/login");
          }
        }
      } catch (error) {
        if (error && error.response.status) {
          const ERROR = error.response.data.error;
          if (Object.keys(ERROR.details).length) {
            ERROR.details.errors.map((row) => {
              const label = row.path[0];
              setvalid((values) => ({ ...values, [label]: true }));
              // setvalidmsg(values => ({ ...values, [label]: row.message }));
            });
          }
          seterrorMsg(ERROR);
          seterror(true);
        }
      }
    }
  }

  function handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    setvalid((values) => ({ ...values, [name]: false }));
    seterror(false);
    let re = "";

    if (name === "dob") {
      // seterror(true);
      let age = DobToAge(value);
      console.log("age", age);
      if (parseInt(age) < 18) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      }
    }
    if (name === "first_name" || name === "last_name") {
      re = /^[A-Za-z]*$/;
      if (
        !re.test(value) ||
        !value.trim() ||
        value.length > 64 ||
        value.length < 2
      ) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      }
    }
    if (name === "city") {
      re = /^[A-Za-z0-9 ,/]*$/;
      if (
        !re.test(value) ||
        !value.trim() ||
        value.length > 100 ||
        value.length < 4
      ) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      }
    }
    if (name === "username") {
      re = /^[A-Za-z0-9_-]*$/;
      if (
        !re.test(value) ||
        !value.trim() ||
        value.length > 64 ||
        value.length < 2
      ) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      }
    }
    if (name === "phone_number") {
      re = /^[0-9-]*$/;
      if (
        !re.test(value) ||
        !value.trim() ||
        value.length > 14 ||
        value.length < 6
      ) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      }
    }
    if (name === "email") {
      re = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
      if (
        !re.test(value) ||
        !value.trim() ||
        value.length > 64 ||
        value.length < 6
      ) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      }
    }
    if (name === "password") {
      // re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,64}$/;
      // if (!re.test(value)) {
      //     seterror(true);
      //     setvalid(values => ({ ...values, [name]: true }));
      // }
      if (!value.trim() || value.length > 64 || value.length < 6) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      } else if (inputs.cpassword !== value) {
        seterror(true);
        setvalid((values) => ({ ...values, cpassword: true }));
      } else {
        setvalid((values) => ({ ...values, password: false }));
        setvalid((values) => ({ ...values, cpassword: false }));
      }
    }
    if (name === "cpassword") {
      if (inputs.password !== value) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      } else {
        setvalid((values) => ({ ...values, password: false }));
        setvalid((values) => ({ ...values, cpassword: false }));
      }
    }
    if (name === "user_type") {
      if (!value) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      } else {
        setShowUpload(value === "Provider");
      }
    }
  }

  return (
    <>
      <div className="main-wrap">
        <Container>
          <h2 className="main-title">Register</h2>

          <Row className="mt-5">
            <Col lg={{ span: 6, offset: 3 }} md={12}>
              <div className="sign-form-wrap">
                <div className="signin-form">
                  <Form
                    autoComplete="off"
                    className="row"
                    onSubmit={handleSubmit}
                  >
                    <Form.Group className="col-md-12 mb-3" controlId="Username">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        name="username"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        isInvalid={valid.username}
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 mb-3" controlId="email">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        name="email"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        isInvalid={valid.email}
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 mb-3" controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        name="password"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        isInvalid={valid.password}
                      />
                      <small>
                        * Min 6 letter, with at least a symbol, upper and lower
                        case letters and a number
                      </small>
                    </Form.Group>
                    <Form.Group
                      className="col-md-12 mb-3"
                      controlId="cpassword"
                    >
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        name="cpassword"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        isInvalid={valid.cpassword}
                      />
                    </Form.Group>
                    <hr />
                    <Form.Group
                      className="col-xd-12 col-md-6 mb-3"
                      controlId="first_name"
                    >
                      <Form.Label>First name</Form.Label>
                      <Form.Control
                        name="first_name"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        isInvalid={valid.first_name}
                      />
                    </Form.Group>
                    <Form.Group
                      className="col-xd-12 col-md-6 mb-3"
                      controlId="last_name"
                    >
                      <Form.Label>Last name</Form.Label>
                      <Form.Control
                        name="last_name"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        isInvalid={valid.last_name}
                      />
                    </Form.Group>
                    <Form.Group
                      className="col-md-12 mb-3"
                      controlId="phone_number"
                    >
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        name="phone_number"
                        type="tel"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        isInvalid={valid.phone_number}
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 mb-3" controlId="dob">
                      <Form.Label>DOB (MM/DD/YYYY)</Form.Label>
                      <Form.Control
                        name="dob"
                        type="date"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        isInvalid={valid.dob}
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 mb-3" controlId="city">
                      <Form.Label>Nearest city to you</Form.Label>
                      <Form.Control
                        name="city"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        isInvalid={valid.city}
                      />
                    </Form.Group>
                    <Form.Group
                      className="col-md-12 mb-3"
                      controlId="validationCustom03"
                    >
                      <Form.Label>I am / We are</Form.Label>
                      <Form.Select
                        name="user_type"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        isInvalid={valid.user_type}
                      >
                        <option value={""}></option>
                        {usertype.map((row) => (
                          <option key={row.id} value={row.id}>
                            {row.val}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    {showUpload ? (
                      <Form.Group
                        controlId="formFile"
                        className="col-md-6 mb-3"
                      >
                        <Form.Label>
                          Upload your Driver's Licence/Passport
                        </Form.Label>
                        <Form.Control
                          type="file"
                          name="docs"
                          accept="image/*"
                          onChange={onFileChangeDocs}
                          required
                          disabled={showUpload ? false : true}
                          multiple
                        />
                      </Form.Group>
                    ) : (
                      <hr />
                    )}
                    <Form.Group className="col-md-12 mb-3 d-flex">
                      <Form.Check
                        aria-label="option 1"
                        required
                        label="I agree to the Terms and Conditions and Privacy Policy"
                        feedback="You must agree before submitting."
                        feedbackType="invalid"
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 mb-3 d-flex align-items-center">
                      <Button className="btn btn-warning" type="submit">
                        Submit
                      </Button>
                      <p className="ms-3 mb-0">
                        Already have an account? <Link to="/Login">Login</Link>
                      </p>
                    </Form.Group>
                    {errorMsg && (
                      <Alert className="col-md-12" variant={"warning"}>
                        {errorMsg.message}
                      </Alert>
                    )}
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Register;
