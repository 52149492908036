/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import UserService from "../../services/UserService";
import UploadService from "../../services/UploadService";

import { UserContext } from "../../context/UserContext";
import HttpHelper from "../../helper/HttpHelper";
import { Toast } from "../layout/Alert";

import Account from "../layout/Account/Account";
import Loader from "../layout/Loader";
import { DobToAge } from "../../helper/UtilityHelper";

function ProfileEdit() {
  const url = HttpHelper.BASE_URL;
  const { init, user, updateUser } = useContext(UserContext);

  const field = {
    phone_number: "",
    first_name: "",
    last_name: "",
    city: "",
    dob: "",
  };

  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState(field);
  const [valid, setvalid] = useState(field);
  const [error, seterror] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    if (!error) {
      try {
        setIsLoading(true);
        const response = await UserService.update(user.id, inputs);
        if (response.status === 200) {
          setIsLoading(false);
          updateUser({
            ...user,
            phone_number: response.data.phone_number,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            city: response.data.city,
            dob: response.data.dob,
          });
          Toast.fire({
            icon: "success",
            title: "Your profile are successfully updated",
          });
        } else {
          setIsLoading(false);
          Toast.fire({
            icon: "warning",
            title: "Something went wrong. try again later",
          });
        }
      } catch (err) {
        setIsLoading(false);
        let message = err.message;
        if (err.code === "ERR_BAD_REQUEST") {
          if (err.response.data.error) {
            message = err.response.data.error.message;
          }
        }
        Toast.fire({ icon: "error", title: message });
      }
    }
  }

  function handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    setvalid((values) => ({ ...values, [name]: false }));
    setIsLoading(false);
    seterror(false);

    let re = "";
    if (name === "dob") {
      // seterror(true);
      let age = DobToAge(value);
      console.log("age", age);
      if (parseInt(age) < 18) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      }
    }
    if (name === "first_name" || name === "last_name") {
      re = /^[A-Za-z]*$/;
      if (
        !re.test(value) ||
        !value.trim() ||
        value.length > 64 ||
        value.length < 2
      ) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      }
    }
    if (name === "city") {
      re = /^[A-Za-z0-9 ,/]*$/;
      if (
        !re.test(value) ||
        !value.trim() ||
        value.length > 100 ||
        value.length < 4
      ) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      }
    }
    if (name === "username") {
      re = /^[A-Za-z0-9_-]*$/;
      if (
        !re.test(value) ||
        !value.trim() ||
        value.length > 64 ||
        value.length < 2
      ) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      }
    }
    if (name === "phone_number") {
      re = /^[0-9-]*$/;
      if (
        !re.test(value) ||
        !value.trim() ||
        value.length > 14 ||
        value.length < 6
      ) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      }
    }
    if (name === "email") {
      re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (
        !re.test(value) ||
        !value.trim() ||
        value.length > 64 ||
        value.length < 6
      ) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      }
    }
    if (name === "user_type") {
      if (!value) {
        seterror(true);
        setvalid((values) => ({ ...values, [name]: true }));
      }
    }
  }

  const [avatar, setavatar] = useState(init.user.avatar.url);
  const [isImgLoading, setImgIsLoading] = useState(false);
  const [selectedImage, setselectedImage] = useState(true);
  const onChangeImages = (event) => {
    setselectedImage(false);
    let input = event.target;
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        setavatar(reader.result);
      };
      reader.readAsDataURL(input.files[0]);
    }
  };
  async function handleUpload(e) {
    e.preventDefault();
    try {
      setselectedImage(true);
      setImgIsLoading(true);
      let response = await UploadService.upload(new FormData(e.target));
      if (response.status === 200) {
        console.log(response.data[0]);
        const newAvatar = response.data[0];
        const oldAvatar = user.avatar;
        response = await UserService.update(user.id, { avatar: newAvatar.id });
        if (response.status === 200) {
          if (oldAvatar) {
            await UploadService.delete(oldAvatar.id);
          }
          updateUser({ ...user, avatar: newAvatar });
          Toast.fire({
            icon: "success",
            title: "Your profile picture are successfully updated",
          });
          setImgIsLoading(false);
        } else {
          throw new Error("Network response was not ok");
        }
      } else {
        setImgIsLoading(false);
        throw new Error("Network response was not ok");
      }
    } catch (err) {
      setselectedImage(true);
      setImgIsLoading(false);
      Toast.fire({ icon: "error", title: err.message });
    }
  }

  const [loaderVisible, setLoaderVisible] = useState(true);
  useEffect(() => {
    if (user && user.id > 0) {
      setInputs({
        phone_number: user.phone_number,
        first_name: user.first_name,
        last_name: user.last_name,
        city: user.city,
        dob: user.dob,
      });
      setLoaderVisible(false);
    }
    if (user && user.avatar !== null && user.avatar?.id > 0) {
      if (user.avatar.formats !== null) {
        setavatar(url + user.avatar.formats?.thumbnail?.url);
      } else {
        setavatar(url + user.avatar.url);
      }
    }
    console.log(user);
  }, [user]);

  return (
    <Account>
      <Loader visible={loaderVisible}>
        <div className="filter-part p-3 mb-3">
          <h3 className="title1">Edit My Profile</h3>
          <Row className="mt-4">
            <Col md={4}>
              <div className="lft-profile-prt">
                <Form onSubmit={handleUpload}>
                  <div className="file-upload">
                    <div className="image-upload-wrap">
                      <div className="drag-img">
                        <img className="img-fluid" src={avatar} />
                      </div>
                    </div>
                    <button className="file-upload-btn" type="button">
                      <i className="fa fa-camera"></i>
                    </button>
                    <input
                      type="file"
                      name="files"
                      onChange={onChangeImages}
                      className="file-upload-input"
                      accept="image/*"
                    />
                  </div>
                  <br />
                  <Button
                    disabled={selectedImage}
                    type="submit"
                    className="btn-warning btn-subscribe mt-5"
                  >
                    {isImgLoading ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Upload"
                    )}
                  </Button>
                </Form>
              </div>
            </Col>
            <Col md={8}>
              <Form className="row" onSubmit={handleSubmit}>
                <Form.Group className="col-md-6 mb-3" controlId="first_name">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    name="first_name"
                    type="text"
                    defaultValue={user.first_name}
                    onChange={handleChange}
                    onBlur={handleChange}
                    isInvalid={valid.first_name}
                    required
                  />
                </Form.Group>
                <Form.Group className="col-md-6 mb-3" controlId="last_name">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    name="last_name"
                    type="text"
                    defaultValue={user.last_name}
                    onChange={handleChange}
                    onBlur={handleChange}
                    isInvalid={valid.last_name}
                    required
                  />
                </Form.Group>
                <Form.Group className="col-md-6 mb-3" controlId="phone_number">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    name="phone_number"
                    type="tel"
                    defaultValue={user.phone_number}
                    onChange={handleChange}
                    onBlur={handleChange}
                    isInvalid={valid.phone_number}
                    required
                  />
                </Form.Group>
                <Form.Group className="col-md-6 mb-3" controlId="dob">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    name="dob"
                    defaultValue={user.dob}
                    onChange={handleChange}
                    onBlur={handleChange}
                    isInvalid={valid.dob}
                    required
                  />
                </Form.Group>
                <Form.Group className="col-md-12 mb-3" controlId="city">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    name="city"
                    type="text"
                    defaultValue={user.city}
                    onChange={handleChange}
                    onBlur={handleChange}
                    isInvalid={valid.city}
                    required
                  />
                </Form.Group>
                <Form.Group className="col-md-12 mb-3 d-flex align-items-center">
                  <Button
                    disabled={isLoading}
                    type="submit"
                    className="btn btn-warning"
                  >
                    {isLoading ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </Loader>
    </Account>
  );
}

export default ProfileEdit;
