import React, { useContext, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { parsePath, useNavigate } from "react-router-dom";
import PackageServices from "../../../services/PackageServices";
import { useEffect } from "react";
import SubscriptionServices from "../../../services/SubscriptionServices";
import { UserContext } from "../../../context/UserContext";
import { Toast } from "../../layout/Alert";
import TransactionService from "../../../services/TransactionService";
import { FormatMoney } from "format-money-js";

function PackageProvider() {
  const {
    isLogin,
    userId,
    totalWalletBalance,
    updateIsSubscribed,
    procssingCost,
  } = useContext(UserContext);
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [useWallet, setUseWallet] = useState(false);
  const [finalPrice, setfinalPrice] = useState(0);
  // const [totalPackagePrice, setTotalPackagePrice] = useState(0);

  const parsedProcesscingCost = JSON.parse(procssingCost);
  // console.log("cost", parsedProcesscingCost);
  let navigate = useNavigate();
  const handleSkip = () => {
    updateIsSubscribed(false);
    navigate("/");
  };

  const fm = new FormatMoney({
    decimals: 2,
  });

  const fetchAllPackages = async () => {
    let response = await PackageServices.getAll();
    try {
      if (response.status === 200) {
        // console.log("RESPONSE", response.data.data);
        setPackages(response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
      let message = error.message;
      if (error.code === "ERR_BAD_REQUEST") {
        message = error.response.data.error.message;
      }
      Toast.fire({ icon: "error", title: message });
    }
  };
  let totalPackagePrice = 0;
  let precentage =
    parseFloat(
      selectedPackages?.attributes?.total_price *
        parseInt(parsedProcesscingCost?.tax)
    ) / 100;
  // console.log("percentage", precentage);
  if (precentage) {
    let finalPackagePrice = parseFloat(
      selectedPackages?.attributes?.total_price +
        precentage +
        parseInt(parsedProcesscingCost?.processing_fee)
    );
    // console.log("final package price", finalPackagePrice);
    totalPackagePrice = finalPackagePrice;
    // setTotalPackagePrice(finalPackagePrice);
    // return finalPackagePrice;
  }

  // console.log("totalpac", totalPackagePrice);

  const handleTransactionIdChange = (e) => {
    setTransactionId(e.target.value);
    // console.log("Transaction id inside", transactionId);
  };
  // console.log("Transaction id", transactionId);

  const handleBookPackage = async () => {
    if (!transactionId && finalPrice > 0) {
      Toast.fire({
        icon: "error",
        title: "Transaction Id cannot be blank!!!!",
      });
      return false;
    }

    let dataPackage = selectedPackages;
    let endDate = dataPackage.attributes.validity;
    // console.log("SELECTED P", selectedPackages);
    const date = new Date();
    let payload = {
      user: userId,
      package: dataPackage.id,
      start_date: new Date().toISOString().slice(0, 19).replace("T", " "),
      end_date: new Date(date.setMonth(date.getMonth() + endDate))
        .toISOString()
        .slice(0, 19)
        .replace("T", " "),
      transaction_id: transactionId,
      amount: dataPackage.attributes.total_price,
      // isActive:
    };
    console.log("Payload", payload);
    console.log("final>>", finalPrice);
    console.log(
      "sdjkhdjs",
      parseFloat(totalWalletBalance) - parseFloat(finalPrice)
    );
    try {
      let response = await SubscriptionServices.create({ data: payload });
      if (response.status === 200) {
        let tRes = await TransactionService.create({
          data: {
            transaction_uid: transactionId,
            transaction_amount: finalPrice,
            user: userId,
            subscription: response.data.data.id,
            order_type: "package",
            // transaction_type: "debit",
          },
        });
        console.log("tres", tRes);
        let tResDebit = await TransactionService.create({
          data: {
            transaction_uid: transactionId,
            transaction_amount: dataPackage.attributes.total_price,
            user: userId,
            subscription: response.data.data.id,
            order_type: "package",
            transaction_type: "debit",
          },
        });
        console.log("TOtawb", totalWalletBalance);
        console.log("final", finalPrice);
        console.log("tresDebit", tResDebit);
        Toast.fire({
          icon: "success",
          title: "Subscription Added!",
        });
        // localStorage.setItem("isSubscribed", true);
        updateIsSubscribed(true);
        navigate("/");
      } else {
        updateIsSubscribed(false);
        Toast.fire({
          icon: "error",
          title: "Something went worng!!!",
        });
      }
    } catch (error) {
      setIsLoading(false);
      let message = error?.message;
      if (error.code === "ERR_BAD_REQUEST") {
        message = error.response.data.error.message;
      }
      Toast.fire({ icon: "error", title: message });
    }
  };

  const handleCheckboxChange = () => {
    setUseWallet(!useWallet);
  };

  useEffect(() => {
    // console.log("Transaction id in use effect", transactionId);
    fetchAllPackages();
  }, []);

  useEffect(() => {
    setfinalPrice(() =>
      useWallet
        ? Math.max(
            parseFloat(totalPackagePrice) - parseFloat(totalWalletBalance),
            0
          )
        : selectedPackages?.attributes?.total_price
    );
  }, [selectedPackages.id, useWallet]);

  return (
    <>
      <div className="main-wrap dashboard-prt">
        <Container>
          {isLogin() ? null : (
            <div className="text-end pe-5">
              <button className="btn btn-danger btn2" onClick={handleSkip}>
                Skip
              </button>
            </div>
          )}
          <h2 className="main-title">Packages</h2>

          <Row
            className="my-5 justify-content-center"
            style={{ rowGap: "24px" }}
          >
            {packages?.map((data, index) => {
              return (
                <Col md={3} key={index}>
                  <div className="pricing-bx">
                    <div className="pricing-hdr">
                      <h3>
                        <span>{data?.attributes?.name}</span> <sup>$</sup>{" "}
                        {fm.from(data?.attributes?.total_price)}
                      </h3>
                    </div>
                    <ul>
                      <li>{data?.attributes?.validity} months.</li>
                      <li>{data?.attributes?.number_of_ads_per_day} Ads.</li>
                      <li>
                        ${fm.from(data?.attributes?.price_per_day)} per day.
                      </li>
                    </ul>
                    <div className="text-center">
                      <Button
                        className="bookbtn"
                        // onClick={() => handleBookNowButton()}
                        onClick={() => {
                          setSelectedPackages(() => data);
                          // addClass();
                        }}
                      >
                        Book now
                      </Button>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col lg={8} md={6} sm={12}>
              <div className="pay-faq">
                <h2>Payment method</h2>

                <div className="d-flex align-items-center my-3">
                  <figure className="qr-code">
                    <img src="/images/qr.png" alt="" />
                  </figure>
                  <ul className="step-lst">
                    <li>Step 1: Scan the QR code.</li>
                    <li>Step 2: Enter the total amount.</li>
                    <li>Step 3: Pay the amount.</li>
                    <li>Step 4: Enter the Transaction Id on sales form.</li>
                    <li>Step 5: Verify your details and submit the form.</li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <div className="selected-package-rightside">
                <h3>Selected Package</h3>
                <h4 className="wallet-baln">
                  Wallet balance: <span>{totalWalletBalance}</span>
                </h4>
                <p>
                  Name: <span>{selectedPackages?.attributes?.name}</span>
                </p>
                <p>
                  Processing Fee :
                  <span>${parsedProcesscingCost?.processing_fee}</span>
                </p>
                <p>
                  Tax : <span>{parsedProcesscingCost?.tax}%</span>{" "}
                </p>
                <p>
                  Total Package Price:{" "}
                  {totalPackagePrice ? (
                    <span>${fm.from(totalPackagePrice)}</span>
                  ) : null}
                  {/* <span> {selectedPackages?.attributes?.total_price}</span> */}
                  <br />
                </p>
                <div class="cat-checkbox form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={useWallet}
                    onChange={handleCheckboxChange}
                  />
                  <label title="" for="English1" class="form-check-label">
                    <span>Use the wallet balance</span>
                  </label>
                </div>
                <p>
                  {useWallet ? (
                    <span>
                      Final price need to pay after using wallet balance:{" "}
                      <span>
                        {" "}
                        ${" "}
                        {fm.from(
                          parseFloat(
                            finalPrice
                              ? finalPrice
                              : parsedProcesscingCost?.processing_fee
                          )
                        )}
                      </span>
                    </span>
                  ) : null}
                </p>
                <div className="package-transaction-id">
                  <h3>Transaction ID*</h3>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      placeholder="Transaction ID"
                      name="transaction_id"
                      className="form-control rounded-0"
                      onChange={(e) => handleTransactionIdChange(e)}
                      required
                      disabled={
                        parseInt(parsedProcesscingCost.processing_fee)
                          ? false
                          : true
                      }
                    />
                  </div>
                  <Button
                    className="rounded-right"
                    onClick={(e) => handleBookPackage(e)}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default PackageProvider;
