import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { CartContext } from "../context/CartContext";
import { numberFormatter, numberWithCommas } from "../helper/UtilityHelper";
import { UserContext } from "../context/UserContext";
import { Toast } from "./layout/Alert";
import OrderService from "../services/OrderService";
import OrderListService from "../services/OrderListService";
import HttpHelper from "../helper/HttpHelper";

function Cart() {
  const { isLogin, userId } = useContext(UserContext);
  const { getCartTotalAmount, getTotalItemCount, removeFromCart } =
    useContext(CartContext);
  const { cartItems, addToCart } = useContext(CartContext);
  // const cart=JSON.parse(localStorage.getItem('cart'))
  console.log("Cart", cartItems);
  // const {getTotalItemCount}=useContext(CartContext)
  const total = getCartTotalAmount();
  const navigate = useNavigate();
  const cartQuantity = getTotalItemCount();
  const url = HttpHelper.BASE_URL;

  // console.log("CartQauntity", cartQuantity);

  // console.log("TOtol", total);
  const handleCheckout = async () => {
    console.log("Clicked");
    if (!isLogin()) {
      Toast.fire({
        icon: "error",
        title: "Need to login",
      });
      navigate("/login");
    } else {
      console.log("Clicked inside else 1");
      try {
        let response = await OrderService.create({
          data: {
            user: userId,
            amount: parseFloat(numberFormatter(getCartTotalAmount())),
            status: "Awaiting Payment",
          },
        });
        if (response.status === 200) {
          console.log("RESPONSE", response.data.data.id);
          let orderId = response.data.data.id;
          var responseOrder = "";
          cartItems.map(async (item, index) => {
            responseOrder = await OrderListService.create({
              data: {
                user: userId,
                order: orderId,
                product: item?.id,
                price: item?.attributes?.price,
                total: parseFloat(numberFormatter(getCartTotalAmount())),
                quantiy: cartQuantity,
              },
            });
            if (
              cartItems.length === index + 1 &&
              responseOrder.status === 200
            ) {
              navigate("/Checkout");
            }
          });
          // let responseOrder = await OrderListService.create({
          //   data: {
          //     user: userId,
          //     order: orderId,
          //     product: 8,
          //     price: 150,
          //     total: parseFloat(numberFormatter(getCartTotalAmount())),
          //     quantiy: cartQuantity,
          //   },
          // });
          // if (responseOrder.status === 200) {
          //   navigate("/Checkout");
          // }
          // navigate("/Checkout");
        }
      } catch (error) {
        console.log("Error", error);
      }
    }
  };

  return (
    <>
      <div className="main-wrap dashboard-prt">
        <Container>
          <h2 className="main-title">Cart</h2>
          {cartItems.length === 0 ? (
            
            <div className="empty-cart">
              <img src="images/preview.png" alt="" />
              <h4>Cart is Empty</h4>
            </div>
            
          ) : (
            <Row className="mt-5">
              <Col md={8}>
                <div className="cart-dtl-bx">
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems?.map((item, index) => {
                        // console.log("Item", item);
                        return (
                          <tr key={index}>
                            <td>
                              <div className="cart-pro-dtl">
                                <img
                                  className="img-fluid"
                                  src={
                                    url +
                                    item?.attributes?.picture?.data?.attributes
                                      ?.url
                                  }
                                  alt=""
                                />
                                <h4>
                                  {item?.attributes?.name}{" "}
                                  <span>SIZE: Normal</span>
                                </h4>
                              </div>
                            </td>
                            <td>
                              $
                              {numberWithCommas(
                                numberFormatter(item?.attributes?.price)
                              )}
                            </td>
                            <td>
                              {" "}
                              {numberWithCommas(numberFormatter(item?.quantiy))}
                            </td>
                            <td>
                              $
                              {numberWithCommas(
                                numberFormatter(
                                  item?.attributes?.price * item?.quantiy
                                )
                              )}
                              <Button
                                className="ms-3 btn p-0 bg-transparent border-0 text-black"
                                onClick={() => removeFromCart(item.id)}
                              >
                                <i
                                  className="fa fa-trash fa-2x "
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={4}>
                <div className="cart-dtl-bx">
                  <Table striped>
                    <tbody>
                      <tr>
                        <th>Subtotal</th>
                        <td className="text-right">
                          ${numberWithCommas(numberFormatter(total))}
                        </td>
                      </tr>
                      <tr>
                        <th>Total</th>
                        <td className="text-right">
                          ${numberWithCommas(numberFormatter(total))}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Button
                    onClick={() => handleCheckout()}
                    className="btn btn-warning mt-3 w-100"
                  >
                    Proceed to checkout
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  );
}

export default Cart;
