import axios from 'axios';
import HttpHelper from '../helper/HttpHelper';

class VendorTypeService {

    getAll(payload) {
        return axios.get(HttpHelper.API_URL + '/vendor-types', { params: payload }, HttpHelper.getHeader());
    }

    getOne(id, payload) {
        return axios.get(HttpHelper.API_URL + '/vendor-types/' + id, { params: payload }, HttpHelper.getHeader());
    }

}

export default new VendorTypeService();