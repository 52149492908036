/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import Loader from "../layout/Loader";
import RightSidebar from "../layout/RightSidebar";
import ProductService from "../../services/ProductService";
import HttpHelper from "../../helper/HttpHelper";
import { numberFormatter, numberWithCommas } from "../../helper/UtilityHelper";

import { CartContext } from "../../context/CartContext";
import { UserContext } from "../../context/UserContext";

export default function Products() {
    const [query, setQuery] = useState({
        populate: "*",
        pagination: { pageSize: 10 },
        sort: ['id:desc']
    });
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState({});

    const handlePerPageOnchange = (event) => {
        let tempQuery = query
        tempQuery.pagination.pageSize = event.target.value;
        setQuery(tempQuery)
        fetchData();
        //console.log(event.target.value)
    }

    const fetchData = async () => {
        try {
            const response = await ProductService.getAll(query);
            if (response.status === 200) {
                // console.log(response.data);
                setItems(response.data.data);
                setMeta(response.data.meta);
            }
            setIsLoaded(true);
        } catch (error) {
            setIsLoaded(true);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    return (
        <>
            <div className="main-wrap dashboard-prt">
                <Container>
                    <Row>
                        <Col md={9}>
                            <Loader visible={!isLoaded}>
                                <div className="filter-part">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="w-100">
                                            <h4>{items.length} Shop Merchant</h4>
                                            <div className="sort-buttons d-flex align-items-center flex-wrap">
                                                <a
                                                    title="Sort by Price"
                                                    className="text-decoration-none item d-flex align-items-center weight-600 f-s-12 me-4"
                                                >
                                                    <svg
                                                        width="10"
                                                        height="15"
                                                        viewBox="0 0 10 15"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <title>sort-arrows</title>
                                                        <path
                                                            d="M5 15L0.669873 9L9.33013 9L5 15Z"
                                                            id="up-arrow"
                                                            fill="currentColor"
                                                        ></path>
                                                        <path
                                                            d="M5 0L9.33013 6H0.669873L5 0Z"
                                                            id="down-arrow"
                                                            fill="currentColor"
                                                        ></path>
                                                    </svg>
                                                    <span className="text text-uppercase ps-2">
                                                        Price
                                                    </span>
                                                </a>

                                                {/* <a
                                                    title="Sort by Rating"
                                                    className=" text-decoration-none item d-flex align-items-center weight-600 f-s-12 me-4"
                                                >
                                                    <svg
                                                        width="10"
                                                        height="15"
                                                        viewBox="0 0 10 15"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <title>sort-arrows</title>
                                                        <path
                                                            d="M5 15L0.669873 9L9.33013 9L5 15Z"
                                                            id="up-arrow"
                                                            fill="currentColor"
                                                        ></path>
                                                        <path
                                                            d="M5 0L9.33013 6H0.669873L5 0Z"
                                                            id="down-arrow"
                                                            fill="currentColor"
                                                        ></path>
                                                    </svg>
                                                    <span className="text text-uppercase ps-2">
                                                        Rating
                                                    </span>
                                                </a> */}
                                            </div>
                                        </div>
                                        <div className="rt-filter-prt d-flex align-items-center justify-content-end">
                                            <Form.Select onChange={handlePerPageOnchange} size="sm">
                                                <option value={10}>10 Per Page</option>
                                                <option value={20}>20 Per Page</option>
                                                <option value={30}>30 Per Page</option>
                                                <option value={40}>40 Per Page</option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                </div>
                                <Row className="mt-3">
                                    {items.map((row) => {
                                        return <Product key={row.id} row={row}></Product>;
                                    })}
                                </Row>
                            </Loader>
                        </Col>
                        <Col md={3}>
                            <RightSidebar />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

function Product({ row }) {
    const { isVender, isProvider } = useContext(UserContext);
    const attr = row.attributes;
    return (
        <Col key={row.id} lg={3} md={6}>
            <div className="product-bx">
                <figure className="pro-img">
                    <Thumbnail image={attr.picture} />
                    <span className="wishlist">
                        <i className="fa fa-heart"></i>
                    </span>
                    {!isVender() && !isProvider() ? <CartButton row={row} /> : null}
                </figure>
                <h4>
                    {attr.name}{" "}
                    <span>$ {numberWithCommas(numberFormatter(attr.price))}</span>
                </h4>
            </div>
        </Col>
    );
}

function CartButton({ row }) {
    const { addToCart, getOneItemCount, removeFromCart, getQuantiyById,  updateToCart} =
        useContext(CartContext);

    const quantityPlus = (row) => {
        let num = getQuantiyById(row.id);
        console.log("NUM",num)
        if (num > 0) {
            row.quantiy = num + 1;
            updateToCart(row);
        } else {
            row.quantiy = 1;
            addToCart(row);
        }
    }

    const quantityMinus = (row) => {
        let num = getQuantiyById(row.id);
        if (num > 1) {
            row.quantiy = num - 1;
            updateToCart(row);
        } else {
            removeFromCart(row.id);
        }
    }


    return (
        <>

            <Button
                variant="danger"
                onClick={() => quantityMinus(row)}
                className="btn-cart"
            >
                -
            </Button>
            <Button
                variant="warning"
                onClick={() => quantityPlus(row)}
                className="btn-cart"
            >
                +
            </Button>
            {/* {getOneItemCount(row.id) ? (
                <Button
                    variant="danger"
                    onClick={() => removeFromCart(row.id)}
                    className="btn-cart"
                >
                    -
                </Button>
            ) : (
                <Button
                    variant="warning"
                    onClick={() => addToCart(row)}
                    className="btn-cart"
                >
                    +
                </Button>
            )} */}
        </>
    );
}

function Thumbnail({ image }) {
    const url = HttpHelper.BASE_URL;
    if (image.data) {
        const img = image.data?.attributes;
        if (img && img.formats) {
            return (
                <img
                    alt={img.formats.thumbnail.name}
                    src={url + img.formats.thumbnail.url}
                    className="img-fluid"
                />
            );
        }
        return <img alt={img.name} src={url + img.url} className="img-fluid" />;
    }
    return (
        <img alt="no" src="/images/no-photo-small.svg" className="img-fluid" />
    );
}
