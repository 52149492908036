import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import HttpHelper from "../../../helper/HttpHelper";
import vendorService from "../../../services/VendorService";

import Loader from "../../layout/Loader";
import { Toast } from "../../layout/Alert";
import Account from "../../layout/Account/Account";

import { UserContext } from "../../../context/UserContext";

export default function List() {
    const { userId } = useContext(UserContext);

    const [data, setData] = useState([]);
    const [visible, setvisible] = useState(true);
    const [query, setQuery] = useState({
        filters: {
            user: {
                id: {
                    $eq: userId,
                },
            },
        },
        populate: "*",
        pagination: { pageSize: 10 },
        sort: ['id:desc']
    })

    const handlePerPageOnchange = (event) => {
        let tempQuery = query
        tempQuery.pagination.pageSize = event.target.value;
        setQuery(tempQuery)
        getAll();
        //console.log(event.target.value)
    }

    async function getAll() {
        try {
            const response = await vendorService.getAll(query);
            if (response.status === 200) {
                if (response.data.data) {
                    setData(response.data.data);
                }
            }
            setvisible(false);
        } catch (error) {
            setvisible(false);
            let message = error.message;
            Toast.fire({ icon: "error", title: message });
        }
    }

    useEffect(() => {
        getAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [itemId, setitemId] = useState(0);
    const [show, setShow] = useState(false);
    const handleShow = (id) => {
        setShow(true);
        setitemId(id);
    };
    const handleClose = () => {
        setShow(false);
    };
    const handleAction = async () => {
        handleClose();
        console.log(itemId);
        const response = await vendorService.delete(itemId);
        if (response.status === 200) {
            Toast.fire({
                icon: "success",
                title: "Your Place are successfully deleted",
            });
            getAll();
        }
    };

    return (
        <Account>

            <Loader visible={visible}>
                <div className="filter-part p-3 mb-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <h3 className="title1 p-0 mb-3">My Places</h3>
                        <div className="rt-filter-prt d-flex align-items-center justify-content-end">
                            <Form.Select onChange={handlePerPageOnchange} size="sm">
                                <option value={10}>10 Per Page</option>
                                <option value={20}>20 Per Page</option>
                                <option value={30}>30 Per Page</option>
                                <option value={40}>40 Per Page</option>
                            </Form.Select>
                            <Link to={"/place/add"} className="btn btn-success">
                                Add Place
                            </Link>
                        </div>
                    </div>
                    <hr />

                    {data.map((row) => {
                        const attr = row.attributes;
                        return (
                            <div className="card-wrapper" key={row.id}>
                                <div className="card-view d-flex">
                                    <div className="info info-left flex-grow-1">
                                        <div className="image-wrapper">
                                            <Photo image={attr.image}></Photo>
                                        </div>
                                        <div className="info">
                                            <h6 className="title color-link weight-600 m-b-5">
                                                {attr.name}
                                            </h6>
                                            <p>{attr.phone}</p>
                                            <PlaceType vendor={attr.vendor_type}></PlaceType>
                                            <Address address={attr.address} />
                                        </div>
                                    </div>
                                    <div className="info-right d-flex align-items-center justify-content-between">
                                        <Link
                                            title="Edit"
                                            to={"/place/edit/" + row.id}
                                            className="btn btn-outline-light text-dark"
                                        >
                                            <i className="fas fa-edit"></i>
                                        </Link>
                                        <Button
                                            title="Delete"
                                            variant="outline-light"
                                            onClick={() => handleShow(row.id)}
                                            className="btn btn-outline-light text-dark"
                                        >
                                            <i className="fas fa-trash"></i>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Loader>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Delete this place?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleAction}>
                        Delete
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </Account>
    );
}

function Photo({ image }) {
    const url = HttpHelper.BASE_URL;
    console.log(image);
    if (image.data) {
        let img = image.data?.attributes;
        if (img && img.formats) {
            return (
                <img
                    alt={img.formats.thumbnail.name}
                    src={url + img.formats.thumbnail.url}
                    className="image"
                    border="0"
                />
            );
        }
        return (
            <img alt={img.name} src={url + img.url} className="image" border="0" />
        );
    }
    return (
        <img
            alt="no"
            src="/images/no-photo-small.svg"
            className="image"
            border="0"
        />
    );
}

function PlaceType({ vendor }) {
    if (vendor !== null) {
        let data = vendor.data?.attributes;
        return <p>{data.type}</p>;
    }
    return "";
}

function Address({ address }) {
    if (address !== null) {
        let data = address.data?.attributes;
        return (
            <p>
                {[
                    data.location,
                    data.city,
                    data.state,
                    data.postal_code,
                    data.country,
                ].join(", ")}
            </p>
        );
    }
    return "";
}
