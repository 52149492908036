import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LeftSidebar from "./LeftSidebar";

export default function Account({ children }) {

    return (
        <>
            <div className="main-wrap dashboard-prt">
                <Container>
                    <Row>
                        <Col md={3}>
                            <LeftSidebar />
                        </Col>
                        <Col md={9}>
                            {children}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
