import React from "react";
import { Spinner } from "react-bootstrap";

export default function Loader({ visible, children }) {
    // console.log(visible);
    let classesSpinner = "d-flex justify-content-center w-100 h-100 text-center mt-5";
    let classesChildren = "d-none";
    if (!visible) {
        classesSpinner = classesChildren;
        classesChildren = null;
    }

    return (
        <>
            <div className={classesSpinner}>
                <Spinner animation="border" variant="warning" />
            </div>
            <div className={classesChildren}>{children}</div>
        </>
    );
}
