import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse';
import { Form } from 'react-bootstrap';

const AnalFilter = ({ handleChange, anal }) => {

    const [open13, setOpen13] = useState(true);
    const [isYes, setIsYes] = useState(false);
    const [isNo, setIsNo] = useState(false);
    const [isNa, setIsNa] = useState(false);
    const [analtype, setAnaltype] = useState({
        CAS: false,
        BAS: false,
    });


    const handleClickCheckbox = (event) => {
        console.log('handleClickCheckbox')
        handleChange(event);
        const { value } = event.target;
        const prevState = { ...analtype, [value]: !analtype[value] };
        setIsYes(() => prevState.CAS || prevState.BAS);
        setIsNo(() => false);
        setIsNa(() => false);
        setAnaltype(prevState);
    };

    const handleClickRadio = (event) => {
        console.log('handleClickRadio')
        handleChange(event);
        let tid = event.target.id;
        if (tid === "anal1") {
            setIsYes(() => true);
            setIsNo(() => false);
            setIsNa(() => false);
        }
        if (tid === "anal2") {
            setIsYes(() => false);
            setIsNo(() => true);
            setIsNa(() => false);
            setAnaltype({
                CAS: false,
                BAS: false,
            });
        }
        if (tid === "anal3") {
            setIsYes(() => false);
            setIsNo(() => false);
            setIsNa(() => true);
            setAnaltype({
                CAS: false,
                BAS: false,
            });
        }
    };

    return (
        <div className="category-list mb-3">
            <div className="expand" onClick={() => setOpen13(!open13)} aria-controls="category13" aria-expanded={open13}>
                <div className="right-arrow"><i className='fa fa-angle-down'></i></div>
                <div><h3 className="hd3">Anal</h3></div>
            </div>
            <Collapse className="category-body" id="category13" in={open13}>
                <ul>
                    <li>
                        <Form.Check
                            checked={isYes}
                            inline
                            className="cat-checkbox"
                            type="radio"
                            name="anal"
                            id="anal1"
                            label={`Yes (${anal[0]?.Yes})`}
                            value="yes"
                            onChange={handleClickRadio}
                        />
                        <div className="ps-3">
                            <ul>
                                {Object.entries({ "CAS": "CAS", "BAS": "BAS" }).map(([key, type], i) => (
                                    <li key={`anal_activity-${key}`}>
                                        <Form.Check
                                            inline
                                            className="cat-checkbox"
                                            type="checkbox"
                                            name="anal_activity"
                                            id={`anal_activity${key}`}
                                            value={key}
                                            label={`${type} (${anal[0]?.[key]})`}
                                            checked={analtype[key]}
                                            onChange={handleClickCheckbox}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>

                    </li>
                    <li>
                        <Form.Check
                            inline
                            checked={isNo}
                            className="cat-checkbox"
                            type="radio"
                            name="anal"
                            id="anal2"
                            label={`No (${anal[0]?.No})`}
                            value="no"
                            onChange={handleClickRadio}
                        />
                    </li>
                    <li>
                        <Form.Check
                            inline
                            checked={isNa}
                            className="cat-checkbox"
                            type="radio"
                            name="anal"
                            id="anal3"
                            label="N/A"
                            value=""
                            onChange={handleClickRadio}
                        />
                    </li>
                </ul>
            </Collapse>
        </div>
    )
}


export default AnalFilter;