import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

export default function Disclaimer() {
    const [disclaimerbox, setdisclaimerbox] = useState(false);

    function handleChange(event) {
        const target = event.target;
        if (target.checked) {
            localStorage.setItem("disclaimerbox", true);
            setdisclaimerbox(false);
        }
    }
    useEffect(() => {
        if (!localStorage.getItem("disclaimerbox")) {
            setdisclaimerbox(true);
        }
    }, []);

    if (!disclaimerbox) {
        return null;
    }
    
    return (
        <div className="disclaimerbox">
            <div className="disclaimerrow">
                <div className="mb-4">
                    <h2>Disclaimer/Terms/Conditions</h2>
                    <br />
                    <p>
                        This website contains nudity, explicit sexual content and adult
                        language. It should be accessed only by people who are of legal
                        age in the physical location from where you are accessing the
                        site. By accessing this website, you are representing to us that
                        you are of legal age and agree to our Terms & Conditions. Any
                        unauthorized use of this site may violate state, federal and/or
                        foreign law. While hotspicygirls.com does not create, produce or
                        edit any content listed on the advertisements, all the posted
                        advertisements must comply with our age and content standards.
                        hotspicygirls.com always have been adamantly against illegal
                        prostitution, sex trafficking, and all forms of child abuse
                        worldwide. We only want adults that want to be here for
                        entertainment fantasies and lawful activity. If you want to do
                        something illegal, you are not welcome here. hotspicygirls.com has
                        a zero-tolerance policy toward human trafficking, prostitution,
                        and any other illegal conduct. We cooperate with law enforcement,
                        pursuant to appropriate process, such as a subpoena, in
                        investigating criminal activity. Activity that violates our
                        zero-tolerance policy may result in a referral to law enforcement.
                        I have no intention to, and will not, use this site in violation
                        of hotspicygirls.com’s policies or any federal, state, or local
                        law, and I agree to report violations to the appropriate
                        authorities. I also agree to report suspected exploitation of
                        minors and/or human trafficking to the appropriate authorities.
                        This site uses cookies. By continuing to browse the site you are
                        agreeing to our use of cookies. I have read and agree to this
                        disclaimer as well as the Terms of Use. Terms of Sale for
                        hotspicygirls.com’s Paid Services From time to time,
                        hotspicygirls.com may make certain services available for a fee in
                        connection with the hotspicygirls.com Service (“Paid Services”).
                        The following terms of sale apply solely to your purchase of Paid
                        Services from hotspicygirls.com.
                        <br />
                    </p>
                    <br />• Fees. Unless otherwise agreed upon by hotspicygirls.com in
                    writing, the fees payable in connection with any Paid Services
                    (“Fees”) will be specified via the hotspicygirls.com Service. All
                    Fees are denominated in U.S. dollars and are exclusive of any
                    applicable taxes.
                    <br />• Payment Method. You may only pay Fees using valid payment
                    methods acceptable to us, as specified via the hotspicygirls.com
                    Service. You represent and warrant that you are authorized to use
                    the payment method you designate via the hotspicygirls.com Service.
                    You authorize us to charge your designated payment method for the
                    total amount of your purchase, including any applicable taxes and
                    other charges. If the payment method cannot be verified, is invalid
                    or is otherwise not acceptable to us, your order may be suspended or
                    cancelled.
                    <br />• Subscriptions. CERTAIN OF THE PAID SERVICES PROVIDED BY
                    hotspicygirls.com MAY BE OFFERED ON A SUBSCRIPTION BASIS WITH
                    AUTO-RENEWING PAYMENTS (“SUBSCRIPTION SERVICES”). THE BILLING PERIOD
                    FOR EACH TYPE OF SUBSCRIPTION SERVICE WILL BE AS SPECIFIED VIA THE
                    hotspicygirls.com SERVICE AT THE TIME OF REGISTRATION. WHEN YOU
                    REGISTER FOR ANY SUBSCRIPTION SERVICE, YOU EXPRESSLY ACKNOWLEDGE AND
                    AGREE THAT (I) hotspicygirls.com (OR ITS DESIGNATED THIRD-PARTY
                    PAYMENT PROCESSOR) IS AUTHORIZED TO CHARGE YOU ON A RECURRING BASIS
                    FOR THE SUBSCRIPTION SERVICE (IN ADDITION TO ANY APPLICABLE TAXES
                    AND OTHER CHARGES) AT THE THEN-CURRENT RATES FOR AS LONG AS THE
                    SUBSCRIPTION SERVICE CONTINUES, AND (II) THE SUBSCRIPTION SERVICE
                    WILL CONTINUE UNTIL YOU CANCEL IT OR WE SUSPEND OR STOP PROVIDING
                    ACCESS TO THE hotspicygirls.com SERVICE IN ACCORDANCE WITH THESE
                    TERMS.
                    <br />• Cancellation Policy For Subscription Services. TO CANCEL ANY
                    SUBSCRIPTION SERVICE, YOU MUST CONTACT US THROUGH OUR HELP CENTER
                    VIA OUR MOBILE APP OR WEBSITE AND FOLLOW THE INSTRUCTIONS IN THE
                    EMAIL WE SEND YOU IN RESPONSE TO YOUR CANCELLATION REQUEST. YOU MUST
                    CANCEL A SUBSCRIPTION SERVICE BEFORE THE START OF THE NEXT BILLING
                    PERIOD IN ORDER TO AVOID CHARGES FOR THE NEXT BILLING PERIOD’S FEES.
                    FOLLOWING ANY CANCELLATION, YOU WILL CONTINUE TO HAVE ACCESS TO THE
                    SUBSCRIPTION SERVICES (SUBJECT TO THESE TERMS) THROUGH THE END OF
                    YOUR CURRENT BILLING PERIOD.
                    <br />• Price Changes. hotspicygirls.com RESERVES THE RIGHT TO
                    MODIFY THE FEES FOR ANY PAID SERVICES, INCLUDING ANY SUBSCRIPTION
                    SERVICES, FROM TIME TO TIME IN ITS SOLE DISCRETION. FOR SUBSCRIPTION
                    SERVICES, PRICE CHANGES WILL APPLY TO THE NEXT BILLING PERIOD.
                    <br />• Taxes. You are responsible for any sales, duty or other
                    governmental taxes or fees due with respect to your purchase of Paid
                    Services. We will collect applicable sales tax if we determine that
                    we have a duty to collect sales tax, and will provide notice of such
                    taxes at the time you place your order.
                    <br />• No Refunds. Except as provided in Section 5(H), or as
                    otherwise expressly agreed upon by hotspicygirls.com, all sales of
                    Paid Services (including any Subscription Services) are final and
                    there are no refunds. THERE ARE NO REFUNDS OR CREDITS FOR PARTIALLY
                    USED SUBSCRIPTION SERVICES PERIODS.
                    <br />• Errors. In the event of an error in connection with the
                    pricing or charging of Paid Services, we reserve the right to
                    correct such error and revise your order accordingly (including
                    charging the correct price) or to cancel the purchase and refund any
                    amount charged. Your sole remedy in the event of a billing error is
                    to obtain a refund for the excess amount charged. To be eligible for
                    such refund, you must provide notice of any such error within 120
                    days of the date of the billing statement in which such error first
                    appeared.
                    <br />• Delivery. Paid services are delivered instantaneously. Any
                    delay in delivery of services due to outside forces will not hold
                    hotspicygirls.com responsible. Time of delivery caused by problems
                    beyond the control of hotspicygirls.com, such as server problems,
                    internet connections, or any kind of unnatural forces will hold
                    hotspicygirls.com harmless.
                    <br />
                    <p>
                        Disclaimer. Any associated fees paid to entertainers are strictly
                        for their time and your companionship only. Anything else that may
                        or may not happen is a matter of personal choice and personal
                        preference between two or more consenting adults of a legal age,
                        and is not contracted for, nor is it requested to be contracted
                        for, or compensated for in any manner. By entering this site you
                        affirm that you are of legal age to view adult materials in the
                        state or country in which you reside. All models are at least 18
                        years of age or older. Report Trafficking ANTI-TRAFFICKING
                        ADVOCACY: hotspicygirls.com is wholly committed to raising
                        awareness on the issue of human trafficking and engages in best
                        practices and advocacy. In the event we become aware of any
                        incident of trafficking, we cooperate enthusiastically with law
                        enforcement and agencies involved in combating the abuse of human
                        rights.
                    </p>
                    <br />
                    <p>
                        {" "}
                        Please report any suspected sexual exploitation of minors and/or
                        human trafficking to the appropriate authorities. United States:
                    </p>
                    <br />• National Center for Missing & Exploited Children (NCMEC)
                    <br />o CyberTipline - report child exploitation
                    <br />o 24-Hour Hotline: 1-800-843-5678
                    <br /> • Polaris Project - Report Human Trafficking: 888-373-7888
                    <br />• Dept. of Health & Human Services: 888-373-7888
                    <br />• Children of the Night: 800-551-1300
                    <br />• ACE National: 202-220-3019
                    <br />• Homeland Security Investigations Tip Line: 866-DHS-2-ICE
                    <br />• Dept. of Justice: 888-428-7581
                    <br />• FBI Office: http://www.fbi.gov/contact-us/field
                    <br />
                    <p>WARNING SIGNS OF POSSIBLE HUMAN TRAFFICKING:</p>
                    <br />• Does an entertainer arrive accompanied by another
                    individual?
                    <br />• Does that individual speak for or appear to maintain control
                    over the entertainer?
                    <br />• Does the entertainer seem fearful of that individual?
                    <br />• Does the entertainer have difficulty communicating, whether
                    resulting from a language barrier or fear of interaction?
                    <br />• Does the entertainer appear to be underage or close to
                    underage?
                    <br />
                    Use common sense, and contact the appropriate authorities if you
                    suspect that someone is being trafficked.
                </div>
                <div className="disclaimer-checkbox mb-4">
                    <Form.Check>
                        <Form.Check
                            aria-label="option 1"
                            label=" Accept"
                            id="disclaimer"
                            onChange={handleChange}
                        />
                    </Form.Check>
                </div>
            </div>
        </div>
    );
}
