export function DobToAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age ? age : 0;
}

export function showRace(id, arr) {
    let data = arr.filter((row) => parseInt(id) === parseInt(row.id));
    return data.length ? data[0].attributes.race_name : "";
}

export function showLang(id, arr) {
    let data = arr.filter((row) => parseInt(id) === parseInt(row.id));
    return data.length ? data[0].attributes.lang_name : "";
}

export function numberFormatter(val) {
    return Number.parseFloat(val).toFixed(2);
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}
