class StorageHelper {
  // token
  setToken(data) {
    localStorage.setItem("token", data);
  }

  setIsSubscribed(data) {
    localStorage.setItem("subscribed", data);
  }

  setProcssingCosts(data) {
    localStorage.setItem("processingCosts", data);
  }

  getToken() {
    return localStorage.getItem("token");
  }
  getIsSubscribed() {
    return localStorage.getItem("subscribed");
  }

  getProcessingCosts() {
    return localStorage.getItem("processingCosts");
  }

  removeToken() {
    localStorage.removeItem("token");
  }
  removeIsSubscribed() {
    localStorage.removeItem("subscribed");
  }

  removeProcessingCosts() {
    localStorage.removeItem("processingCosts");
  }

  // user
  setUser(data) {
    localStorage.setItem("user", JSON.stringify(data));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  removeUser() {
    localStorage.removeItem("user");
  }

  // userid
  setUserId(data) {
    localStorage.setItem("userid", data);
  }

  getUserId() {
    return localStorage.getItem("userid") || {};
  }

  removeUserId() {
    localStorage.removeItem("userid");
  }

  // cart
  setCart(data) {
    localStorage.setItem("cart", JSON.stringify(data));
  }

  getCart() {
    return JSON.parse(localStorage.getItem("cart"));
  }

  removeCart() {
    localStorage.removeItem("cart");
  }

  removeAll() {
    localStorage.clear();
    // localStorage.removeItem("user");
    // localStorage.removeItem("cart");
    // localStorage.removeItem("token");
    // localStorage.removeItem("userid");
    // localStorage.removeItem("subscribed");
    // localStorage.removeItem("processingCosts");
  }
}

export default StorageHelper = new StorageHelper();
