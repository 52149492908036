import React, { useContext, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Account from "../layout/Account/Account";
import { UserContext } from "../../context/UserContext";
import { FormatMoney } from "format-money-js";
import TransactionService from "../../services/TransactionService";

export const MyWallet = () => {
  const {
    totalDebit,
    totalCredit,
    totalCreditPackage,
    transactions,
    totalWalletBalance,
  } = useContext(UserContext);

  const fm = new FormatMoney({
    decimals: 2,
  });

  return (
    <Account>
      <div className="filter-part p-3 mb-3">
        <h3 className="title1">My Wallet</h3>
        <Row className="mt-4">
          <Col md={5}>
            <div className="lft-profile-prt">
              <div className="balence-bx">
                <h2>
                  <span>Wallet Balance</span> {fm.from(totalWalletBalance)}
                </h2>
              </div>
              <Row className="mt-3">
                <Col md={6}>
                  <div className="b-bx">
                    <h3>
                      <span>Total Income</span> ${" "}
                      {parseFloat(totalCredit) - parseFloat(totalCreditPackage)}
                    </h3>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="b-bx">
                    <h3 className="clr-red">
                      <span>Total Expences</span> $ {parseFloat(totalDebit)}
                    </h3>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={7}>
            <h3 className="title2">Recent Transactions</h3>
            <div className="recent-transactions-inner-wrap">
              {transactions.map((row, i) => {
                return (
                  <div key={i} className="trans-bx">
                    <div className="detail">
                      <div>
                        {row?.attributes?.transaction_type === "debit" ? (
                          <div>
                            <strong>Buy a {row?.attributes?.order_type}</strong>
                            <p>{row?.attributes?.createdAt}</p>
                            <p>
                              Start date :{" "}
                              {
                                row?.attributes?.subscription?.data?.attributes
                                  ?.start_date
                              }{" "}
                            </p>
                            <p>
                              End date :{" "}
                              {
                                row?.attributes?.subscription?.data?.attributes
                                  ?.end_date
                              }
                            </p>
                            <p>
                              Status :{" "}
                              {
                                row?.attributes?.subscription?.data?.attributes
                                  ?.isActive
                              }
                            </p>
                          </div>
                        ) : (
                          <div>
                            <strong>
                              {row?.attributes?.order_type === "package"
                                ? "Amount transaction"
                                : "Get A tip"}
                            </strong>
                            <p>{row?.attributes?.createdAt}</p>
                            <p>{row?.attributes?.transaction_type}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="right">
                      {row?.attributes?.transaction_type === "debit" ? (
                        <div className="price text-danger">
                          - {row?.attributes?.transaction_amount}
                          <p>{row?.attributes?.transaction_type}</p>
                        </div>
                      ) : (
                        <div className="price text-success">
                          + {row?.attributes?.transaction_amount}
                          <p>{row?.attributes?.transaction_type}</p>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </div>
    </Account>
  );
};
