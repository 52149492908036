import React, { useEffect, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Form } from "react-bootstrap";

const KissingFilter = ({ handleChange, kissing }) => {
    const [open8, setOpen8] = useState(true);
    const [isYes, setIsYes] = useState(false);
    const [isNo, setIsNo] = useState(false);
    const [isNa, setIsNa] = useState(false);
    const [kissingtype, setKissingtype] = useState({
        Light: false,
        Moderate: false,
        Heavy: false,
    });

    const handleClickCheckbox = (event) => {
        console.log('handleClickCheckbox')
        handleChange(event);
        const { value } = event.target;
        const prevState = { ...kissingtype, [value]: !kissingtype[value] };
        setIsYes(() => prevState.Light || prevState.Moderate || prevState.Heavy);
        setIsNo(() => false);
        setIsNa(() => false);
        setKissingtype(prevState);
    };

    const handleClickRadio = (event) => {
        console.log('handleClickRadio')
        handleChange(event);
        let tid = event.target.id;
        if (tid === "kissing1") {
            setIsYes(() => true);
            setIsNo(() => false);
            setIsNa(() => false);
        }
        if (tid === "kissing2") {
            setIsYes(() => false);
            setIsNo(() => true);
            setIsNa(() => false);
            setKissingtype({
                Light: false,
                Moderate: false,
                Heavy: false,
            });
        }
        if (tid === "kissing3") {
            setIsYes(() => false);
            setIsNo(() => false);
            setIsNa(() => true);
            setKissingtype({
                Light: false,
                Moderate: false,
                Heavy: false,
            });
        }
    };

    // console.log(kissingtype)

    return (
        <div className="category-list mb-3">
            <div
                className="expand"
                onClick={() => setOpen8(!open8)}
                aria-controls="category8"
                aria-expanded={open8}
            >
                <div className="right-arrow">
                    <i className="fa fa-angle-down"></i>
                </div>
                <div>
                    <h3 className="hd3">Kissing</h3>
                </div>
            </div>
            <Collapse className="category-body" id="category8" in={open8}>
                <ul>
                    <li>
                        <Form.Check
                            inline
                            checked={isYes}
                            className="cat-checkbox"
                            type="radio"
                            label={`Yes (${kissing[0]?.Yes})`}
                            name="kissing"
                            id="kissing1"
                            value="yes"
                            onChange={handleClickRadio}
                        />

                        <div className="ps-3">
                            <ul>
                                {["Light", "Moderate", "Heavy"].map((type) => (
                                    <li key={`kissing_types-${type}`}>
                                        <Form.Check
                                            inline
                                            className="cat-checkbox"
                                            type="checkbox"
                                            name="kissing_types"
                                            id={`kissing_types${type}`}
                                            value={type}
                                            label={`${type} (${kissing[0]?.[type]})`}
                                            checked={kissingtype[type]}
                                            onChange={handleClickCheckbox}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Form.Check
                            inline
                            checked={isNo}
                            className="cat-checkbox"
                            type="radio"
                            label={`No (${kissing[0]?.No})`}
                            name="kissing"
                            id="kissing2"
                            value="no"
                            onChange={handleClickRadio}
                        />
                    </li>
                    <li>
                        <Form.Check
                            inline
                            checked={isNa}
                            className="cat-checkbox"
                            type="radio"
                            label="N/A"
                            name="kissing"
                            id="kissing3"
                            value=""
                            onChange={handleClickRadio}
                        />
                    </li>
                </ul>
            </Collapse>
        </div>
    );
};

export default KissingFilter;
