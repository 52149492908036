import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse';
import { Form } from 'react-bootstrap';


const FellatioFilter = ({ handleChange, fellatio }) => {
    const [open9, setOpen9] = useState(true);
    const [isYes, setIsYes] = useState(false);
    const [isNo, setIsNo] = useState(false);
    const [isNa, setIsNa] = useState(false);
    const [fellatiotype, setFellatiotype] = useState([{
        CBJ: false,
        BBJ: false,
    }]);


    const handleClickCheckbox = (event) => {
        handleChange(event);
        const { value } = event.target;
        const prevState = { ...fellatiotype, [value]: !fellatiotype[value] };
        setIsYes(() => prevState.CBJ || prevState.BBJ);
        setIsNo(() => false);
        setIsNa(() => false);
        setFellatiotype(prevState);
    }

    const handleClickRadio = (event) => {
        handleChange(event);
        let tid = event.target.id;
        if (tid === "fellatio1") {
            setIsYes(() => true);
            setIsNo(() => false);
            setIsNa(() => false);
        }
        if (tid === "fellatio2") {
            setIsYes(() => false);
            setIsNo(() => true);
            setIsNa(() => false);
            setFellatiotype({
                CBJ: false,
                BBJ: false,
            });
        }
        if (tid === "fellatio3") {
            setIsYes(() => false);
            setIsNo(() => false);
            setIsNa(() => true);
            setFellatiotype({
                CBJ: false,
                BBJ: false,
            });
        }
    }

    return (
        <div className="category-list mb-3">
            <div className="expand" onClick={() => setOpen9(!open9)} aria-controls="category9" aria-expanded={open9}>
                <div className="right-arrow"><i className='fa fa-angle-down'></i></div>
                <div><h3 className="hd3">Fellatio</h3></div>
            </div>
            <Collapse className="category-body" id="category9" in={open9}>
                <ul>
                    <li>
                        <Form.Check
                            checked={isYes}
                            inline
                            className="cat-checkbox"
                            type="radio"
                            name="fellatio"
                            id="fellatio1"
                            label={`Yes (${fellatio[0]?.Yes})`}
                            value="yes"
                            onChange={handleClickRadio}
                        />
                        <div className="ps-3">
                            <ul>
                                {Object.entries({ "CBJ": "CBJ", "BBJ": "BBJ" }).map(([key, type], i) => (
                                    <li key={`fellatio_type-${key}`}>
                                        <Form.Check
                                            inline
                                            className="cat-checkbox"
                                            type="checkbox"
                                            name="fellatio_type"
                                            id={`fellatio_type${key}`}
                                            value={key}
                                            label={`${type} (${fellatio[0]?.[key]})`}
                                            checked={fellatiotype[key]}
                                            onChange={handleClickCheckbox}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Form.Check
                            inline
                            checked={isNo}
                            className="cat-checkbox"
                            type="radio"
                            name="fellatio"
                            id="fellatio2"
                            label={`No (${fellatio[0]?.No})`}
                            value="no"
                            onChange={handleClickRadio}
                        />
                    </li>
                    <li>
                        <Form.Check
                            inline
                            checked={isNa}
                            className="cat-checkbox"
                            type="radio"
                            name="fellatio"
                            id="fellatio3"
                            label="N/A"
                            value=""
                            onChange={handleClickRadio} />
                    </li>
                </ul>
            </Collapse>
        </div>

    )
}

export default FellatioFilter;
