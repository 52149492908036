import React from "react";
import { useParams } from "react-router-dom";

import Account from "../layout/Account/Account";

import AdsList from "../Provider/Adds/List";
import AdsEdit from "../Provider/Adds/Edit";

function MyAdds() {
  let { id } = useParams();
  if (isNaN(id)) {
    id = false;
  }
  return (
    <>
      {id ? (
        <Account>
          <AdsEdit id={id} />{" "}
        </Account>
      ) : (
        <Account>
          <AdsList />
        </Account>
      )}
    </>
  );
}

export default MyAdds;
